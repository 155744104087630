import { useState } from "react";

export default function useShareModal() {
  const [isShareOpen, setIsShareOpen] = useState(false);

  const toggleShare = () => {
    setIsShareOpen(!isShareOpen);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = body.style.overflow === "" ? "hidden" : "";
    }
  };

  return {
    isShareOpen,
    toggleShare,
  };
}
