import { useState } from "react";
import { ListingTypeEnum } from "../../../../lib/models/listing";
import styles from "./CreateListing.module.css";

interface ChooseListingTypeProps {
  handleNextButtonClick: (type: ListingTypeEnum) => void;
  onClose: () => void;
}

const ChooseListingType: React.FC<ChooseListingTypeProps> = ({
  onClose,
  handleNextButtonClick,
}) => {
  const [selectedListingType, setSelectedListingType] =
    useState<ListingTypeEnum>(ListingTypeEnum.Outing);
  const handleListingTypeClick = (type: ListingTypeEnum) => {
    setSelectedListingType(type);
  };
  return (
    <>
      <div className={styles.chooseListingModal}>
        <div className={styles.modalHeader}>
          <div className={styles.content}>
            <img
              className={styles.featuredIcon}
              alt=""
              src="/create-listing.svg"
            />
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>Create a new listing</div>
              <div className={styles.supportingText}>
                Please choose a listing type below.
              </div>
            </div>
          </div>
          <div className={styles.buttonCloseX} onClick={onClose}>
            <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
          </div>
          <img className={styles.dividerIcon} alt="" src="/divider.svg" />
        </div>
        <div className={styles.content1}>
          <div className={styles.grid}>
            <div className={styles.listingRow}>
              <div
                className={`${styles.listingTypeCard} ${
                  selectedListingType === ListingTypeEnum.Outing
                    ? styles.selected
                    : ""
                }`}
                onClick={() => handleListingTypeClick(ListingTypeEnum.Outing)}
              >
                <div className={styles.content2}>
                  <div className={styles.topBar}>
                    <div className={styles.eventParent}>
                      <div className={styles.event}>
                        <img
                          className={styles.trophyIcon}
                          alt=""
                          src="/listing-types/outing_icon.svg"
                        />
                      </div>
                      <div className={styles.textContainer}>
                        <div className={styles.textAndCategory1}>
                          <div className={styles.text1}>Outing</div>
                        </div>
                        <div className={styles.p1Container}>
                          <div className={styles.p1}>
                            Hosting an Outing? List it on Scratchmaker and get
                            access to hundreds of local golfers!
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedListingType === ListingTypeEnum.Outing ? (
                      <img
                        className={styles.checkboxBaseIcon}
                        alt=""
                        src="/checkbox-checked.svg"
                      />
                    ) : (
                      <div className={styles.checkboxBase} />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`${styles.listingTypeCard} ${
                  selectedListingType === ListingTypeEnum.Competitive
                    ? styles.selected
                    : ""
                }`}
                onClick={() =>
                  handleListingTypeClick(ListingTypeEnum.Competitive)
                }
              >
                <div className={styles.content2}>
                  <div className={styles.topBar}>
                    <div className={styles.eventParent}>
                      <div className={styles.event}>
                        <img
                          className={styles.trophyIcon}
                          alt=""
                          src="/listing-types/tournament_icon.svg"
                        />
                      </div>
                      <div className={styles.textContainer}>
                        <div className={styles.textAndCategory1}>
                          <div className={styles.text1}>Competitive</div>
                        </div>
                        <div className={styles.p1Container}>
                          <div className={styles.p1}>
                            Want to put your skills to the test? Compete with as
                            little as 2 players in various formats at your local
                            courses.
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedListingType === ListingTypeEnum.Competitive ? (
                      <img
                        className={styles.checkboxBaseIcon}
                        alt=""
                        src="/checkbox-checked.svg"
                      />
                    ) : (
                      <div className={styles.checkboxBase} />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`${styles.listingTypeCard} ${
                  selectedListingType === ListingTypeEnum.Casual
                    ? styles.selected
                    : ""
                }`}
                onClick={() => handleListingTypeClick(ListingTypeEnum.Casual)}
              >
                <div className={styles.content2}>
                  <div className={styles.topBar}>
                    <div className={styles.eventParent}>
                      <div className={styles.event}>
                        <img
                          className={styles.trophyIcon}
                          alt=""
                          src="/listing-types/casual_icon.svg"
                        />
                      </div>
                      <div className={styles.textContainer}>
                        <div className={styles.textAndCategory1}>
                          <div className={styles.text1}>Casual Play</div>
                        </div>
                        <div className={styles.p1Container}>
                          <div className={styles.p1}>
                            Need to fill your tee time? Or want to play with
                            people at your skill level? Post a casual play
                            listing and find players today!
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedListingType === ListingTypeEnum.Casual ? (
                      <img
                        className={styles.checkboxBaseIcon}
                        alt=""
                        src="/checkbox-checked.svg"
                      />
                    ) : (
                      <div className={styles.checkboxBase} />
                    )}
                  </div>
                </div>
              </div>
              {/*  TODO: add more listing types */}
              {/* <div
              className={`${styles.listingTypeCard} ${
                selectedListingType === ListingTypeEnum.Exclusive
                  ? styles.selected
                  : ""
              }`}
              onClick={() => handleListingTypeClick(ListingTypeEnum.Exclusive)}
            >
              <div className={styles.content2}>
                <div className={styles.topBar}>
                  <div className={styles.eventParent}>
                    <div className={styles.event}>
                      <img
                        className={styles.trophyIcon}
                        alt=""
                        src="/listing-types/exclusive_icon.svg"
                      />
                    </div>
                    <div className={styles.textContainer}>
                      <div className={styles.textAndCategory1}>
                        <div className={styles.text1}>
                          Host an Exclusive Course
                        </div>
                      </div>
                      <div className={styles.p1Container}>
                        <div className={styles.p1}>
                          Member of a country club? Many people would love to
                          try your course, and are willing to pay you for it -
                          it's a win-win!
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedListingType === ListingTypeEnum.Exclusive ? (
                    <img
                      className={styles.checkboxBaseIcon}
                      alt=""
                      src="/checkbox-checked.svg"
                    />
                  ) : (
                    <div className={styles.checkboxBase} />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`${styles.listingTypeCard} ${
                selectedListingType === ListingTypeEnum.Scratchmaker
                  ? styles.selected
                  : ""
              }`}
              onClick={() =>
                handleListingTypeClick(ListingTypeEnum.Scratchmaker)
              }
            >
              <div className={styles.content2}>
                <div className={styles.topBar}>
                  <div className={styles.eventParent}>
                    <div className={styles.event}>
                      <img
                        className={styles.trophyIcon}
                        alt=""
                        src="/listing-types/Scratchmaker_Strikethrough.png"
                      />
                    </div>
                    <div className={styles.textContainer}>
                      <div className={styles.textAndCategory1}>
                        <div className={styles.text1}>
                          Recruit a Scratchmaker
                        </div>
                      </div>
                      <div className={styles.p1Container}>
                        <div className={styles.p1}>TBD</div>
                      </div>
                    </div>
                  </div>
                  {selectedListingType === ListingTypeEnum.Scratchmaker ? (
                    <img
                      className={styles.checkboxBaseIcon}
                      alt=""
                      src="/checkbox-checked.svg"
                    />
                  ) : (
                    <div className={styles.checkboxBase} />
                  )}
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <div className={styles.modalActions}>
          <div className={styles.dividerWrap}>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
          </div>
          <div className={styles.content6}>
            <div className={styles.cancelButton} onClick={onClose}>
              <div className={styles.text14}>Cancel</div>
            </div>
            <div
              className={styles.nextButton}
              onClick={() => handleNextButtonClick(selectedListingType)}
            >
              <div className={styles.text14}>Next</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseListingType;
