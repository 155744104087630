import { FunctionComponent, useState } from "react";
import styles from "./HowToBook.module.css";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";
const HowToBook: FunctionComponent = () => {
  const [step, setStep] = useState<number>(1);
  const [user] = useAuth();

  return (
    <div className={styles.featuresSection} id="book">
      <div className={styles.wrapFeatures}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.headingAndSupportingText}>
              <div className={styles.headingAndSubheading}>
                <div className={styles.subheading}>Simplify Your Process</div>
                <div className={styles.heading}>Find more ways to play</div>
              </div>
              <div className={styles.supportingText}>
                From casual tee times, impromptu scrambles, outings and more,
                your next tee off is just a few clicks away!
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container1}>
          <div className={styles.content1}>
            <div
              className={`${
                step === 1 ? styles.featureTabSelected : styles.featureTab
              }`}
              onClick={() => setStep(1)}
            >
              <div className={styles.textAndSupportingText}>
                <div className={styles.step}>
                  Browse Through Available Listings
                </div>
                <div className={styles.supportingText1}>
                  Use the "Listings" tab and filter tools to find the best
                  opportunity for you. Filter options include location, date and
                  event type.
                </div>
              </div>
              <NavLink to={`/listings`} className={styles.button}>
                <div className={styles.text1}>Search Listings</div>
                <img
                  className={styles.arrowRightIcon}
                  alt=""
                  src="/arrowright.svg"
                />
              </NavLink>
            </div>
            {/* if step = 2, class is featureTabSelected. onclick will change step to 2 */}
            <div
              className={`${
                step === 2 ? styles.featureTabSelected : styles.featureTab
              }`}
              onClick={() => setStep(2)}
            >
              <div className={styles.textAndSupportingText}>
                <div className={styles.step}>Reserve your Spot</div>
                <div className={styles.supportingText1}>
                  Claim your spot in the event and confirm your reservation. You
                  can message the host to discuss any details leading up to the
                  event.
                </div>
              </div>
              <NavLink
                to={`/profile/${user?.Username}`}
                className={styles.button}
              >
                <div className={styles.text1}>View Your Upcoming Listings</div>
                <img
                  className={styles.arrowRightIcon}
                  alt=""
                  src="/arrowright.svg"
                />
              </NavLink>
            </div>
            <div
              className={`${
                step === 3 ? styles.featureTabSelected : styles.featureTab
              }`}
              onClick={() => setStep(3)}
            >
              <div className={styles.textAndSupportingText}>
                <div className={styles.step}>Meet Up + Play ⛳️</div>
                <div className={styles.supportingText1}>
                  Meet at the course, pay your greens fees and you're ready to
                  play!
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content2}>
            {step === 1 ? (
              <div className={styles.screenMockup}>
                <div className={styles.mockupShadow} />
                <img
                  className={styles.screenMockupReplaceFill}
                  alt=""
                  src="/find_step1.png"
                />
                {/* if step = 3, show a different picture */}
              </div>
            ) : step === 2 ? (
              <div className={styles.screenMockup}>
                <div className={styles.mockupShadow} />
                <img
                  className={styles.screenMockupReplaceFill}
                  alt=""
                  src="/find_step2.png"
                />
                {/* if step = 3, show a different picture */}
              </div>
            ) : (
              <div className={styles.wrapImage}>
                <img src="/meetup.jpg" alt="meetup with golfers" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToBook;
