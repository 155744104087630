import React from "react";
import { Link } from "react-router-dom";
import CloseButton from "../../Assets/Images/Close.png";
import Warning from "../../Assets/Images/warning.png";

interface UsernameAlertModalProps {
  show: boolean;
  modalToggle: (val: boolean) => void;
}

const UsernameAlertModal: React.FC<UsernameAlertModalProps> = ({
  show,
  modalToggle,
}) => {
  return (
    <div
      className={`username-alert-modal cardDetailsPopup ${show ? "show" : ""}`}
      id="showcarddetailspopup"
    >
      <div className="popupCardDetails">
        <img
          src={CloseButton}
          id="closecarddetailspopup"
          alt="close"
          onClick={() => modalToggle(false)}
          className="close-golfer-info"
        />
        <div className="popBasicCardInfo">
          <div className="popupcardbasicinfo">
            <div className="alert-header">
              <img src={Warning} alt="warning" />
              <h3>You haven't yet created your username!</h3>
            </div>
            <p className="alert-body">
              Please click the button below to go to your profile page and
              create one:
            </p>
            <Link to="/profile" className="go-profile">
              Go to My Profile
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsernameAlertModal;
