import { FunctionComponent } from "react";
import styles from "./CheckoutFooter.module.css";
const CheckoutFooter: FunctionComponent = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.text}>© Scratchmaker Golf 2023</div>
      <div className={styles.row}>
        <img className={styles.mail01Icon} alt="" src="/mail01.svg" />
        <div className={styles.text}>
          <a href="mailto:info@scratchmakergolf.com">
            info@scratchmakergolf.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFooter;
