import { FunctionComponent, useEffect, useState } from "react";
import styles from "../styles/ListingPageViewAll.module.css";
import ListingSummary from "../components/ListingsPageViewAll/ListingSummary";
import CategoryRow from "../components/ListingsPageViewAll/CategoryRow";
import ScratchApiService from "../lib/services/scratch-api-service";
import {
  Listing,
  ListingGroup,
  ListingTypeEnum,
  ListingTypeEnumMap,
} from "../lib/models/listing";
import Search from "../components/ListingsPageViewAll/Search";
import TournamentBlock from "../components/ListingsPageViewAll/TournamentBlock";
import CasualBlock from "../components/ListingsPageViewAll/CasualBlock";
import usePosition from "../hooks/usePosition";
import { useNavigate, useParams } from "react-router-dom";
import { Position } from "../lib/services/google-maps-service";
import { Range } from "react-date-range";
import BecomeAHostCallback from "../components/v2_Home/BecomeAHostCallback";
import ListingsDrillDown from "../components/ListingsPageViewAll/ListingsDrillDown";
import ListingModal from "../components/common/listings/ListingModal";
import OutingBlock from "../components/ListingsPageViewAll/OutingBlock";
import useCreateListingModal from "../hooks/useCreateListingModal";
import CreateListingModal from "../components/common/listings/create-listing-modal/CreateListingModal";
import useLoginModal from "../hooks/useLoginModal";
import LoginModal from "../components/common/auth/LoginModal";
import useAuth from "../hooks/useAuth";
import ProcessUserModal from "../components/common/auth/process-user/ProcessUserModal";
import useProcessUserModal from "../hooks/useProcessUserModal";
import useViewListingModal from "../hooks/useViewListingModal";

export interface SearchParams {
  Radius: number;
  Location: Position;
  DateRange: Range | null | undefined;
}
const ListingPageViewAll: FunctionComponent = () => {
  // const typeFilter = new URLSearchParams(window.location.search).get(
  //   "typeFilter"
  // );
  const { typeFilter, listingId } = useParams();
  const [searchParams, setSearchParams] = useState<SearchParams | null>();
  const [listings, setListings] = useState<Listing[]>([]);
  const [sortedListings, setSortedListings] = useState<Listing[]>([]);
  const [listingGroups, setListingGroups] = useState<ListingGroup>({});
  const [category, setCategory] = useState<ListingTypeEnum>(
    ListingTypeEnum.All
  );
  const [position] = usePosition();
  const navigate = useNavigate();
  const [selectedListing, setSelectedListing] = useState<Listing>();
  const [selectedListingType, setSelectedListingType] =
    useState<ListingTypeEnum>();
  const [showListingModal, setShowListingModal] = useState(false);
  const [linkToListingId, setLinkToListingId] = useState<string | null>();
  const { isCreateListingOpen, toggleCreateListing } = useCreateListingModal();
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const [user] = useAuth();
  const { isProcessUserOpen, toggleProcessUser } = useProcessUserModal();
  const { isViewListingOpen, toggleViewListing } = useViewListingModal();

  useEffect(() => {
    if (listingId) {
      setLinkToListingId(listingId);
    }
  }, [listingId]);

  useEffect(() => {
    if (!position) {
      setSearchParams(null);
    } else {
      if (!searchParams) {
        setSearchParams({ Radius: 25, Location: position, DateRange: null });
      }
    }
  }, [navigate, position, searchParams]);

  useEffect(() => {
    async function getListings() {
      if (searchParams) {
        const listings = await ScratchApiService.getListings(
          searchParams.Location.latitude,
          searchParams.Location.longitude,
          searchParams.Radius,
          searchParams.DateRange?.startDate ?? null,
          searchParams.DateRange?.endDate ?? null
        );
        setListings(listings);
      }
    }

    if (searchParams) {
      getListings();
    }
  }, [linkToListingId, position, searchParams]);

  useEffect(() => {
    async function getListingById(lId: string) {
      const listing = await ScratchApiService.getListingById(lId);
      setSelectedListing(listing);
      setSelectedListingType(ListingTypeEnumMap[listing.ListingTypeId]);
    }

    if (linkToListingId) {
      // get listing
      getListingById(linkToListingId);
      toggleViewListing();
    }
  }, [linkToListingId]);
  useEffect(() => {
    if (listings) {
      const sortByEventDate = (a: Listing, b: Listing) => {
        // Compare the event dates of two listings
        const eventDateA = new Date(a.EventDate);
        const eventDateB = new Date(b.EventDate);
        return eventDateA.getTime() - eventDateB.getTime();
      };

      const sorted = [...listings].sort(sortByEventDate);
      setSortedListings(sorted);
    }
  }, [listings]);

  const modalOpen = (bool: boolean) => {
    setShowListingModal(bool);
    toggleViewListing();
    // const body = document.querySelector("body");
    // if (body) {
    //   body.style.overflow = bool ? "hidden" : "";
    // }
  };
  useEffect(() => {
    if (typeFilter) {
      switch (typeFilter) {
        case "Casual":
          setCategory(ListingTypeEnum.Casual);
          break;
        case "Competitive":
          setCategory(ListingTypeEnum.Competitive);
          break;
        case "Exclusive":
          setCategory(ListingTypeEnum.Exclusive);
          break;
        case "Lessons":
          setCategory(ListingTypeEnum.Lesson);
          break;
        case "Outing":
          setCategory(ListingTypeEnum.Outing);
          break;
        case "Scratchmaker":
          setCategory(ListingTypeEnum.Scratchmaker);
          break;
        default:
          setCategory(ListingTypeEnum.All);
          break;
      }
    }
  }, [typeFilter]);
  // TODO: Lessons
  // useEffect(() => {
  //   async function getListings() {
  //     const lessons = await ScratchApiService.getLessons(0, 0, 0);
  //     setLessons(lessons);
  //   }
  //   getListings();
  // }, []);

  useEffect(() => {
    if (sortedListings.length > 0) {
      const groups = sortedListings.reduce(
        (acc: ListingGroup, listing: Listing, index: number) => {
          const type = listing.ListingType.Type;

          if (!acc[type]) {
            acc[type] = [];
          }
          acc[type].push(listing);
          return acc;
        },
        {}
      );
      setListingGroups(groups);
    } else {
      setListingGroups({});
    }
  }, [sortedListings]);

  const handleCategory = (cat: ListingTypeEnum) => {
    setCategory(cat);
    const newUrl = `/listings/${cat}`;
    window.history.replaceState(null, "", newUrl);
  };

  const handleSelectListing = (listing: Listing, type: ListingTypeEnum) => {
    setSelectedListing(listing);
    setSelectedListingType(type);
    modalOpen(true);

    const newPath = `${window.location.origin}/listings/${type}/${listing.Id}`;
    const newUrl = new URL(newPath, window.location.origin);

    window.history.replaceState(null, "", newUrl.href);

    // const newUrl = `${window.location.pathname}/${listing.Id}`;
    // window.history.replaceState(null, "", newUrl);
  };

  const onCloseModal = () => {
    modalOpen(false);
    setLinkToListingId(null);
    const currentUrl = window.location.pathname;
    const newUrl = currentUrl.substring(0, currentUrl.lastIndexOf("/"));
    window.history.replaceState(null, "", newUrl);
  };

  const noFindingsTsx = () => {
    return (
      <div className={`${styles.section} ${styles.noListings}`}>
        <span>No listings found.</span>
        <div className={styles.promoteGrowth}>
          <span>Create your own listing to start playing in your area!</span>
          <button onClick={handleCreateClick}>Create Listing</button>
        </div>
      </div>
    );
  };
  const handleCreateClick = () => {
    if (user) {
      if (user.Location) {
        toggleCreateListing();
      } else {
        toggleProcessUser();
      }
    } else {
      toggleLogin();
    }
  };
  return (
    <div className={styles.listingPageViewAll}>
      <div className={styles.main}>
        <div className={styles.sections}>
          {sortedListings && searchParams && (
            <>
              <ListingSummary
                listings={sortedListings}
                location={searchParams.Location}
              />
            </>
          )}
          <CategoryRow onClickCB={handleCategory} activeType={category} />
          <Search setSearch={setSearchParams} />
          {sortedListings.length > 0 &&
            listingGroups &&
            position &&
            (category === ListingTypeEnum.All ? (
              <div className={styles.eventContainer}>
                {listingGroups.Casual && (
                  <CasualBlock
                    listings={listingGroups.Casual}
                    seeAllCB={() => handleCategory(ListingTypeEnum.Casual)}
                    onListingClick={handleSelectListing}
                  />
                )}
                {listingGroups.Competitive && (
                  <TournamentBlock
                    listings={listingGroups.Competitive}
                    seeAllCB={() => handleCategory(ListingTypeEnum.Competitive)}
                    onListingClick={handleSelectListing}
                  />
                )}
                {listingGroups.Outing && (
                  <OutingBlock
                    listings={listingGroups.Outing}
                    seeAllCB={() => handleCategory(ListingTypeEnum.Outing)}
                    onListingClick={handleSelectListing}
                  />
                )}

                {/* Now show the others if no entries exist */}
                {!listingGroups.Casual && (
                  <CasualBlock
                    listings={listingGroups.Casual}
                    seeAllCB={() => handleCategory(ListingTypeEnum.Casual)}
                    onListingClick={handleSelectListing}
                  />
                )}
                {!listingGroups.Competitive && (
                  <TournamentBlock
                    listings={listingGroups.Competitive}
                    seeAllCB={() => handleCategory(ListingTypeEnum.Competitive)}
                    onListingClick={handleSelectListing}
                  />
                )}
                {!listingGroups.Outing && (
                  <OutingBlock
                    listings={listingGroups.Outing}
                    seeAllCB={() => handleCategory(ListingTypeEnum.Outing)}
                    onListingClick={handleSelectListing}
                  />
                )}

                {/* TODO: Re-add exclusive and lessons */}
                {/* {listingGroups.Exclusive && (
                  <ExclusiveBlock
                    listings={listingGroups.Exclusive}
                    seeAllCB={() => handleCategory(ListingTypeEnum.Exclusive)}
                    onListingClick={handleSelectListing}
                  />
                )}
                {lessons.length > 0 && (
                  <LessonBlock
                    lessons={lessons}
                    seeAllCB={() => handleCategory(ListingTypeEnum.Lesson)}
                  />
                )} */}
              </div>
            ) : (
              Object.keys(listingGroups).length !== 0 &&
              (() => {
                switch (category) {
                  case ListingTypeEnum.Outing:
                    return listingGroups.Outing ? (
                      <ListingsDrillDown
                        listings={listingGroups.Outing}
                        type={category}
                        onListingClick={handleSelectListing}
                      />
                    ) : (
                      noFindingsTsx()
                    );
                  case ListingTypeEnum.Competitive:
                    return listingGroups.Competitive ? (
                      <ListingsDrillDown
                        listings={listingGroups.Competitive}
                        type={category}
                        onListingClick={handleSelectListing}
                      />
                    ) : (
                      noFindingsTsx()
                    );
                  case ListingTypeEnum.Casual:
                    return listingGroups.Casual ? (
                      <ListingsDrillDown
                        listings={listingGroups.Casual}
                        type={category}
                        onListingClick={handleSelectListing}
                      />
                    ) : (
                      noFindingsTsx()
                    );
                  default:
                    return null;
                }
              })()
            ))}
          {(!sortedListings || sortedListings.length === 0) && noFindingsTsx()}
        </div>
      </div>

      <BecomeAHostCallback />

      {selectedListing && selectedListingType && (
        <ListingModal
          listing={selectedListing}
          listingType={selectedListingType}
          isOpen={isViewListingOpen}
          toggle={toggleViewListing}
        />
      )}
      <CreateListingModal
        isOpen={isCreateListingOpen}
        toggle={toggleCreateListing}
      />
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
      <ProcessUserModal isOpen={isProcessUserOpen} toggle={toggleProcessUser} />
    </div>
  );
};

export default ListingPageViewAll;
