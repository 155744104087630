import { AppState } from "../store/app-state/interfaces";
import * as JSON from "./json";

const APP_STATE_KEY = "APP_STATE";

/**
 * Retrieve application state from local storage.
 *
 * @export
 * @return {*}  {(Promise<AppState | null>)}
 */
export async function fetchApplicationState(): Promise<AppState | null> {
  const result = localStorage.getItem(APP_STATE_KEY);

  if (result) {
    return JSON.parse<AppState>(result);
  }

  return null;
}

/**
 * Persist application state to local storage.
 *
 * @export
 * @param {AppState} state
 * @return {*}  {Promise<void>}
 */
export async function saveApplicationState(state: AppState): Promise<void> {
  const serializedState = JSON.stringify<AppState>(state);
  return localStorage.setItem(APP_STATE_KEY, serializedState);
}
