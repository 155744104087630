import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pencil from "../../Assets/Images/Edit.png";
import LoadingSpinner from "../../Assets/Images/spinner.gif";
import useAuth from "../../hooks/useAuth";
import AzureBlobService from "../../lib/services/blob-service";

interface ProfilePicProps {
  onUpload: (name: string) => void;
}
const ProfilePic: React.FC<ProfilePicProps> = ({ onUpload }) => {
  const [user] = useAuth();
  const navigate = useNavigate();
  // current file to upload into container
  const [fileUploaded, setFileUploaded] = useState<string>("");

  // UI/form management
  const [uploading, setUploading] = useState<boolean>(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36));

  const onFileChange = async (event: any) => {
    // capture file into state
    if (event.target.files[0]) await FileUpload(event.target.files[0]);
  };

  const FileUpload = async (fileSelected: File) => {
    if (fileSelected && fileSelected?.name) {
      // prepare UI
      setUploading(true);

      // *** UPLOAD TO AZURE STORAGE ***
      await AzureBlobService.addProfilePicture(fileSelected, user);

      // reset state/form
      setFileUploaded(fileSelected.name);
      onUpload(fileSelected.name);
      setUploading(false);
      setInputKey(Math.random().toString(36));
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      if (user.ImageUrl && !fileUploaded) {
        setFileUploaded(user.ImageUrl);
        onUpload(user.ImageUrl);
      }
    }
  }, [fileUploaded, navigate, onUpload, user]);

  return (
    <section className="ProfilePictureSection">
      <div className="container">
        <div className="ProfilePictureMain">
          <input
            type="file"
            id="profile-pic-input"
            onChange={onFileChange}
            key={inputKey || ""}
            className="profile-pic-input"
            accept="image/*"
          />
          <label htmlFor="profile-pic-input">
            {uploading ? (
              <img
                className="profilePicture"
                src={LoadingSpinner}
                alt="profile-pic"
              />
            ) : (
              <img
                className="profilePicture"
                src={`https://scratchmaker.blob.core.windows.net/profiles/${
                  fileUploaded
                    ? `${user?.Username}_${fileUploaded}`
                    : user?.ImageUrl ?? "Default_PFP.jpg"
                }`}
                alt="profile-pic"
              />
            )}
          </label>

          <div className="profile-pic-edit">
            <img src={Pencil} alt="pencil" />
          </div>
        </div>
        <div className="wrap-user-info">
          <div className="info">
            <span className="username">{user?.Username}</span>
            <span className="email">{user?.Email}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfilePic;
