import styles from "./ProfileHighlight.module.css";
import { UserInformation } from "../../lib/models/user";
import { firstNameFromFullName } from "../../lib/helpers/texthelper";
interface ProfileHighlightProps {
  userInfo: UserInformation;
}

const ProfileHighlight: React.FC<ProfileHighlightProps> = ({ userInfo }) => {
  return (
    <div className={styles.content}>
      {/* TODO: Add highlights */}
      {/* <div className={styles.details}>
        <div className={styles.labelAndText}>
          <div className={styles.label}>Rating</div>
          <div className={styles.scratchmakerReview}>
            <div className={styles.content1}>
              <div className={styles.textAndSupportingText}>
                <div className={styles.rating}>
                  <div className={styles.stars}>
                    <img
                      className={styles.starIcon}
                      alt=""
                      src="/star-icon.svg"
                    />
                    <img
                      className={styles.starIcon}
                      alt=""
                      src="/star-icon.svg"
                    />
                    <img
                      className={styles.starIcon}
                      alt=""
                      src="/star-icon.svg"
                    />
                    <img
                      className={styles.starIcon}
                      alt=""
                      src="/star-icon.svg"
                    />
                    <img
                      className={styles.starIcon}
                      alt=""
                      src="/star-icon.svg"
                    />
                  </div>
                  <div className={styles.rating}>
                    <div className={styles.text}>4.9</div>
                    <div className={styles.supportingText}>(202)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.labelAndText1}>
          <div className={styles.label}>Badges</div>
          <div className={styles.scratchmakerReview1}>
            <div className={styles.badgeParent}>
              <img className={styles.badgeIcon} alt="" src="/badge@2x.png" />
            </div>
          </div>
        </div>
        <div className={styles.labelAndText2}>
          <div className={styles.label}>Lessons Given</div>
          <div className={styles.button}>
            <img className={styles.starIcon} alt="" src="/certificate01.svg" />
            <div className={styles.text1}>4,302</div>
          </div>
        </div>
        <div className={styles.labelAndText3}>
          <div className={styles.label}>Handicap</div>
          <div className={styles.button1}>
            <img className={styles.starIcon} alt="" src="/golf-stroke.svg" />
            <div className={styles.badge}>
              <div className={styles.text2}>+13</div>
            </div>
          </div>
        </div>
        <div className={styles.labelAndText4}>
          <div className={styles.label}>Location</div>
          <div className={styles.textAndIcon}>
            <img className={styles.starIcon} alt="" src="/au.svg" />
            <div className={styles.text3}>Sydney, Australia</div>
          </div>
        </div>
      </div> */}
      <div className={styles.leftColumn}>
        <div className={styles.sectionHeader}>
          <div className={styles.content2}>
            <div className={styles.textAndSupportingText2}>
              <div className={styles.text4}>
                About{" "}
                {firstNameFromFullName(userInfo.User.Name) ??
                  userInfo.User.Username}
              </div>
              <div className={styles.supportingText1}>
                <p className={styles.description}>
                  {userInfo.User.Description
                    ? userInfo.User.Description
                    : "Edit your profile to add a bio."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHighlight;
