import { Position } from "../../lib/models";
import { Listing } from "../../lib/models/listing";
import styles from "../../styles/ListingPageViewAll.module.css";

interface ListingSummaryProps {
  listings: Listing[];
  location: Position;
}

const ListingSummary: React.FC<ListingSummaryProps> = ({
  listings,
  location,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.container1}>
        <div className={styles.pageHeader}>
          <div className={styles.listingSummary}>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text16}>
                {`${listings.length} Listings near ${
                  location.name ?? "Current Location"
                }`}
              </div>
              <div className={styles.supportingText}>
                Find listings ranging from casual rounds to large outings
              </div>
            </div>
            {/* TODO: Add in share and save search */}
            {/* <div className={styles.content1}>
              <div className={styles.button2}>
                <div className={styles.text15}>Share</div>
              </div>
              <button className={styles.button3}>
                <img className={styles.searchLgIcon} alt="" src="/star01.svg" />
                <div className={styles.text20}>Save search</div>
              </button>
            </div> */}
            <div className={styles.inputDropdown}>
              <input
                className={styles.inputWithLabel}
                type="text"
                placeholder="Search"
              />
            </div>
          </div>
          <img className={styles.dividerIcon} alt="" src="/divider.svg" />
        </div>
      </div>
    </div>
  );
};
export default ListingSummary;
