import { useEffect, useMemo, useState } from "react";
import styles from "./CreateListing.module.css";
import { Listing, ListingTypeEnum } from "../../../../lib/models/listing";
import ChooseListingType from "./ChooseListingType";
import CreateOuting from "./CreateOuting";
import CreateTournament from "./CreateTournament";
import ListingCreated from "./ListingCreated";
import CreateCasual from "./CreateCasual";
interface CreateListingProps {
  onClose: () => void;
}
const CreateListing: React.FC<CreateListingProps> = ({ onClose }) => {
  const [step, setStep] = useState<number>(1);
  const [selectedListingType, setSelectedListingType] =
    useState<ListingTypeEnum>(ListingTypeEnum.Outing);

  const [createdListing, setCreatedListing] = useState<Listing>();

  const handleNextButtonClick = (type?: ListingTypeEnum) => {
    if (type) setSelectedListingType(type);
    setStep(step + 1);
  };

  const handleCreateListing = (listing: Listing) => {
    setCreatedListing(listing);
    setStep(step + 1);
  };
  useEffect(() => {
    if (step === 2) {
      if (selectedListingType === ListingTypeEnum.Outing) {
        setSelectedComponent(
          <CreateOuting
            onClose={onClose}
            handleCompleteClick={handleCreateListing}
          />
        );
      } else if (selectedListingType === ListingTypeEnum.Competitive) {
        setSelectedComponent(
          <CreateTournament
            onClose={onClose}
            handleCompleteClick={handleCreateListing}
          />
        );
      } else if (selectedListingType === ListingTypeEnum.Casual) {
        setSelectedComponent(
          <CreateCasual
            onClose={onClose}
            handleCompleteClick={handleCreateListing}
          />
        );
      }
    } else if (step === 3) {
      // show listing created
      if (createdListing) {
        setSelectedComponent(
          <ListingCreated onClose={onClose} listing={createdListing} />
        );
      }
    }
  }, [step, selectedListingType, onClose, createdListing]);

  const [selectedComponent, setSelectedComponent] = useState<JSX.Element>(
    <ChooseListingType
      onClose={onClose}
      handleNextButtonClick={handleNextButtonClick}
    />
  );

  return <div className={styles.modal}>{selectedComponent}</div>;
};

export default CreateListing;
