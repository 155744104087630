import React from "react";

interface Props {
  currentStep: number;
  clickCB: (step: number) => void;
}

const SignUpSteps: React.FC<Props> = ({ currentStep, clickCB }) => {
  const steps = [
    { name: "personal_info", label: "Personal Info" },
    { name: "golfer_info", label: "Golfer Info" },
    { name: "identification", label: "Identification" },
  ];
  const handleClick = (idx: number) => {
    if (idx <= currentStep) {
      clickCB(idx);
    }
  };
  return (
    <div className="step-wrapper">
      <nav>
        {steps.map((step, index) => (
          <button
            key={step.name}
            name={step.name}
            className={
              index < currentStep
                ? "completed"
                : index === currentStep
                ? "active"
                : "disabled"
            }
            onClick={() => handleClick(index)}
          >
            <span>{index + 1}</span>
            {step.label}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default SignUpSteps;
