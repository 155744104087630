import { Position } from "../../models/position";
import { User } from "../../models/user";
import { Dispatch } from "react";
import { AppState } from "./interfaces";
import { Golfer } from "../../models/golfer";
import { Message } from "../../models/chat";

/**
 * Available actions to modify app state.
 */
export type Action =
  | { type: "initialize"; appState: AppState }
  | { type: "set_position"; position: Position }
  | { type: "clear_position" }
  | { type: "set_user"; user: User }
  | { type: "clear_user" }
  | { type: "set_golfer"; golfer: Golfer }
  | { type: "clear_golfer" }
  | { type: "set_messages"; unreadMessages: Message[] }
  | { type: "clear_messages"; unreadMessages: [] }
  | { type: "remove_room_messages"; roomId: string };

const setPosition = (dispatch: Dispatch<Action>) => {
  return (position: Position) => {
    dispatch({ type: "set_position", position });
  };
};

const clearPosition = (dispatch: Dispatch<Action>) => {
  return () => {
    dispatch({ type: "clear_position" });
  };
};

const setUser = (dispatch: Dispatch<Action>) => {
  return (user: User) => {
    dispatch({ type: "set_user", user });
  };
};

const clearUser = (dispatch: Dispatch<Action>) => {
  return () => {
    dispatch({ type: "clear_user" });
  };
};

const setGolfer = (dispatch: Dispatch<Action>) => {
  return (golfer: Golfer) => {
    dispatch({ type: "set_golfer", golfer });
  };
};

const clearGolfer = (dispatch: Dispatch<Action>) => {
  return () => {
    dispatch({ type: "clear_golfer" });
  };
};

const setUnreadMessages = (dispatch: Dispatch<Action>) => {
  return (unreadMessages: Message[]) => {
    dispatch({ type: "set_messages", unreadMessages });
  };
};

const clearUnreadMessages = (dispatch: Dispatch<Action>) => {
  return () => {
    dispatch({ type: "clear_messages", unreadMessages: [] });
  };
};

const removeRoomMessages = (dispatch: Dispatch<Action>) => {
  return (roomId: string) => {
    dispatch({ type: "remove_room_messages", roomId });
  };
};

export {
  setPosition,
  clearPosition,
  setUser,
  clearUser,
  setGolfer,
  clearGolfer,
  setUnreadMessages,
  clearUnreadMessages,
  removeRoomMessages,
};
