import EditHeader from "./EditHeader";
import styles from "./EditProfile.module.css";
import Swal from "sweetalert2";
import {
  UpdateUserRequest,
  UserInformation,
} from "../../../../lib/models/user";
import { useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { charLeft } from "../../../../lib/helpers/texthelper";
import {
  LocationSearchResult,
  getPositionByPlaceId,
  searchByCity,
} from "../../../../lib/services/google-maps-service";
import ScratchApiService from "../../../../lib/services/scratch-api-service";
import { User } from "../../../../lib/models/user";
import {
  ImageCropper,
  ImageUploadingButton,
} from "../../../common/ImageUpload";
import { ImageType } from "react-images-uploading";

interface EditProfileProps {
  userInfo: UserInformation;
  editCB: (user: User) => void;
}

interface UserData {
  fullName: string | null;
  userName: string | null;
  bio: string | null;
  location: string | null;
  profilePhoto: string | null;
  handicap: number | null;
  phoneNumber: string | null;
  coverPhoto: string | null;
}

const EditProfile: React.FC<EditProfileProps> = ({ userInfo, editCB }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<LocationSearchResult[]>(
    []
  );
  const locRef = useRef<HTMLDivElement>(null);

  const [image, setImage] = useState<ImageType[]>([]);
  const [croppedProfileImage, setCroppedProfileImage] = useState<string | null>(
    null
  );
  const [croppedCoverImage, setCroppedCoverImage] = useState<string | null>(
    null
  );
  const [profileDialogOpen, setProfileDialogOpen] = useState<boolean>(false);
  const [coverDialogOpen, setCoverDialogOpen] = useState<boolean>(false);

  const selectLocation = async (placeId: string, description: string) => {
    const position = await getPositionByPlaceId(placeId);
    setSearchValue(description);

    setUserFormData((prevData) => {
      const newData = {
        ...prevData,
        location: position.name ?? null,
      };
      return newData;
    });

    setSearchResults([]);
  };

  const [userFormData, setUserFormData] = useState<UserData>({
    fullName: userInfo.User?.Name ?? null,
    userName: userInfo.User?.Username ?? null,
    handicap: userInfo.User?.Handicap ?? null,
    bio: userInfo.User?.Description ?? null,
    phoneNumber: userInfo.User?.PhoneNumber ?? null,
    location: userInfo.User?.Location ?? null,
    profilePhoto: userInfo.User?.ImageUrl ?? null,
    coverPhoto: userInfo.User?.CoverUrl ?? null,
  });

  const handleInputChange = (fieldName: keyof UserData, value: any) => {
    setUserFormData((prevData) => {
      const newData = {
        ...prevData,
        [fieldName]: value,
      };
      return newData;
    });
  };

  const handleCroppedProfile = (image: string) => {
    setCroppedProfileImage(image);
    setProfileDialogOpen(false);
    setUserFormData((prevData) => ({
      ...prevData,
      profilePhoto: image,
    }));
  };

  const handleCroppedCover = (image: string) => {
    setCroppedCoverImage(image);
    setCoverDialogOpen(false);
    setUserFormData((prevData) => ({
      ...prevData,
      coverPhoto: image,
    }));
  };

  const handleProfilePhotoUpload = (newImage: ImageType[]) => {
    setProfileDialogOpen(true);
    setImage(newImage);
  };

  const handleCoverPhotoUpload = (newImage: ImageType[]) => {
    setCoverDialogOpen(true);
    setImage(newImage);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    searchByCity(value).then(setSearchResults);
  };

  const onSave = async () => {
    let {
      fullName,
      userName,
      bio,
      phoneNumber,
      handicap,
      location,
      profilePhoto,
      coverPhoto,
    } = userFormData;
    // check if the username exists
    if (userName) {
      const userFound = await ScratchApiService.getUserByUsername(userName);
      if (userFound && userFound.Id !== userInfo.User.Id) {
        Swal.fire({
          title: "Username Already Exists",
          text: "Please change your desired username, as the request username already exists.",
          icon: "info",
        });
        return;
      }
    }
    if (bio) {
      if (bio.length > 1000) {
        Swal.fire({
          title: "Bio Description Exceeds Max Characters",
          text: "Please do not exceed 1000 characters in your bio.",
          icon: "info",
        });
        return;
      }
    }
    const updateUserRequest: UpdateUserRequest = {
      fullName,
      userName,
      bio,
      phoneNumber,
      location,
      handicap,
      profilePhoto,
      coverPhoto,
    };
    const hasValues = Object.values(updateUserRequest).some((value) => !!value);
    if (hasValues) {
      ScratchApiService.updateUser(userInfo.User.Id, updateUserRequest)
        .then((updatedUser) => {
          if (updatedUser) {
            editCB(updatedUser);
            Swal.fire({
              title: "User Settings Updated",
              text: "Your user settings have been successfully updated.",
              imageUrl: updatedUser.ImageUrl,
              imageAlt: updatedUser.Id,
              showCloseButton: true,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
              confirmButtonAriaLabel: "Thumbs up, great!",
            }).then((result) => {});
          }
        })
        .catch((err) => {
          // Handle unsuccessful update
          Swal.fire({
            title: "Error",
            text: "Failed to update user settings.",
            icon: "error",
          });
        });
    } else {
      Swal.fire({
        title: "No User Settings to Update",
        text: "Please provide at least one value to update your user settings.",
        icon: "info",
      });
    }
  };

  return (
    <div className={styles.content}>
      <EditHeader userInfo={userInfo} onSave={onSave} />
      <div className={styles.content1}>
        <div className={styles.form}>
          <div className={styles.row}>
            <div className={styles.text}>Full Name</div>
            <div className={styles.inputField}>
              <div className={styles.inputWithLabel}>
                <div className={styles.input}>
                  <div className={styles.content2}>
                    <input
                      type="text"
                      placeholder={userInfo.User.Name ?? "Enter Full Name"}
                      className={styles.inputText}
                      value={userFormData.fullName ?? ""}
                      onChange={(e) =>
                        handleInputChange("fullName", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <div className={styles.text}>Username</div>
            <div className={styles.inputField1}>
              <div className={styles.inputWithLabel}>
                <div className={styles.label}>Username</div>
                <div className={styles.input1}>
                  <div className={styles.addOn}>
                    <div className={styles.text3}>
                      scratchmakergolf.com/profile/
                    </div>
                  </div>
                  <div className={styles.textInput}>
                    <input
                      type="text"
                      className={styles.inputText}
                      placeholder={userInfo.User.Username}
                      value={userFormData.userName ?? ""}
                      onChange={(e) =>
                        handleInputChange("userName", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <div className={styles.text}>Your Location</div>
            <div className={styles.inputFieldAndCheckbox}>
              <div className={styles.searchCity}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input}>
                    <div className={styles.content7}>
                      <img
                        className={styles.chevronDownIcon}
                        alt=""
                        src="/searchlg.svg"
                      />
                      <input
                        className={styles.inputText}
                        type="text"
                        placeholder={userInfo.User.Location ?? "Enter a city"}
                        value={
                          searchValue != null
                            ? searchValue
                            : userFormData.location
                            ? userFormData.location
                            : ""
                        }
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
                {searchResults.length > 0 && (
                  <div className={styles.dropdown} ref={locRef}>
                    {searchResults.slice(0, 10).map((loc) => (
                      <div
                        onClick={() =>
                          selectLocation(loc.placeId, loc.description)
                        }
                        className={styles.dropdownRow}
                        key={loc.placeId}
                      >
                        <span className="loc-description">
                          {loc.description}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <div className={styles.text}>Handicap</div>
            <div className={styles.inputField1}>
              <div className={styles.inputWithLabel}>
                <div className={styles.label}>Handicap</div>
                <div className={styles.input1}>
                  <div className={styles.addOn}>
                    <img
                      className={styles.searchLgIcon}
                      alt=""
                      src="/golf-stroke.svg"
                    />
                  </div>
                  <div className={styles.textInput}>
                    <input
                      type="number"
                      className={styles.inputText}
                      value={userFormData.handicap ?? ""}
                      placeholder={
                        userInfo.User.Handicap
                          ? userInfo.User.Handicap.toString()
                          : "Enter Handicap"
                      }
                      onChange={(e) =>
                        handleInputChange("handicap", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <div className={styles.text}>Phone Number</div>
            <div className={styles.inputField1}>
              <div className={styles.inputWithLabel}>
                <div className={styles.label}>Phone Number</div>
                <div className={styles.input1}>
                  <div className={styles.addOn}>
                    <img
                      className={styles.searchLgIcon}
                      alt=""
                      src="/phone.svg"
                    />
                  </div>
                  <div className={styles.textInput}>
                    <input
                      type="tel"
                      className={styles.inputText}
                      value={userFormData.phoneNumber ?? ""}
                      placeholder={
                        userInfo.User.PhoneNumber
                          ? userInfo.User.PhoneNumber
                          : "Enter Phone Number"
                      }
                      onChange={(e) =>
                        handleInputChange("phoneNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <div className={styles.text}>Profile Image</div>
            <div className={styles.avatarAndFileUpload}>
              <img
                className={styles.contentIcon}
                alt=""
                src={
                  croppedProfileImage
                    ? croppedProfileImage
                    : userInfo.User.ImageUrl ?? "/featured-icon3.svg"
                }
              />

              <ImageCropper
                open={profileDialogOpen}
                image={image.length > 0 ? image[0].dataURL ?? null : null}
                onComplete={handleCroppedProfile}
                header={"Crop Profile Photo"}
                circle={true}
                containerStyle={{
                  position: "relative",
                  width: "100%",
                  height: 300,
                  background: "#333",
                }}
                aspectRatio={1}
              />
              <ImageCropper
                open={coverDialogOpen}
                image={image.length > 0 ? image[0].dataURL ?? null : null}
                onComplete={handleCroppedCover}
                header={"Crop Profile Cover Photo"}
                circle={false}
                containerStyle={{
                  position: "relative",
                  width: "100%",
                  height: 300,
                  background: "#333",
                }}
                aspectRatio={4 / 1.5}
              />
              <div className={styles.fileUpload}>
                <div className={styles.fileUploadBase}>
                  <div className={styles.content4}>
                    <label className={styles.fileUploadContent}>
                      <div className={styles.textAndSupportingText}>
                        <div className={styles.action}>
                          <div className={styles.button}>
                            <div className={styles.text6}>Click to upload</div>
                          </div>
                        </div>
                        <div className={styles.supportingText}>
                          SVG, PNG, or JPG
                        </div>
                      </div>
                      <ImageUploadingButton
                        value={image}
                        onChange={handleProfilePhotoUpload}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.text}>Cover Image</div>
            <div className={styles.avatarAndFileUpload}>
              <img
                className={styles.contentIcon}
                alt=""
                src={
                  croppedCoverImage
                    ? croppedCoverImage
                    : userInfo.User.CoverUrl ?? "/featured-icon3.svg"
                }
              />
              <div className={styles.fileUpload}>
                <div className={styles.fileUploadBase}>
                  <div className={styles.content4}>
                    <label className={styles.fileUploadContent}>
                      <div className={styles.textAndSupportingText}>
                        <div className={styles.action}>
                          <div className={styles.button}>
                            <div className={styles.text6}>Click to upload</div>
                          </div>
                        </div>
                        <div className={styles.supportingText}>
                          SVG, PNG, or JPG
                        </div>
                      </div>
                      <ImageUploadingButton
                        value={image}
                        onChange={handleCoverPhotoUpload}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <div className={styles.textAndSupportingText2}>
              <div className={styles.text}>Your Bio</div>
              <div className={styles.supportingText2}>
                Write a short introduction about yourself
              </div>
            </div>
            <div className={styles.toolbarAndTextInput}>
              <div className={styles.inputWithLabel}>
                <div className={styles.inputWithLabel3}>
                  <div className={styles.label}>Description</div>
                  <div className={styles.input3}>
                    <div className={styles.text13}>
                      <textarea
                        className={styles.textarea}
                        value={userFormData.bio ?? ""}
                        placeholder={
                          userInfo.User.Description ??
                          "Write a few sentences about yourself..."
                        }
                        onChange={(e) =>
                          handleInputChange("bio", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className={styles.supportingText2}>
                  {charLeft(userFormData.bio, 1000)} characters left
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
