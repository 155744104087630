const ClientStories = () => {
  return (
    <section className="clientStoriesSection">
      <div className="wrap-client-stories">
        <header>
          <h2>Scratchmaker Stories</h2>
        </header>
        <div className="background-client-stories">
          <div className="buyer1">
            <blockquote>
              "Scratchmaker became a great way to use my golf game to make extra
              money."
              <br />
              <cite>Adam Scheff, College Golfer</cite>
            </blockquote>
          </div>
          <div className="buyer2">
            <blockquote>
              "If you're a golf pro, Scratchmaker is a great way to diversify
              your service offering."
              <br />
              <cite>Paul Roth, Golf Pro</cite>
            </blockquote>
          </div>
          <div className="buyer3">
            <blockquote>
              "I usually just play on weekends, but my low handicap earned me
              extra money while making new professional connections."
              <br />
              <cite>Stephanie Sunami, Golf Hobbyist</cite>
            </blockquote>
          </div>
          <div className="buyer4">
            <blockquote>
              "Scratchmaker has been a great platform for me to advertise my
              golf lesson services. I have received a steady stream of new
              students through the platform and have been able to grow my
              business as a result."
              <br />
              <cite>Jeff Goldbloom, Golf Pro</cite>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientStories;
