const ProductCards = () => {
  return (
    <div className="wrap-product-cards">
      <div className="col product-card">
        <h2 className="title">Learn and Improve</h2>
        <p className="subtitle">
          Find a Scratchmaker to give you lessons. We bring you the most skilled
          golfers in your area at the cheapest price possible.
        </p>
        <div className="imgWrapper">
          <div className="img purify_cmTcNdmK0kI5aOb3laN2ww==">
            <img
              src="https://dk2h3gy4kn9jw.cloudfront.net/web-2019/fa984a1d/img/customer-app-en.d0ba412.webp"
              alt="Booksy app"
            />
          </div>
        </div>
      </div>
      <div className="col product-card">
        <h2 className="title">
          Find &amp; book <b>an appointment</b>
        </h2>
        <p className="subtitle">
          Cut the phone tag. Find your next appointment and{" "}
          <b>book instantly</b> anytime, anywhere.
        </p>
        <div className="imgWrapper">
          <div className="img purify_cmTcNdmK0kI5aOb3laN2ww==">
            <img
              src="https://dk2h3gy4kn9jw.cloudfront.net/web-2019/fa984a1d/img/customer-app-en.d0ba412.webp"
              alt="Booksy app"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCards;
