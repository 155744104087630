import styles from "./ListingModal.module.css";
import {
  Listing,
  ListingTypeEnum,
  ListingTypeImageMap,
} from "../../../lib/models/listing";
import Moment from "react-moment";
import ListingLocationMap from "./ListingLocationMap";
import { useNavigate } from "react-router-dom";
import useShareModal from "../../../hooks/useShareModal";
import ShareModal from "./ShareModal";
import { Reservation } from "../../../lib/models/reservation";
import useAuth from "../../../hooks/useAuth";
import useLoginModal from "../../../hooks/useLoginModal";
import LoginModal from "../auth/LoginModal";
import { useEffect, useRef, useState } from "react";
import ProcessUserModal from "../auth/process-user/ProcessUserModal";
import useProcessUserModal from "../../../hooks/useProcessUserModal";

interface ViewListingProps {
  listingType: ListingTypeEnum;
  listing: Listing;
  onClose: () => void;
}
const ViewListing: React.FC<ViewListingProps> = ({
  listingType,
  listing,
  onClose,
}) => {
  const navigate = useNavigate();
  const [user] = useAuth();
  const { isShareOpen, toggleShare } = useShareModal();
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const { isProcessUserOpen, toggleProcessUser } = useProcessUserModal();
  const [expanded, setExpanded] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [alreadyBooked, setAlreadyBooked] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleReservation = () => {
    if (!user) {
      toggleLogin();
    } else {
      if (user.Location) {
        onClose();
        navigate(`/checkout/${listing.Id}`);
      } else {
        toggleProcessUser();
      }
    }
  };
  const handleAttendingClick = (reservation: Reservation) => {
    const userName = reservation.User.Username;
    navigate(`/profile/${userName}`);
  };

  const handleAvatarClick = () => {
    const userName = listing.User.Username;
    navigate(`/profile/${userName}`);
  };

  const toggleSeeMore = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      setShowSeeMore(
        contentRef.current.scrollHeight > contentRef.current.clientHeight
      );
    }
  }, []);

  useEffect(() => {
    if (user && listing.Reservations && listing.Reservations.length > 0) {
      const isUserBooked = listing.Reservations.some(
        (reservation) => reservation.User.Id === user.Id
      );
      setAlreadyBooked(isUserBooked);
    }
  }, [listing.Reservations, user]);

  return (
    <>
      <div className={styles.listingModal}>
        <div className={styles.modalFrame}>
          <div className={styles.container}>
            <div className={styles.contentWrapper}>
              <div className={styles.wrapLeftRight}>
                <div className={styles.leftContent}>
                  <div className={styles.item10Parent}>
                    <div className={styles.item10}>
                      <div className={styles.golfBallWrapper}>
                        <img
                          className={styles.golfBallIcon}
                          alt=""
                          src={ListingTypeImageMap[listingType]}
                        />
                      </div>
                      <div className={styles.text140}>{listingType}</div>
                    </div>
                    <div className={styles.heading3}>{listing.Title}</div>
                  </div>
                  <div className={styles.content49}>
                    <div className={styles.rightContent}>
                      <div className={styles.tagList}>
                        <div className={styles.tagListItems}>
                          {listing.Format && (
                            <div className={styles.badge17}>
                              <div className={styles.badge18}>
                                <img
                                  className={styles.featuredIcon}
                                  alt=""
                                  src="/flag052.svg"
                                />
                                <div className={styles.tag}>
                                  {listing.Format}
                                </div>
                              </div>
                            </div>
                          )}
                          {listing.Prize && (
                            <div className={styles.badge17}>
                              <div className={styles.badge18}>
                                <img
                                  className={styles.featuredIcon}
                                  alt=""
                                  src="/trophy01.svg"
                                />
                                <div className={styles.tag}>
                                  ${listing.Prize} Purse
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.section3}>
                      <div className={styles.heading4}>Description</div>
                      <div
                        ref={contentRef}
                        className={`${styles.paragraph} ${
                          expanded ? styles.expanded : ""
                        }`}
                        id="description"
                      >
                        <div
                          className={styles.desc}
                          dangerouslySetInnerHTML={{
                            __html: listing.Description,
                          }}
                        ></div>
                      </div>
                      {showSeeMore && (
                        <button
                          className={styles.collapseButton}
                          onClick={toggleSeeMore}
                          id="collapse-button"
                        >
                          {expanded ? "- Read Less" : "+ Read More"}
                        </button>
                      )}
                    </div>
                    <div className={styles.section4}>
                      <div className={styles.metricItemWrapper}>
                        <div className={styles.content50}>
                          <div className={styles.labelAndText}>
                            <div className={styles.label7}>Date</div>
                            <div className={styles.button36}>
                              <img
                                className={styles.homeLineIcon}
                                alt=""
                                src="/calendar.svg"
                              />
                              <div className={styles.text29}>
                                <Moment format="MMM DD, YYYY">
                                  {listing.EventDate.toString()}
                                </Moment>
                              </div>
                            </div>
                          </div>
                          <div className={styles.labelAndText}>
                            <div className={styles.label7}>Time</div>
                            <div className={styles.button36}>
                              <img
                                className={styles.homeLineIcon}
                                alt=""
                                src="/alarmclock.svg"
                              />
                              <div className={styles.text29}>
                                <Moment format="h:mm A">
                                  {listing.EventDate.toString()}
                                </Moment>
                              </div>
                            </div>
                          </div>
                          {listing.Price && (
                            <div className={styles.labelAndText}>
                              <div className={styles.label7}>
                                {listing.ListingType.Type ===
                                ListingTypeEnum.Casual
                                  ? "Green Fees"
                                  : "Price"}
                              </div>
                              <div className={styles.button36}>
                                <img
                                  className={styles.homeLineIcon}
                                  alt=""
                                  src="/currencydollar.svg"
                                />
                                <div className={styles.text29}>
                                  {listing.Price}
                                </div>
                              </div>
                            </div>
                          )}
                          {listing.WagerAmount && (
                            <div className={styles.labelAndText}>
                              <div className={styles.label7}>Wager</div>
                              <div className={styles.button36}>
                                <div className={styles.text29}>
                                  ${listing.WagerAmount}
                                </div>
                              </div>
                            </div>
                          )}
                          {listing.Prize && (
                            <div className={styles.labelAndText}>
                              <div className={styles.label7}>Purse</div>
                              <div className={styles.button36}>
                                <img
                                  className={styles.homeLineIcon}
                                  alt=""
                                  src="/piggybank01.svg"
                                />
                                <div className={styles.text29}>
                                  {listing.Prize}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {listing.Reservations != null &&
                      listing.TotalSlots != null && (
                        <div className={styles.section5}>
                          <div className={styles.metricItemWrapper}>
                            <div className={styles.containre10}>
                              <div className={styles.chart}>
                                <div className={styles.spotsTargetChart} />
                                <div
                                  className={styles.slotsIndicator}
                                  style={{
                                    width: `${
                                      listing.Reservations.length > 0
                                        ? `${
                                            (listing.Reservations.length /
                                              listing.TotalSlots) *
                                            100
                                          }%`
                                        : "20px"
                                    }`,
                                  }}
                                />
                              </div>
                              <div className={styles.avatars}>
                                <div className={styles.spots}>
                                  <div className={styles.spotsFilled}>
                                    {listing.Reservations.length}
                                  </div>
                                  <div className={styles.spotsTarget}>
                                    {`/${listing.TotalSlots} Spots`}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    <div className={styles.buttonGroupWrapper}>
                      <div className={styles.buttonGroup2}>
                        {!alreadyBooked ? (
                          listing.Reservations &&
                          listing.TotalSlots &&
                          listing.Reservations.length < listing.TotalSlots ? (
                            <div
                              className={styles.bookButton}
                              onClick={handleReservation}
                            >
                              <div className={styles.text}>
                                Make a Reservation
                              </div>
                            </div>
                          ) : (
                            <div className={styles.bookButtonBooked}>
                              <div className={styles.text}>Listing Full</div>
                            </div>
                          )
                        ) : (
                          <div className={styles.bookButtonBooked}>
                            <div className={styles.text}>Already Booked</div>
                          </div>
                        )}

                        <div className={styles.cancel}>
                          <div
                            className={styles.shareButton}
                            onClick={toggleShare}
                          >
                            <img
                              className={styles.homeLineIcon}
                              alt=""
                              src="/send01.svg"
                            />
                            <div className={styles.text}>Share</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.rightContent}>
                  <div className={styles.metricItemWrapper}>
                    <div className={styles.metricItem}>
                      <div
                        className={styles.wrapAvatar}
                        onClick={handleAvatarClick}
                      >
                        <div className={styles.avatar}>
                          <img
                            className={styles.avatarIcon}
                            alt=""
                            src={listing.User.ImageUrl}
                          />
                          {/* <div className={styles.avatarInner}>
                              <div className={styles.rectangleParent}>
                                <img
                                  className={styles.layer2Copy}
                                  alt=""
                                  src="/Scratchmaker_Strikethrough.png"
                                />
                              </div>
                            </div> */}
                        </div>
                        <div className={styles.content1}>
                          <div className={styles.textAndSupportingText}>
                            <div className={styles.textAndSubtext}>
                              <div className={` ${styles.userName}`}>
                                {listing.User.Username}
                              </div>
                            </div>
                            {/* TODO: Add stars */}
                            <div className={styles.rating}>
                              {listing.User.Location && (
                                <div className={styles.supportingText}>
                                  <img
                                    className={styles.heartIcon}
                                    alt=""
                                    src="/markerpin02.svg"
                                  />
                                  {`${listing.User.Location}`}
                                </div>
                              )}
                              {listing.User.Handicap != null && (
                                <div className={styles.supportingText}>
                                  Handicap:{" "}
                                  <span>{`${listing.User.Handicap}`}</span>
                                </div>
                              )}
                              {/*  <div className={styles.starsWrap}>
                                  <img
                                    className={styles.star}
                                    alt=""
                                    src="/star-icon.svg"
                                  />
                                  <img
                                    className={styles.star}
                                    alt=""
                                    src="/star-icon.svg"
                                  />
                                  <img
                                    className={styles.star}
                                    alt=""
                                    src="/star-icon.svg"
                                  />
                                  <img
                                    className={styles.star}
                                    alt=""
                                    src="/star-icon.svg"
                                  />
                                  <img
                                    className={styles.star}
                                    alt=""
                                    src="/star-icon.svg"
                                  />
                                </div>
                                <div className={styles.ratingWrap}>
                                  <div className={styles.text30}>4.9</div>
                                </div>
                                <div>(202)</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ListingLocationMap listing={listing} />
                  {listing.Reservations && listing.Reservations.length > 0 && (
                    <div className={styles.metricItemContainer}>
                      <div className={styles.metricItem1}>
                        <div className={styles.heading5}>Attending</div>
                        <div className={styles.numberAndBadge}>
                          <div className={styles.number7}>
                            {listing.Reservations.length}
                          </div>
                          <div className={styles.badgeWrap}>
                            <div className={styles.avatarGroup}>
                              <div className={styles.avatars}>
                                {listing.Reservations.slice(0, 8).map(
                                  (reservation) => (
                                    <img
                                      onClick={() =>
                                        handleAttendingClick(reservation)
                                      }
                                      className={styles.avatarIcon2}
                                      alt=""
                                      src={reservation.User.ImageUrl}
                                      key={reservation.Id}
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.dropdown2}>
                          <img
                            className={styles.homeLineIcon}
                            alt=""
                            src="/dotsvertical1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.modalActions}>
              {!alreadyBooked ? (
                listing.Reservations &&
                listing.TotalSlots &&
                listing.Reservations.length < listing.TotalSlots ? (
                  <div
                    className={styles.bookButton}
                    onClick={handleReservation}
                  >
                    <div className={styles.text}>Make a Reservation</div>
                  </div>
                ) : (
                  <div className={styles.bookButtonBooked}>
                    <div className={styles.text}>Listing Full</div>
                  </div>
                )
              ) : (
                <div className={styles.bookButtonBooked}>
                  <div className={styles.text}>Already Booked</div>
                </div>
              )}

              <div className={styles.cancel}>
                <div className={styles.shareButton} onClick={toggleShare}>
                  <img
                    className={styles.homeLineIcon}
                    alt=""
                    src="/send01.svg"
                  />
                  <div className={styles.text}>Share</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.modalClose} onClick={onClose}>
        <img className={styles.socialIcon} alt="" src="/xcircle.svg" />
      </div>
      <ShareModal isOpen={isShareOpen} toggle={toggleShare} listing={listing} />
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
      <ProcessUserModal isOpen={isProcessUserOpen} toggle={toggleProcessUser} />
    </>
  );
};
export default ViewListing;
