import styles from "./EventsBlock.module.css";
import { UserInformation } from "../../lib/models/user";
import { Reservation } from "../../lib/models/reservation";
import {
  Listing,
  ListingTypeEnum,
  ListingTypeEnumMap,
  ListingViewType,
} from "../../lib/models/listing";
import Tournament from "../ListingsPageViewAll/Tournament";
import Outing from "../ListingsPageViewAll/Outing";
import usePagination from "../../hooks/usePagination";
import CasualCard from "../ListingsPageViewAll/CasualCard";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useMemo } from "react";
import ProcessUserModal from "../common/auth/process-user/ProcessUserModal";
import CreateListingModal from "../common/listings/create-listing-modal/CreateListingModal";
import LoginModal from "../common/auth/LoginModal";
import useProcessUserModal from "../../hooks/useProcessUserModal";
import useCreateListingModal from "../../hooks/useCreateListingModal";
import useLoginModal from "../../hooks/useLoginModal";

interface EventsBlockProps {
  userInfo: UserInformation;
}

const EventsBlock: React.FC<EventsBlockProps> = ({ userInfo }) => {
  const navigate = useNavigate();
  const [user] = useAuth();
  const { isCreateListingOpen, toggleCreateListing } = useCreateListingModal();
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const { isProcessUserOpen, toggleProcessUser } = useProcessUserModal();

  const handleListingClick = (listing: Listing, type: ListingTypeEnum) => {
    navigate(`/listings/All/${listing.Id}`);
  };

  const filteredReservations = useMemo(() => {
    return userInfo.Reservations.filter(
      (reservation) => new Date(reservation.Listing.EventDate) > new Date()
    );
  }, [userInfo.Reservations]);

  const { page, totalPages, filteredArray, handleBackPage, handleForwardPage } =
    usePagination({
      array: filteredReservations,
      pageSize: 3,
    });

  const cardFromReservation = (reservation: Reservation) => {
    const listingType = ListingTypeEnumMap[reservation.Listing.ListingTypeId];
    switch (listingType) {
      case ListingTypeEnum.Competitive:
        return (
          <Tournament
            listing={reservation.Listing}
            listingViewType={ListingViewType.Write}
            onListingClick={handleListingClick}
          />
        );
      case ListingTypeEnum.Outing:
        return (
          <Outing
            listing={reservation.Listing}
            listingViewType={ListingViewType.Write}
            onListingClick={handleListingClick}
          />
        );

      case ListingTypeEnum.Casual:
        return (
          <CasualCard
            listing={reservation.Listing}
            listingViewType={ListingViewType.Write}
            onListingClick={handleListingClick}
          />
        );
    }
  };
  const noFindingsTsx = () => {
    return (
      <div className={styles.section}>
        <div className={`${styles.noListings}`}>
          <span>No events found.</span>
          <div className={styles.promoteGrowth}>
            <span>Create your own listing to start playing in your area!</span>
            <button onClick={handleCreateClick}>Create Listing</button>
          </div>
        </div>
      </div>
    );
  };
  const handleCreateClick = () => {
    if (user) {
      if (user.Location) {
        toggleCreateListing();
      } else {
        toggleProcessUser();
      }
    } else {
      toggleLogin();
    }
  };
  return (
    <div className={styles.content}>
      <div className={styles.sectionHeader}>
        <div className={styles.content1}>
          <div className={styles.content2}>
            <div className={styles.text}>{`${
              user?.Username === userInfo.User.Username
                ? "Upcoming"
                : `${userInfo.User.Username}'s`
            } Events`}</div>
          </div>
          {userInfo.Reservations.length > 3 && (
            <div className={styles.dropdown}>
              <div className={styles.frameParent}>
                <div className={styles.pageIndicator}>
                  Page {page} of {totalPages}
                </div>
                {page > 1 && (
                  <div className={styles.arrowWrapper} onClick={handleBackPage}>
                    <img
                      className={styles.arrowLeftIcon}
                      alt=""
                      src="/arrowleft.svg"
                    />
                  </div>
                )}

                {page < totalPages && (
                  <div
                    className={styles.arrowWrapper}
                    onClick={handleForwardPage}
                  >
                    <img
                      className={styles.arrowLeftIcon}
                      alt=""
                      src="/arrownarrowright.svg"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {filteredArray.length > 0 ? (
        <div className={styles.experience}>
          <div className={styles.content3}>
            {filteredArray.map((res) => {
              return (
                <div key={res.Id} className={styles.wrapCard}>
                  {cardFromReservation(res)}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        noFindingsTsx()
      )}
      <CreateListingModal
        isOpen={isCreateListingOpen}
        toggle={toggleCreateListing}
      />
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
      <ProcessUserModal isOpen={isProcessUserOpen} toggle={toggleProcessUser} />
    </div>
  );
};

export default EventsBlock;
