import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ProfilePic from "./ProfilePic";
import LocationSearch from "../Checkout/LocationSearch";

interface PersonalInfoProps {
  currentStep: number;
  clickCB: (step: number) => void;
  stateCB: (state: PersonalInfoState) => void;
  sessionState: PersonalInfoState | null | undefined;
}

export interface PersonalInfoState {
  firstName: string;
  lastName: string;
  dob: string;
  address: google.maps.places.AutocompletePrediction | null;
  phone: string;
  imageUrl: string;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({
  currentStep,
  clickCB,
  stateCB,
  sessionState,
}) => {
  const [formData, setFormData] = useState<PersonalInfoState>({
    firstName: "",
    lastName: "",
    dob: "",
    address: null,
    phone: "",
    imageUrl: "",
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLocationChange = (
    loc: google.maps.places.AutocompletePrediction
  ) => {
    setFormData({ ...formData, address: loc });
  };

  const handleContinue = () => {
    if (validateForm()) {
      stateCB(formData);
      clickCB(currentStep + 1);
    }
  };

  const validateForm = () => {
    if (formData.firstName.length < 1 || formData.lastName.length < 1) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "You must provide your full name.",
      });
      return false;
    } else if (formData.imageUrl.length < 1) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "You must provide a profile picture.",
      });
      return false;
    } else if (formData.dob.length < 1) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "You must provide a date of birth.",
      });
      return false;
    } else if (!formData.address) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "You must provide your address.",
      });
      return false;
    } else if (formData.phone.length < 1) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "You must provide a phone number.",
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (sessionState) {
      setFormData(sessionState);
    }
  }, [sessionState]);

  const handleImageUpload = (imgUrl: string) => {
    setFormData({ ...formData, imageUrl: imgUrl });
  };
  return (
    <div className="signup-form-wrapper">
      <div className="signup-title">
        <h2>Personal Information</h2>
        <p>
          Introduce yourself as a Scratchmaker. The information you provide will
          be displayed on your public profile, allowing prospective customers to
          learn more about your background and expertise.
        </p>
      </div>
      <div className="signup-form-fields">
        <ProfilePic onUpload={handleImageUpload} />

        <div className="field-row">
          <p>Full Name:</p>
          <div>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
            />
          </div>
        </div>
        <div className="field-row">
          <p>Date of Birth:</p>
          <div>
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              placeholder="Date of Birth"
              required
            />
          </div>
        </div>
        <div className="field-row">
          <p>Address:</p>
          <div>
            <LocationSearch
              handleSelectLocation={handleLocationChange}
              curValue={formData?.address ?? undefined}
            />
          </div>
        </div>
        <div className="field-row">
          <p>Phone Number:</p>
          <div>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone Number"
              required
            />
          </div>
        </div>
        {/* <div className="field-row">
          <p>Identification:</p>
          <div>
            <LicenseUpload />
          </div>
        </div> */}
      </div>
      <span className="greenBtn" onClick={handleContinue}>
        Continue
      </span>
    </div>
  );
};
export default PersonalInfo;
