import React from "react";
import arrow from "../../Assets/Images/buttonArrow.png";
import downarrow from "../../Assets/Images/downHeaderArrow.svg";

const Hero = () => {
  return (
    <section className="header">
      <div className="container hero">
        <h1>Scratchmaker</h1>
      </div>
    </section>
  );
};

export default Hero;
