import ActiveTarget from "./ActiveTarget";
import Messages from "./Messages";
import MessageInput from "./MessageInput";
import styles from "./MessageWindow.module.css";
import { Message, Room } from "../../lib/models/chat";
import io, { Socket } from "socket.io-client";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import useMessages from "../../hooks/useMessages";

interface MessageWindowProps {
  room: Room;
  messages: Message[];
  addMessageToRoom: (message: Message) => void;
  handleBack: () => void;
}

const MessageWindow: React.FC<MessageWindowProps> = ({
  room,
  messages,
  addMessageToRoom,
  handleBack,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [user] = useAuth();
  const [currentMessage, setCurrentMessage] = useState("");
  const [unreadMessages, , , removeRoomMessages] = useMessages();

  useEffect(() => {
    setSocket(io());

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (user && room && socket) {
      socket.emit("join_room", room.Id);
    }
  }, [room, socket, user]);

  useEffect(() => {
    if (socket) {
      socket.on("receive_message", (data: Message) => {
        if (!data) {
          alert("Failed to receive message");
        } else {
          // updateMessages
          addMessageToRoom(data);
          removeRoomMessages(data.MessageRoomId);
        }
      });
    }
  }, [socket]);

  const sendMessage = () => {
    if (currentMessage !== "" && user && socket) {
      const messageData: Message = {
        MessageRoomId: room.Id,
        SenderUserId: user.Id,
        User: user,
        Message: currentMessage,
        TimeSent: new Date(Date.now()),
      };

      socket.emit("send_message", messageData);
      setCurrentMessage("");
    }
  };
  return (
    <>
      {user && room && (
        <div className={styles.content}>
          <div className={styles.backButton} onClick={handleBack}>
            <img src="/arrowleftwhite.svg" alt="arrowleft" />
            Back
          </div>
          <ActiveTarget room={room} />
          <Messages room={room} messages={messages} />
          <MessageInput
            currentMessage={currentMessage}
            onChange={setCurrentMessage}
            onSend={sendMessage}
          />
        </div>
      )}
    </>
  );
};

export default MessageWindow;
