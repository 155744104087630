import { Position } from "../models/position";

export interface LocationSearchResult {
  placeId: string;
  description: string;
}

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

const services = {
  autocomplete: new google.maps.places.AutocompleteService(),
  geocoder: new google.maps.Geocoder(),
};

/**
 * Use the Google Maps Places API to search for locations by zip code.
 *
 * @param {(string | undefined)} postalCode
 * @return {*}  {Promise<LocationSearchResult[]>}
 */
const searchByPostalCode = (
  postalCode: string | undefined
): Promise<LocationSearchResult[]> => {
  return new Promise((resolve) => {
    // empty result set if no search term is passed
    if (!postalCode || postalCode.length === 0) {
      resolve([]);
      return;
    }

    const processResults = (predictions: AutocompletePrediction[] | null) => {
      // only return hits on postal code, map into the result model
      const results = (predictions || [])
        .filter((p) => p.types.includes("postal_code"))
        .map<LocationSearchResult>((p) => {
          return {
            placeId: p.place_id,
            // change default result format "City, State ZIP, USA" to just "City, State ZIP"
            description: p.description.replace(", USA", ""),
          };
        });
      resolve(results);
    };

    services.autocomplete.getPlacePredictions(
      {
        input: postalCode,
        componentRestrictions: {
          country: ["us"],
        },
        types: ["(regions)"],
      },
      processResults
    );
  });
};

const searchByCity = (
  city: string | undefined
): Promise<LocationSearchResult[]> => {
  return new Promise((resolve) => {
    // Empty result set if no search term is passed
    if (!city || city.length === 0) {
      resolve([]);
      return;
    }

    const processResults = (predictions: AutocompletePrediction[] | null) => {
      // Only return hits on locality (city), map into the result model
      const results = (predictions || [])
        .filter((p) => p.types.includes("locality"))
        .map((p) => {
          return {
            placeId: p.place_id,
            description: p.description,
          };
        });
      resolve(results);
    };

    services.autocomplete.getPlacePredictions(
      {
        input: city,
        componentRestrictions: {
          country: ["us"],
        },
        types: ["(cities)"],
      },
      processResults
    );
  });
};

/**
 * Get details for a Google Places API location.
 *
 * @param {string} placeId
 * @return {*}  {Promise<Position>}
 */
const getPositionByPlaceId = (placeId: string): Promise<Position> => {
  return new Promise((resolve, reject) => {
    const processResults = (
      results: google.maps.GeocoderResult[] | null,
      status: google.maps.GeocoderStatus
    ) => {
      if (
        status === google.maps.GeocoderStatus.OK &&
        results &&
        results.length > 0
      ) {
        resolve({
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng(),
          name: results[0].formatted_address.replace(", USA", ""),
        });
      } else {
        reject(status);
      }
    };

    services.geocoder.geocode({ placeId }, processResults);
  });
};

const getPositionDescription = (
  lat: number,
  lng: number
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const processResults = (
      results: google.maps.GeocoderResult[] | null,
      status: google.maps.GeocoderStatus
    ) => {
      if (
        status === google.maps.GeocoderStatus.OK &&
        results &&
        results.length > 0
      ) {
        const descriptions: string[] = results
          ?.filter((r) => r.types.includes("postal_code"))
          .map((r) => r.formatted_address.replace(", USA", ""));

        resolve(descriptions?.length > 0 ? descriptions[0] : undefined);
      } else {
        reject(status);
      }
    };

    services.geocoder.geocode(
      {
        location: { lat, lng },
      },
      processResults
    );
  });
};

export type { Position };
export {
  getPositionByPlaceId,
  getPositionDescription,
  searchByPostalCode,
  searchByCity,
};
