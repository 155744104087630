import { FacebookIcon, GoogleIcon } from "../../common/Content";
import Modal from "../../common/Modal";
import { useMemo, useState } from "react";
import { ModalType } from "./LoginModal";
import AuthService from "../../../lib/services/auth-service";
import { SocialType } from "../../../lib/models";
import { useLocation } from "react-router-dom";

interface RegisterProps {
  changeModalType: (type: ModalType) => void;
  setRegisteredEmail: (email: string) => void;
}
export const Register: React.FC<RegisterProps> = ({
  changeModalType,
  setRegisteredEmail,
}) => {
  const [email, setEmail] = useState("");
  const [failureMessage, setFailureMessage] = useState<String>("");
  const [hideSocial, setHideSocial] = useState<boolean>(false);
  const location = useLocation();

  const handleRegister = async () => {
    // save email in db
    let registerResult = await AuthService.emailRegister(email);

    if (!registerResult || !registerResult.success) {
      setFailureMessage(registerResult.message);
      return;
    }

    if (registerResult.success) {
      setRegisteredEmail(email);
      changeModalType(ModalType.registeruserpass);
    }
  };

  const handleSocial = (socialType: SocialType) => {
    // open window related to the social media source
    switch (socialType) {
      case SocialType.facebook:
        AuthService.facebookLoginHandler();
        break;
      case SocialType.google:
        AuthService.googleLoginHandler();
        break;
      default:
        console.log("default");
    }
  };

  useMemo(() => {
    if (location.search.indexOf("src=social") > 0) {
      setHideSocial(true);
    }
  }, [location.search]);

  return (
    <>
      <div className="sign-up-form">
        <section className="form-header">
          <img src="/Scratchmaker_Strikethrough.png" alt="ScratchmakerIcon" />
        </section>
        {!hideSocial && (
          <div className="social-signing">
            {/* TODO: Add facebook */}
            {/* <button
        type="button"
        className="icon-button social-signing-button facebook-signing-button"
        onClick={() => handleSocial(SocialType.facebook)}
      >
        <FacebookIcon />
        <p>Continue with Facebook</p>
      </button> */}
            <button
              type="button"
              className="icon-button social-signing-button google-signing-button"
              onClick={() => handleSocial(SocialType.google)}
            >
              <GoogleIcon />
              <p>Continue with Google</p>
            </button>
            <div className="form-separator">
              <span>or</span>
            </div>
          </div>
        )}

        <div className="sign-up-email-form">
          <div className="field">
            <div className="WvIqLXU field-input-wrapper">
              <div className="wrap-email">
                <input
                  className="emailpass-input"
                  type="text"
                  data-lpignore="true"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="status-message text-body-2">
            {failureMessage.length > 0 && failureMessage}
          </div>
          <span className="submit-email submit-button" onClick={handleRegister}>
            <p>Continue</p>
          </span>

          <p className="disclaimer text-body-2">
            By joining I agree to receive emails from Scratchmaker.
          </p>
        </div>
      </div>
      <footer className="login-footer modal-content-footer">
        <div className="signing-footer text-body-2">
          <p>Already a member?</p>
          <button
            type="button"
            className="link-button text-body-2 green"
            onClick={() => changeModalType(ModalType.login)}
          >
            Sign In
          </button>
        </div>
      </footer>
    </>
  );
};
