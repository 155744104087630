import { ChangeEvent, useState } from "react";
import styles from "./CreateTournament.module.css";
import ScratchApiService from "../../../../lib/services/scratch-api-service";
import { Course } from "../../../../lib/models/course";
import {
  CreateListingRequest,
  Listing,
  ListingTypeEnum,
} from "../../../../lib/models/listing";
import useAuth from "../../../../hooks/useAuth";
import {
  charLeft,
  convertStateToAbbreviation,
} from "../../../../lib/helpers/texthelper";

interface CreateTournamentProps {
  handleCompleteClick: (listing: Listing) => void;
  onClose: () => void;
}

interface TournamentData {
  tournamentTitle: string;
  golfCourse: Course | null;
  tournamentDate: string;
  startingTime: string;
  tournamentFormat: string;
  description: string;
  greenFees: number;
  prizeValue: number;
  slotsAvailable: number;
  handicapLimit: number;
  teamSize: number;
  wager: number;
}

const CreateTournament: React.FC<CreateTournamentProps> = ({
  onClose,
  handleCompleteClick,
}) => {
  const [tournamentData, setTournamentData] = useState<TournamentData>({
    tournamentTitle: "",
    golfCourse: null,
    tournamentDate: "",
    startingTime: "",
    tournamentFormat: "",
    description: "",
    greenFees: 0,
    prizeValue: 0,
    slotsAvailable: 0,
    handicapLimit: 0,
    teamSize: 0,
    wager: 0,
  });

  const [user] = useAuth();
  const [searchResults, setSearchResults] = useState<Course[]>([]);
  const [courseSelected, setCourseSelected] = useState<boolean>();
  const [searchValue, setSearchValue] = useState("");
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [handicapLimit, setHandicapLimit] = useState(false);

  const handleInputChange = (fieldName: keyof TournamentData, value: any) => {
    setTournamentData((prevData) => {
      let newData = {
        ...prevData,
        [fieldName]: value,
      };
      if (newData.slotsAvailable && newData.wager) {
        if (!isNaN(newData.slotsAvailable) && !isNaN(newData.wager)) {
          newData.prizeValue = newData.slotsAvailable * newData.wager;
        }
      }
      return newData;
    });
  };

  const checkInputs = (data: TournamentData) => {
    const errors: Record<string, boolean> = {};

    if (data.tournamentTitle === null || data.tournamentTitle === "") {
      errors.tournamentTitle = true;
    }
    if (data.golfCourse === null) {
      errors.golfCourse = true;
    }
    if (data.tournamentDate === null || data.tournamentDate === "") {
      errors.tournamentDate = true;
    }
    if (data.startingTime === null || data.startingTime === "") {
      errors.startingTime = true;
    }
    if (data.description === null || data.description === "") {
      errors.description = true;
    }
    if (data.greenFees === null || data.greenFees === 0) {
      errors.greenFees = true;
    }
    if (data.prizeValue === null || data.prizeValue === 0) {
      errors.prizeValue = true;
    }
    if (data.slotsAvailable === null || data.slotsAvailable === 0) {
      errors.slotsAvailable = true;
    }
    if (data.teamSize === null || data.teamSize === 0 || data.teamSize > 4) {
      errors.teamSize = true;
    }
    if (data.wager === null || data.wager <= 0) {
      errors.wager = true;
    }
    setErrors(errors);
    return Object.keys(errors).length !== 0;
  };

  const handleCreate = async () => {
    if (!user) return;

    const hasErrors = checkInputs(tournamentData);
    if (hasErrors) {
      return;
    }
    if (!tournamentData.golfCourse) return;

    // call api service
    const request: CreateListingRequest = {
      listingType: ListingTypeEnum.Competitive,
      userId: user.Id,
      title: tournamentData.tournamentTitle,
      format: tournamentData.tournamentFormat,
      courseId: tournamentData.golfCourse.Id,
      description: tournamentData.description,
      picture: null,
      eventDate: tournamentData.tournamentDate,
      eventTime: tournamentData.startingTime,
      price: tournamentData.greenFees,
      prize: tournamentData.prizeValue,
      greenFeesIncluded: true,
      featuredFlag: false,
      wagerFormat: null,
      wagerAmount: tournamentData.wager,
      teamSize: tournamentData.teamSize,
      handicapLimit: handicapLimit,
      handicapLimitValue: tournamentData.handicapLimit,
      totalSlots: tournamentData.slotsAvailable,
    };

    const listing = await ScratchApiService.createListing(request);

    // next
    handleCompleteClick(listing);
  };

  const handleHandicapLimitToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setHandicapLimit(event.target.checked);
  };

  const handleCourseSearch = (searchText: string) => {
    setSearchValue(searchText);
    setCourseSelected(false);

    if (searchText !== "") {
      ScratchApiService.getCourses(searchText).then(setSearchResults);
    }
  };

  const handleCourseSearchClick = (result: Course) => {
    setCourseSelected(true);
    handleInputChange("golfCourse", result);
  };

  return (
    <>
      <div className={styles.tournamentModal}>
        <div className={styles.modalHeader}>
          <div className={styles.content}>
            <div className={styles.event}>
              <img
                className={styles.trophyIcon}
                alt=""
                src="/listing-types/tournament_icon.svg"
              />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>
                Lets start building your Competition
              </div>
              <div className={styles.supportingText}>
                Please fill in all the details below.
              </div>
            </div>
          </div>
          <div className={styles.buttonCloseX} onClick={onClose}>
            <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
          </div>
          <img className={styles.dividerIcon} alt="" src="/divider.svg" />
        </div>
        <div className={styles.content1}>
          <div className={styles.form}>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Competition Title</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input}>
                    <div className={styles.content2}>
                      <input
                        type="text"
                        className={styles.inputText}
                        placeholder="Enter Tournament Title"
                        onChange={(e) =>
                          handleInputChange("tournamentTitle", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.tournamentTitle && (
                    <div className={styles.errorText}>
                      Please enter an competition title.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Golf Course</div>
              </div>
              <div className={styles.inputWithLabel}>
                <div className={styles.courseSearch}>
                  <div className={styles.content3}>
                    <img
                      className={styles.searchLgIcon}
                      alt=""
                      src="/searchlg.svg"
                    />
                    <input
                      type="text"
                      className={styles.inputText}
                      placeholder="Search Course"
                      value={
                        courseSelected
                          ? tournamentData.golfCourse
                            ? tournamentData.golfCourse.ClubName
                            : searchValue
                          : searchValue
                      }
                      onChange={(e) => handleCourseSearch(e.target.value)}
                    />
                  </div>
                  {!courseSelected && searchResults.length > 0 && (
                    <div className={styles.searchResults}>
                      {searchResults.map((result) => (
                        <div
                          className={styles.searchResultItem}
                          key={result.Id}
                          onClick={() => handleCourseSearchClick(result)}
                        >
                          <img
                            className={styles.markerPin02Icon}
                            alt=""
                            src="/markerpin02.svg"
                          />
                          {result.ClubName} (
                          {`${result.City}, ${convertStateToAbbreviation(
                            result.State
                          )}`}
                          )
                        </div>
                      ))}
                      {/* TODO: Add the manual entry */}
                      {/* <div className={styles.manualAlert} key="manualListing">
                        Don't see the course your looking for?{" "}
                        <span> Enter details manually</span>
                      </div> */}
                    </div>
                  )}
                </div>
                {errors.golfCourse && (
                  <div className={styles.errorText}>
                    Please search for a golf course.
                  </div>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Competition Date</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/calendar.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="date"
                        className={styles.inputText}
                        placeholder="Select Date"
                        min={new Date().toISOString().split("T")[0]}
                        onChange={(e) =>
                          handleInputChange("tournamentDate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.tournamentDate && (
                    <div className={styles.errorText}>
                      Please choose a date.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Starting Time</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/alarmclock.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="time"
                        className={styles.inputText}
                        placeholder="Start Time"
                        onChange={(e) =>
                          handleInputChange("startingTime", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.startingTime && (
                    <div className={styles.errorText}>
                      Please choose a time.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Format</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input}>
                    <div className={styles.content2}>
                      <select
                        className={`${styles.inputText} ${styles.selectFormat}`}
                        onChange={(e) =>
                          handleInputChange("tournamentFormat", e.target.value)
                        }
                      >
                        <option className={styles.selectFormat} value="">
                          Select Format
                        </option>
                        <option value="Stroke Play">Stroke Play</option>
                        <option value="Match Play">Match Play</option>
                        <option value="Scramble">Scramble</option>
                        <option value="Best Ball">Best Ball</option>
                        <option value="Skins">Skins</option>
                      </select>
                    </div>
                  </div>
                  {errors.tournamentTitle && (
                    <div className={styles.errorText}>
                      Please select a format.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Team Size</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/users01.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        max={4}
                        className={styles.inputText}
                        placeholder="Team Size"
                        onChange={(e) =>
                          handleInputChange("teamSize", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.teamSize && (
                    <div className={styles.errorText}>
                      Please enter a team size between 1-4.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Description</div>
              </div>
              <div className={styles.textareaInputField}>
                <div className={styles.inputWithLabel3}>
                  <div className={styles.label}>Description*</div>
                  <div className={styles.input4}>
                    <textarea
                      className={styles.textarea}
                      placeholder="Write a few sentences about the tournament..."
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
                <div className={styles.hintText}>
                  {charLeft(tournamentData.description, 1000)} characters left
                </div>
                {errors.description && (
                  <div className={styles.errorText}>
                    Please enter a description.
                  </div>
                )}
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Handicap Limit</div>
              </div>
              <div className={styles.toggle}>
                <div className={styles.toggleBase}>
                  {" "}
                  <input
                    type="checkbox"
                    id="handicapLimitToggle"
                    onChange={handleHandicapLimitToggle} // Replace with your toggle change handler function
                  />
                  <label htmlFor="handicapLimitToggle"></label>
                </div>
                <div className={styles.textAndSupportingText}>
                  <div className={styles.text1}>Apply a handicap limit</div>
                </div>
              </div>
            </div>
            {handicapLimit && (
              <div className={styles.row}>
                <div className={styles.text1}></div>
                <div className={styles.inputField}>
                  <div className={styles.inputWithLabel}>
                    <div className={styles.input2}>
                      <div className={styles.addOn}>
                        <img
                          className={styles.searchLgIcon}
                          alt=""
                          src="/golf-stroke.svg"
                        />
                      </div>
                      <div className={styles.textInput}>
                        <input
                          type="number"
                          className={styles.inputText}
                          placeholder="Handicap Limit"
                          onChange={(e) =>
                            handleInputChange("handicapLimit", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    {errors.startingTime && (
                      <div className={styles.errorText}>
                        Please choose a time.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Green Fees</div>
                <div className={styles.subtitle}>
                  What should golfers expect to pay?
                </div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/currencydollar.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        placeholder="$50.00"
                        onChange={(e) =>
                          handleInputChange("greenFees", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.greenFees && (
                    <div className={styles.errorText}>
                      Please enter the price of green fees.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Competition Buy-In</div>
                <div className={styles.subtitle}>Buy-in amount per golfer</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/cash.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        placeholder="$10.00"
                        onChange={(e) =>
                          handleInputChange("wager", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.wager && (
                    <div className={styles.errorText}>
                      Please enter the competition buy-in price.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text11}>How Many Players?</div>
                <div className={styles.subtitle}>Including yourself</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/users01.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        placeholder="Enter Total Number of Players"
                        onChange={(e) =>
                          handleInputChange("slotsAvailable", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.slotsAvailable && (
                    <div className={styles.errorText}>
                      Please set the number of slots available.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text11}>Prize Value</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/piggybank01.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        min={0}
                        value={
                          tournamentData.wager && tournamentData.slotsAvailable
                            ? tournamentData.wager *
                              tournamentData.slotsAvailable
                            : ""
                        }
                        placeholder="Enter Prize Amount"
                        onChange={(e) =>
                          handleInputChange("prizeValue", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.prizeValue && (
                    <div className={styles.errorText}>
                      Please set a prize amount.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.modalActions}>
          <div className={`${styles.dividerWrap} ${styles.dividerActions}`}>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
          </div>
          <div className={styles.content4}>
            <div className={styles.cancelButton} onClick={onClose}>
              <div className={styles.text14}>Cancel</div>
            </div>
            <div className={`${styles.nextButton}`} onClick={handleCreate}>
              <div className={styles.text14}>Create</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTournament;
