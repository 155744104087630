import { Course } from "./course";
import { Reservation } from "./reservation";
import { User } from "./user";

export interface Listing {
  Id: string;
  ListingType: ListingType;
  ListingTypeId: number;
  User: User;
  Title: string;
  Format: string;
  Course: Course;
  Description: string;
  FeaturedFlag: boolean;
  Picture: null | string;
  EventDate: string;
  Price: number | null;
  Prize: number | null;
  WagerFormat: string | null;
  WagerAmount: number | null;
  TeamSize: number | null;
  GreenFeesIncluded: boolean | null;
  HandicapLimit: boolean | null;
  HandicapLimitValue: number | null;
  TotalSlots: number;
  SlotsTaken: number | null;
  Reservations: Reservation[] | null;
  createdAt: string;
  updatedAt: string;
  TotalPrice?: number | null;
}

export function getTotalPrice(listing: Listing): number | null {
  return (listing.Price ?? 0) + (listing.WagerAmount ?? 0);
}

export interface ListingType {
  Id: number;
  Type: string;
  createdAt: string;
  updatedAt: string;
}

export enum ListingTypeEnum {
  All = "All",
  Casual = "Casual",
  Competitive = "Competitive",
  Outing = "Outing",
  Lesson = "Lessons",
  Exclusive = "Exclusive",
  Scratchmaker = "Scratchmaker",
}

export enum ListingViewType {
  Read = "Read",
  Checkout = "Checkout",
  Write = "Write",
}

export const ListingTypeImageMap: Record<ListingTypeEnum, string> = {
  [ListingTypeEnum.All]: "/listing-types/birdie@2x.png",
  [ListingTypeEnum.Casual]: "/listing-types/casual_icon.svg",
  [ListingTypeEnum.Competitive]: "/listing-types/tournament_icon.svg",
  [ListingTypeEnum.Outing]: "/listing-types/outing_icon.svg",
  [ListingTypeEnum.Lesson]: "/listing-types/lessons_icon.svg",
  [ListingTypeEnum.Exclusive]: "/listing-types/exclusive_icon.svg",
  [ListingTypeEnum.Scratchmaker]:
    "/listing-types/Scratchmaker_Strikethrough.png",
};

export interface ListingGroup {
  [key: string]: Listing[];
}

export interface GetListingRequest {
  latitude: number;
  longitude: number;
  distance: number;
  startDate: Date | null;
  endDate: Date | null;
}

export interface CreateListingRequest {
  listingType: ListingTypeEnum;
  userId: string;
  title: string;
  format: string | null;
  courseId: number;
  description: string;
  picture: string | null;
  eventDate: string;
  eventTime: string;
  price: number | null;
  prize: number | null;
  wagerFormat: string | null;
  wagerAmount: number | null;
  teamSize: number | null;
  greenFeesIncluded: boolean | null;
  featuredFlag: boolean;
  handicapLimit: boolean | null;
  handicapLimitValue: number | null;
  totalSlots: number | null;
}

export interface UpdateListingRequest {
  listingId: string;
  title: string;
  description: string;
  totalSlots: number;
}

export const ListingTypeEnumMap: Record<number, ListingTypeEnum> = {
  1: ListingTypeEnum.Outing,
  2: ListingTypeEnum.Competitive,
  4: ListingTypeEnum.Exclusive,
  5: ListingTypeEnum.Casual,
  6: ListingTypeEnum.Lesson,
  7: ListingTypeEnum.Scratchmaker,
};

export const ListingTypePageLimitMap: {
  type: ListingTypeEnum;
  pages: number;
}[] = [
  { type: ListingTypeEnum.Outing, pages: 9 },
  { type: ListingTypeEnum.Competitive, pages: 9 },
  { type: ListingTypeEnum.Exclusive, pages: 8 },
  { type: ListingTypeEnum.Casual, pages: 9 },
  { type: ListingTypeEnum.Lesson, pages: 9 },
  { type: ListingTypeEnum.Scratchmaker, pages: 8 },
];
