import { useContext } from "react";
import { AppStateContext } from "../lib/store/app-state/context";
import * as actions from "../lib/store/app-state/actions";
import { Message } from "../lib/models/chat";

/**
 * Hook for accessing current location
 *
 * @return {*}
 */

type UseMessagesHook = [
  Message[] | null,
  (unreadMessages: Message[]) => void,
  () => void,
  (roomId: string) => void
];
const useMessages = (): UseMessagesHook => {
  const { dispatch, state } = useContext(AppStateContext);

  return [
    state.unreadMessages,
    actions.setUnreadMessages(dispatch),
    actions.clearUnreadMessages(dispatch),
    actions.removeRoomMessages(dispatch),
  ];
};

export default useMessages;
