import { FunctionComponent } from "react";
import styles from "./Footer.module.css";

type FooterType = {
  title?: string;
};

const Footer: FunctionComponent<FooterType> = ({ title }) => {
  return (
    <div className={styles.footerLinksColumn}>
      <div className={styles.heading}>{title}</div>
      <div className={styles.footerLinks}>
        {/* TODO: Add About Us */}
        {/* <div className={styles.footerLink}>
          <div className={styles.button}>
            <div className={styles.text}>About us</div>
          </div>
        </div> */}

        <div className={styles.footerLink}>
          <div className={styles.button}>
            <div className={styles.text}>
              <a href="mailto:info@scratchmakergolf.com">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
