import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Hero from "../components/CheckoutSuccess/Hero";
import SuccessModal from "../components/CheckoutSuccess/SuccessModal";
import "../styles/checkoutSuccess.css";
import ScratchApiService from "../lib/services/scratch-api-service";
import { Golfer } from "../lib/models/golfer";
import CheckoutService from "../lib/services/checkout-service";
import { Booking } from "../lib/models/booking";
import BookingService from "../lib/services/booking-service";

type CheckoutSuccessParams = {
  golferId: string;
};

const CheckoutSuccess = () => {
  const { golferId } = useParams<CheckoutSuccessParams>();
  const [query] = useSearchParams();
  const [golfer, setGolfer] = useState<Golfer>();
  const [validated, setValidated] = useState<boolean>();
  const [booking, setBooking] = useState<Booking>();
  const navigate = useNavigate();
  const handleDismiss = () => {
    navigate("/");
  };

  useEffect(() => {
    if (golferId) {
      ScratchApiService.getGolferByGolferId(golferId).then(setGolfer);
    }
  }, [golferId]);

  useEffect(() => {
    if (query) {
      const paymentIntentId = query.get("payment_intent");
      if (paymentIntentId) {
        CheckoutService.validatePayment(paymentIntentId).then(setValidated);
        BookingService.getBookingByPaymentIntentId(paymentIntentId).then(
          setBooking
        );
      }
    }
  }, [query]);

  return (
    <div className="checkoutSuccess">
      <Hero />
      {golfer && booking && (
        <SuccessModal
          isOpen={true}
          onDismiss={handleDismiss}
          booking={booking}
        />
      )}
    </div>
  );
};
export default CheckoutSuccess;
