import React, { useEffect, useState } from "react";
import { Course } from "../../lib/models/course";
import { Golfer, GolferInfo } from "../../lib/models/golfer";

interface GolferSectionProps {
  golfer: Golfer;
  golferInfo: GolferInfo;
}

const GolferSection: React.FC<GolferSectionProps> = ({
  golfer,
  golferInfo,
}) => {
  const [coursesPlayed, setCoursesPlayed] = useState<Course[]>();
  const [courseWishlist, setCourseWishlist] = useState<Course[]>();

  useEffect(() => {
    if (golferInfo) {
      // setCourseWishlist(
      //   golferInfo.Courses?.filter((g) => {
      //     return g.Wishlist;
      //   })
      // );
      // setCoursesPlayed(
      //   golferInfo.Courses?.filter((g) => {
      //     return !g.Wishlist;
      //   })
      // );
    }
  }, [golferInfo]);

  const toggleSeeMore = () => {
    const aboutCard = document.getElementById("about-card");
    const collapseButton = document.getElementById("collapse-button");
    aboutCard?.classList.toggle("show");
    if (collapseButton) {
      collapseButton.innerHTML = aboutCard?.classList.contains("show")
        ? "- See Less"
        : "+ See More";
    }
  };
  return (
    <>
      <div className="checkOutInfoSection" id="showcarddetailspopup">
        <div className="head">
          <h1>Scratchmaker Details</h1>
        </div>
        <div className="checkoutSection">
          <div className="popBasicCardInfo">
            <img
              src={`https://scratchmaker.blob.core.windows.net/profiles/${golfer.User?.ImageUrl}`}
              alt="profile-pic"
            ></img>
            <div className="popupcardbasicinfo">
              <div className="productDetailCardHeading1">
                <h3>{golfer.User?.Username}</h3>
                <div className="productHeading4">
                  <span className="hdcp-lbl">HDCP:</span>
                  <p className="hdcp">{golfer.Handicap}</p>
                </div>
                <h3 className="color-green">${golfer.StartingPrice}/session</h3>
                <div className="golferCardstarsDiv">
                  {/* <div className="productCardRating">
                    <div className="stars">
                    <i className="fa-sharp fa-solid fa-star"></i>
                    <i className="fa-sharp fa-solid fa-star"></i>
                    <i className="fa-sharp fa-solid fa-star"></i>
                    <i className="fa-sharp fa-solid fa-star"></i>
                    <i className="fa-sharp fa-solid fa-star"></i>
                  </div>
                  <p>{golfer.Rating}</p> 
                  </div> */}
                </div>
              </div>

              {/* <div className="popCardBasicTags">
                <div className="tag">Long Drive</div>
                <div className="tag">Wedge Pro</div>
                <div className="tag">Chipper</div>
              </div> */}
            </div>
          </div>
          <div className="about-wrapper">
            <div className="popupCardAbout" id="about-card">
              <h4>About</h4>
              <p>{golfer.User?.Description}</p>
              {coursesPlayed && courseWishlist && (
                <div className="popupCardTags">
                  {coursesPlayed && coursesPlayed.length > 0 && (
                    <div className="popupCardBigTags">
                      <h4>Courses Played</h4>
                      {coursesPlayed.slice(0, 3).map((g) => {
                        return (
                          <div className="BigTag" key={g.Id}>
                            {g.ClubName}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {courseWishlist && courseWishlist.length > 0 && (
                    <>
                      <div className="hrLine"></div>
                      <div className="popupCardBigTags">
                        <h4>Course Wishlist</h4>
                        {courseWishlist.slice(0, 3).map((g) => {
                          return (
                            <div className="BigTag" key={g.Id}>
                              {g.ClubName}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="cover"></div>
            <button
              className="collapse-button"
              onClick={toggleSeeMore}
              id="collapse-button"
            >
              + See More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GolferSection;
