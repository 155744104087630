import React, { useRef, useState } from "react";
import PositionService from "../../lib/services/position-service";
import {
  LocationSearchResult,
  getPositionByPlaceId,
  getPositionDescription,
  searchByPostalCode,
  Position,
} from "../../lib/services/google-maps-service";
import MyLocImage from "../../Assets/Images/my-loc.svg";

interface LocationSearchbarProps {
  onSelected: (position: Position) => void;
}

const LocationSearchbar: React.FC<LocationSearchbarProps> = ({
  onSelected,
}) => {
  const searchbarRef = useRef<HTMLInputElement>(null);

  const [results, setResults] = useState<LocationSearchResult[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSelected = (position: Position) => {
    if (searchbarRef.current) {
      searchbarRef.current.value = "";
    }

    onSelected(position);
  };

  const getCurrentPosition = async () => {
    const result = await PositionService.getCurrentLocation();

    if (result.success) {
      const { latitude, longitude } = result.position;
      const name = await getPositionDescription(latitude, longitude);
      handleSelected({ latitude, longitude, name });
    } else {
      switch (result.reason) {
        case GeolocationPositionError.PERMISSION_DENIED:
          setErrorMessage("Location permission not enabled");
          break;

        case GeolocationPositionError.TIMEOUT:
        case GeolocationPositionError.POSITION_UNAVAILABLE:
        default:
          setErrorMessage("Location is not available");
          break;
      }
    }
  };

  const selectLocation = async (placeId: string) => {
    const position = await getPositionByPlaceId(placeId);
    handleSelected(position);
  };

  return (
    <>
      <div className="search-container flex-row">
        <span className="use-current-loc" onClick={getCurrentPosition}>
          <img className="search-img" src={MyLocImage} alt="loc-outline" />
          Use Current Location
        </span>
        <div className="search-inner">
          <input
            type="text"
            placeholder="Enter a city or zip code"
            onChange={(e) =>
              searchByPostalCode(e.target.value).then(setResults)
            }
          />
        </div>
        <div className="dropdown">
          {results.slice(0, 10).map((loc) => (
            <div
              onClick={() => selectLocation(loc.placeId)}
              className="dropdown-row"
              key={loc.placeId}
            >
              <span className="loc-description">{loc.description}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LocationSearchbar;
