import React, { useState, useEffect } from "react";
import styles from "./MyProfileMenu.module.css";
import { ProfileTab } from "./MyProfile";

interface MyProfileMenuProps {
  activeTab: ProfileTab;
  setActiveTab: (tab: ProfileTab) => void;
}

const MyProfileMenu: React.FC<MyProfileMenuProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const handleTabClick = (tab: ProfileTab) => {
    setActiveTab(tab);
  };
  const handleTabChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTab = event.target.value as ProfileTab;
    setActiveTab(selectedTab);
  };

  return (
    <>
      <div className={styles.dropdown}>
        <select
          className={styles.dropdownSelect}
          value={activeTab}
          onChange={handleTabChange}
        >
          <option value={ProfileTab.Summary}>Profile Summary</option>
          <option value={ProfileTab.EditProfile}>Edit Profile</option>
          <option value={ProfileTab.Messages}>Messages</option>
          <option value={ProfileTab.MyListings}>My Listings</option>
          {/* TODO: REVIEWS AND BILLING */}
          {/* <option value={ProfileTab.MyReviews}>My Reviews</option> */}
          {/* <option value={ProfileTab.Billing}>Billing</option> */}
        </select>
      </div>
      <div className={styles.verticalTabs}>
        <div
          className={`${styles.tabButtonBase} ${
            activeTab === ProfileTab.Summary ? styles.selected : ""
          }`}
          onClick={() => handleTabClick(ProfileTab.Summary)}
        >
          <div className={styles.text}>Profile Summary</div>
        </div>
        <div
          className={`${styles.tabButtonBase} ${
            activeTab === ProfileTab.EditProfile ? styles.selected : ""
          }`}
          onClick={() => handleTabClick(ProfileTab.EditProfile)}
        >
          <div className={styles.text}>Edit Profile</div>
        </div>
        <div
          className={`${styles.tabButtonBase} ${
            activeTab === ProfileTab.Messages ? styles.selected : ""
          }`}
          onClick={() => handleTabClick(ProfileTab.Messages)}
        >
          <div className={styles.text}>Messages</div>
        </div>
        <div
          className={`${styles.tabButtonBase} ${
            activeTab === ProfileTab.MyListings ? styles.selected : ""
          }`}
          onClick={() => handleTabClick(ProfileTab.MyListings)}
        >
          <div className={styles.text}>My Listings</div>
        </div>

        {/* TODO: REVIEWS AND BILLING */}
        {/* 
        <div
          className={`${styles.tabButtonBase} ${
            activeTab === ProfileTab.MyReviews ? styles.selected : ""
          }`}
          onClick={() => handleTabClick(ProfileTab.MyReviews)}
        >
          <div className={styles.text}>My Reviews</div>
        </div>
        <div
          className={`${styles.tabButtonBase} ${
            activeTab === ProfileTab.Billing ? styles.selected : ""
          }`}
          onClick={() => handleTabClick(ProfileTab.Billing)}
        >
          <div className={styles.text}>Billing</div>
        </div> */}
      </div>
    </>
  );
};

export default MyProfileMenu;
