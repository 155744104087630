import { useState } from "react";

export default function useLoginModal() {
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const toggleLogin = () => {
    setIsLoginOpen(!isLoginOpen);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = body.style.overflow === "" ? "hidden" : "";
    }
  };

  return {
    isLoginOpen,
    toggleLogin,
  };
}
