import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Login } from "../components/common/auth/Login";
import { ModalType } from "../components/common/auth/LoginModal";
import { Register } from "../components/common/auth/Register";
import { RegisterFromSocial } from "../components/common/auth/RegisterFromSocial";
import { RegisterUserPass } from "../components/common/auth/RegisterUserPass";
import ResetPassword from "../components/common/auth/ResetPassword";
import { ResetviaEmail } from "../components/common/auth/ResetviaEmail";
import AuthService from "../lib/services/auth-service";
import "../styles/password-reset.css";

const PasswordReset = () => {
  const [token, setToken] = useState<String>("");
  const [userId, setUserId] = useState<String>("");
  const [loading, setLoading] = useState<Boolean>(true);
  const [tokenActive, setTokenActive] = useState<boolean>();
  const [modalType, setModalType] = useState<ModalType>(
    ModalType.forgotpassword
  );
  const [email, setEmail] = useState<String>("");
  const navigate = useNavigate();
  // use effect to check query params for password reset
  useEffect(() => {
    const getQueryParams = () => {
      const queryParams = new URLSearchParams(window.location.search);
      if (Array.from(queryParams).length < 1) {
        navigate("/");
      }
      const token = queryParams.get("token");
      const userId = queryParams.get("id");
      return [token, userId];
    };

    const [token, userId] = getQueryParams();
    if (token && userId) {
      setToken(token);
      setUserId(userId);
      // check if token is expired
      // if so, show reset component. If not, show password reset form
      AuthService.validateToken(userId, token).then((res) => {
        setTokenActive(res);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [navigate]);

  const handleToggle = () => {
    console.log("do nothing for now");
  };

  const AuthMarkup = () => {
    switch (modalType) {
      case ModalType.login:
        return <Login toggle={handleToggle} changeModalType={setModalType} />;
      case ModalType.registeruserpass:
        return (
          <RegisterUserPass
            toggle={handleToggle}
            changeModalType={setModalType}
            email={email}
          />
        );
      case ModalType.registersocial:
        return (
          <RegisterFromSocial
            toggle={handleToggle}
            changeModalType={setModalType}
          />
        );
      case ModalType.forgotpassword:
        if (tokenActive) {
          return <ResetPassword token={token} userId={userId} />;
        }
        return (
          <ResetviaEmail toggle={handleToggle} changeModalType={setModalType} />
        );
      default:
        return (
          <Register
            setRegisteredEmail={setEmail}
            changeModalType={setModalType}
          />
        );
    }
  };

  return (
    <div className="password-reset page">
      <div className="wrap-reset">
        <div className="wrap-form">{!loading && AuthMarkup()}</div>
      </div>
    </div>
  );
};

export default PasswordReset;
