import { FunctionComponent } from "react";
import styles from "./Features.module.css";
import { NavLink } from "react-router-dom";
const Features: FunctionComponent = () => {
  return (
    <div className={styles.featuresSection}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.headingAndSupportingText}>
            <div className={styles.headingAndBadge}>
              <div className={styles.badge}>
                <div className={styles.text}>Features</div>
              </div>
              <div className={styles.heading}>
                Your Ultimate Destination for Local Golf
              </div>
            </div>
            <div className={styles.supportingText}>
              List or find outings and competitions to join in any city - only a
              few taps away📱
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container1}>
        <div className={styles.content1}>
          <img className={styles.contentIcon} alt="" src="/screens.svg" />
        </div>
        <div className={styles.content2}>
          <div className={styles.featureText}>
            <div className={styles.wrapHighlightImage}>
              <img
                className={styles.featuredIcon}
                alt=""
                src="/listing-types/casual_icon.svg"
              />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text1}>Tee Time Finder</div>
              <div className={styles.supportingText1}>
                Looking to fill your foursome, or just meet local golfers? Use
                our casual tee time finder.
              </div>
            </div>
            <NavLink to="/listings/Casual" className={styles.button}>
              <div className={styles.text2}>Find Tee Times</div>
              <img
                className={styles.arrowRightIcon}
                alt=""
                src="/arrowright.svg"
              />
            </NavLink>
          </div>
          <div className={styles.featureText}>
            <div className={styles.wrapHighlightImage}>
              <img
                className={styles.featuredIcon}
                alt=""
                src="/listing-types/tournament_icon.svg"
              />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text1}>Compete Locally</div>
              <div className={styles.supportingText1}>
                Quickly and easily find local golfers to compete with -
                including match play, skins, 2-man scrambles, and more!
              </div>
            </div>
            <NavLink to="/listings/Competitive" className={styles.button}>
              <div className={styles.text2}>Browse Competitive Events</div>
              <img
                className={styles.arrowRightIcon}
                alt=""
                src="/arrowright.svg"
              />
            </NavLink>
          </div>
          <div className={styles.featureText}>
            <div className={styles.wrapHighlightImage}>
              <img
                className={styles.featuredIcon}
                alt=""
                src="/listing-types/Scratchmaker_Strikethrough.png"
              />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text1}>Become a Scratchmaker</div>
              <div className={styles.supportingText1}>
                Need a few lessons or are looking to make money off of your golf
                skills? Becoming a qualified Scratchmaker.
              </div>
            </div>
            <div className={styles.button}>
              <div className={styles.text2}>COMING SOON</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
