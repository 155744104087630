import styles from "./Search.module.css";
import DatePickerMenu from "./DatePickerMenu";
import { Range } from "react-date-range";
import { useEffect, useRef, useState } from "react";
import PositionService from "../../lib/services/position-service";
import usePosition from "../../hooks/usePosition";
import {
  LocationSearchResult,
  getPositionByPlaceId,
  searchByPostalCode,
  Position,
  searchByCity,
} from "../../lib/services/google-maps-service";
import { SearchParams } from "../../pages/ListingPageViewAll";

interface SearchProps {
  setSearch: (params: SearchParams) => void;
}

const Search: React.FC<SearchProps> = ({ setSearch }) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [radius, setRadius] = useState<number>(25);
  const [dateRange, setDateRange] = useState<Range>();
  const [position, setPosition] = usePosition();
  const [searchResults, setSearchResults] = useState<LocationSearchResult[]>(
    []
  );
  const [searchValue, setSearchValue] = useState("");
  const [pending, setPending] = useState<boolean>(false);
  const [showDistanceDropdown, setShowDistanceDropdown] =
    useState<boolean>(false);
  const locRef = useRef<HTMLDivElement>(null);

  const handleSelected = (selPosition: Position) => {
    // erase search bar text and callback to golfers component
    setPosition(selPosition);
  };

  const selectLocation = async (placeId: string, description: string) => {
    const position = await getPositionByPlaceId(placeId);
    handleSelected(position);
    setSearchValue(description);
    setSearchResults([]);
    setPending(true);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    // Perform both postal code and city search
    Promise.all([searchByPostalCode(value), searchByCity(value)]).then(
      ([postalCodeResults, cityResults]) => {
        setSearchResults([...postalCodeResults, ...cityResults]);
      }
    );
  };

  const handleRadiusClick = (radius: number) => {
    setRadius(radius);
    setShowDistanceDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (locRef.current && !locRef.current.contains(event.target as Node)) {
        setSearchResults([]);
      }
    };
    const handleScroll = () => {
      setSearchResults([]);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDatePickerClick = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleSelectDateRange = (selectedDateRange: Range) => {
    if (selectedDateRange !== dateRange) {
      setDateRange(selectedDateRange);
    }
    setPending(true);
  };

  const handleSearchClick = () => {
    if (radius && position) {
      const params: SearchParams = {
        Radius: radius,
        Location: position,
        DateRange: dateRange,
      };
      setSearch(params);
      setPending(false);
    }
  };

  const handleClearClick = () => {
    setDateRange(undefined);
    setRadius(25);
    if (radius && position) {
      const params: SearchParams = {
        Radius: 25,
        Location: position,
        DateRange: null,
      };
      setSearch(params);
      setPending(false);
    }
  };

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.filtersBar}>
          <div className={styles.content}>
            <div className={styles.dropdownsContainer}>
              <div className={styles.dropdowns}>
                <div
                  className={styles.datePickerDropdown}
                  onClick={handleDatePickerClick}
                >
                  <div className={styles.button}>
                    <img
                      className={styles.calendarIcon}
                      alt=""
                      src="/calendar.svg"
                    />
                    <div className={styles.text}>
                      {dateRange && dateRange.startDate && dateRange.endDate ? (
                        <>
                          {`${dateRange?.startDate?.toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )} - ${dateRange?.endDate?.toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}`}
                        </>
                      ) : (
                        <>All Dates</>
                      )}
                    </div>
                  </div>
                </div>
                {isDatePickerOpen && (
                  <DatePickerMenu
                    onDateRangeSelected={handleSelectDateRange}
                    closeDatePicker={() => setIsDatePickerOpen(false)}
                  />
                )}
                {/* TODO: Add filters */}
                {/* <div className={styles.inputDropdown}>
                  <div className={styles.inputWithLabel}>
                    <div className={styles.input}>
                      <div className={styles.content1}>
                        <img
                          className={styles.calendarIcon}
                          alt=""
                          src="/filterlines.svg"
                        />
                        <div className={styles.text1}>Filter</div>
                      </div>
                      <img
                        className={styles.calendarIcon}
                        alt=""
                        src="/chevrondown.svg"
                      />
                    </div>
                  </div>
                </div> */}
              </div>
              {/* Tags */}
              {/* <div className={styles.dropdowns1}>
                <div className={styles.filterContainer}>
                  <div className={styles.filterContainer}>
                    <div className={styles.input1}>
                      <div className={styles.content2}>
                        <div className={styles.tag}>
                          <div className={styles.content2}>
                            <div className={styles.text2}>Label</div>
                          </div>
                          <div className={styles.tagXClose}>
                            <img
                              className={styles.xCloseIcon}
                              alt=""
                              src="/xclose.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.tag}>
                        <div className={styles.content2}>
                          <div className={styles.text2}>Label</div>
                        </div>
                        <div className={styles.tagXClose}>
                          <img
                            className={styles.xCloseIcon}
                            alt=""
                            src="/xclose.svg"
                          />
                        </div>
                      </div>
                      <div className={styles.tag}>
                        <div className={styles.content2}>
                          <div className={styles.text2}>Label</div>
                        </div>
                        <div className={styles.tagXClose}>
                          <img
                            className={styles.xCloseIcon}
                            alt=""
                            src="/xclose.svg"
                          />
                        </div>
                      </div>
                      <div className={styles.tag}>
                        <div className={styles.content2}>
                          <div className={styles.text2}>Label</div>
                        </div>
                        <div className={styles.tagXClose}>
                          <img
                            className={styles.xCloseIcon}
                            alt=""
                            src="/xclose.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className={styles.wrapSearchButtons}>
              <div
                className={`${styles.button2} ${styles.mobileSearch}`}
                onClick={handleClearClick}
              >
                <div className={styles.text}>Clear</div>
              </div>
              <div
                className={`${styles.button3} ${styles.mobileSearch}`}
                onClick={handleSearchClick}
              >
                {pending ? (
                  <div className={styles.text}>Update Search</div>
                ) : (
                  <div className={styles.text}>Search</div>
                )}
              </div>
            </div>

            {/*  TODO: Map */}
            {/* <div className={styles.mapModalButton}>
              <div className={styles.button1}>
                <img className={styles.calendarIcon} alt="" src="/map01.svg" />
                <div className={styles.text6}>View Map</div>
                <img
                  className={styles.placeholderIcon}
                  alt=""
                  src="/placeholder2.svg"
                />
              </div>
            </div> */}
          </div>
          <div className={styles.searchBarSection}>
            <div className={styles.inputField}>
              <div className={styles.inputWithLabel}>
                <div className={styles.input2}>
                  <div className={styles.textInput}>
                    <div className={styles.content1}>
                      <img
                        className={styles.calendarIcon}
                        alt=""
                        src="/searchlg.svg"
                      />
                      <span className={styles.setLocation}>
                        <div className={styles.searchInner}>
                          <input
                            type="text"
                            placeholder="Enter a city or zip code"
                            value={
                              searchValue != null
                                ? searchValue
                                : position
                                ? position.name
                                : ""
                            }
                            onChange={handleSearchChange}
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className={styles.addOn}>
                    <b className={styles.radiusLabel}>Radius:</b>
                    <div
                      className={styles.radiusDropdown}
                      onClick={() =>
                        setShowDistanceDropdown(!showDistanceDropdown)
                      }
                    >
                      <div className={styles.radiusDropdownValue}>
                        {radius} mi
                      </div>

                      <img
                        className={styles.calendarIcon}
                        alt=""
                        src="/chevrondown.svg"
                      />
                    </div>
                    {showDistanceDropdown && (
                      <ul className={styles.radiusDropdownList}>
                        <li onClick={() => handleRadiusClick(25)}>25 mi</li>
                        <li onClick={() => handleRadiusClick(50)}>50 mi</li>
                        <li onClick={() => handleRadiusClick(75)}>75 mi</li>
                        <li onClick={() => handleRadiusClick(100)}>100 mi</li>
                        <li onClick={() => handleRadiusClick(150)}>150 mi</li>
                        <li onClick={() => handleRadiusClick(200)}>200 mi</li>
                      </ul>
                    )}
                  </div>
                  {searchResults.length > 0 && (
                    <div className={styles.dropdown} ref={locRef}>
                      {searchResults.slice(0, 10).map((loc) => (
                        <div
                          onClick={() =>
                            selectLocation(loc.placeId, loc.description)
                          }
                          className={styles.dropdownRow}
                          key={loc.placeId}
                        >
                          <span className="loc-description">
                            {loc.description}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`${styles.button2} ${styles.desktopSearch}`}
              onClick={handleClearClick}
            >
              <div className={styles.text}>Clear</div>
            </div>
            <div
              className={`${styles.button3} ${styles.desktopSearch}`}
              onClick={handleSearchClick}
            >
              {pending ? (
                <div className={styles.text}>Update Search</div>
              ) : (
                <div className={styles.text}>Search</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
