import { useState } from "react";
import SignUpForm from "../components/SignUpGolfer/SignUpForm";
import SignUpSteps from "../components/SignUpGolfer/SignUpSteps";
import "../styles/signUpStyle.css";

const SignUpGolfer = () => {
  const [step, setStep] = useState<number>(0);
  return (
    <div className="signup page">
      <SignUpSteps currentStep={step} clickCB={setStep} />
      <SignUpForm currentStep={step} clickCB={setStep} />
    </div>
  );
};
export default SignUpGolfer;
