import { UserInformation } from "../../../lib/models/user";

import styles from "./MyProfile.module.css";
import MyProfileMenu from "./MyProfileMenu";
import EditProfile from "./Edit/EditProfile";
import ProfileHeader from "./ProfileHeader";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import Chat from "../../v2_Chat/Chat";
import EventsManager from "./MyListings/EventsManager";
import useLoginModal from "../../../hooks/useLoginModal";
import useCreateListingModal from "../../../hooks/useCreateListingModal";
import LoginModal from "../../common/auth/LoginModal";
import CreateListingModal from "../../common/listings/create-listing-modal/CreateListingModal";
import Summary from "./Summary/Summary";

interface MyProfileProps {
  userInfo: UserInformation;
}
export enum ProfileTab {
  Summary = "Summary",
  Messages = "Messages",
  Purchases = "Purchases",
  MyListings = "My Listings",
  EditProfile = "Edit Profile",
  Notifications = "Notifications",
  MyReviews = "My Reviews",
  Billing = "Billing",
  Payouts = "Payouts",
  Settings = "Settings",
  LogOut = "Log Out",
}
const MyProfile: React.FC<MyProfileProps> = ({ userInfo }) => {
  const [user, setUser] = useAuth();
  const [activeTab, setActiveTab] = useState<ProfileTab>(ProfileTab.Summary);
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const { isCreateListingOpen, toggleCreateListing } = useCreateListingModal();
  const handleHostClick = () => {
    if (user) {
      toggleCreateListing();
    } else {
      toggleLogin();
    }
  };
  return (
    <div className={styles.main}>
      {/* TODO: Do we need to say welcome back? */}
      {/* <ProfileHeader userInfo={userInfo} /> */}
      <div className={styles.section}>
        <div className={styles.container}>
          <MyProfileMenu activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === ProfileTab.Summary && <Summary userInfo={userInfo} />}
          {activeTab === ProfileTab.EditProfile && (
            <EditProfile userInfo={userInfo} editCB={setUser} />
          )}
          {activeTab === ProfileTab.Messages && <Chat />}
          {activeTab === ProfileTab.MyListings && (
            <EventsManager handleHostClick={handleHostClick} />
          )}
        </div>
      </div>
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
      <CreateListingModal
        isOpen={isCreateListingOpen}
        toggle={toggleCreateListing}
      />
    </div>
  );
};

export default MyProfile;
