import { UserInformation } from "../../../lib/models/user";

import styles from "../../../styles/profile.module.css";
import ProfileSummary from "./ProfileSummary";

interface ViewProfileProps {
  userInfo: UserInformation;
}

const ViewProfile: React.FC<ViewProfileProps> = ({ userInfo }) => {
  return (
    <div className={styles.profile}>
      <div className={styles.main}>
        <ProfileSummary userInfo={userInfo} />
      </div>
    </div>
  );
};

export default ViewProfile;
