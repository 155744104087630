import { useEffect, useState } from "react";
import CheckoutFooter from "../components/v2_Checkout/CheckoutFooter";
import styles from "../styles/Checkoutv2.module.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Listing } from "../lib/models/listing";
import ScratchApiService from "../lib/services/scratch-api-service";
import OrderDetails from "../components/v2_Checkout/OrderDetails";
import BillingDetails from "../components/v2_Checkout/BillingDetails";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Success from "../components/v2_Checkout/Success";

type CheckoutParams = {
  listingId: string;
};

const Checkoutv2: React.FC = () => {
  const { listingId } = useParams<CheckoutParams>();
  const navigate = useNavigate();
  const [listing, setListing] = useState<Listing>();
  const [step, setStep] = useState(1); // initialize the current step to 1
  const [reservationId, setReservationId] = useState<number>(); // initialize the current step to 1

  useEffect(() => {
    if (listingId) {
      ScratchApiService.getListingById(listingId).then(setListing);
    }
  }, [listingId]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const goBack = () => {
    if (step === 1) {
      navigate(-1);
    } else {
      setStep(step - 1);
    }
  };

  // Load the Stripe API key
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_API_KEY as string
  );

  return (
    <Elements stripe={stripePromise}>
      <div className={styles.checkoutv2}>
        <div className={styles.section}>
          <div className={styles.headerNavigation}>
            <NavLink to="/">
              <img className={styles.logoIcon} alt="" src="/logo-blue.svg" />
            </NavLink>
          </div>

          <div className={styles.container}>
            {listing && step === 1 && (
              <OrderDetails
                listing={listing}
                handleReservation={setReservationId}
                handleNextClick={nextStep}
                handleBackClick={goBack}
              />
            )}
            {/* TODO: Re-add billing */}
            {/* {listing && step === 2 && (
              <BillingDetails
                listing={listing}
                handleReservation={setReservationId}
                handleNextClick={nextStep}
                handleBackClick={goBack}
              />
            )} */}
            {listing && step === 2 && reservationId && (
              <Success reservationId={reservationId} />
            )}
          </div>

          <CheckoutFooter />
        </div>
        <div className={styles.section2}>
          <img
            className={styles.maleGolfPlayerOnProfessionIcon}
            alt=""
            src="/settingtee.png"
          />
        </div>
      </div>
    </Elements>
  );
};

export default Checkoutv2;
