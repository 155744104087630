/**
 * Typed wrapper for JSON.stringify
 *
 * @export
 * @template T
 * @param {T} value
 * @return {*}  {string}
 */
export function stringify<T>(value: T): string {
  return JSON.stringify(value);
}

/**
 * Typed wrapper for JSON.parse
 *
 * @export
 * @template T
 * @param {string} value
 * @return {*}  {T}
 */
export function parse<T>(value: string): T {
  return JSON.parse(value);
}
