import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

interface LocationSearchProps {
  handleSelectLocation: (
    loc: google.maps.places.AutocompletePrediction
  ) => void;
  curValue: google.maps.places.AutocompletePrediction | undefined;
}
const LocationSearch: React.FC<LocationSearchProps> = ({
  handleSelectLocation,
  curValue,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    (loc: google.maps.places.AutocompletePrediction) => () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(loc.description, false);
      clearSuggestions();

      handleSelectLocation(loc);
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref} className="google-search-div">
      <input
        value={value ? value : curValue?.description ?? ""}
        onChange={handleInput}
        disabled={!ready}
        placeholder={"Enter Address"}
      />

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};

export default LocationSearch;
