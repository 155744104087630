import { Position } from "../models";

type PositionResult =
  | { success: true; position: Position }
  | {
      success: false;
      reason: GeolocationPositionError["code"];
    };

/**
 * Wrapper for Position methods
 *
 * @export
 * @class PositionService
 */
export default class PositionService {
  static async getCurrentLocation(): Promise<PositionResult> {
    return new Promise((resolve, reject) => {
      let geolocation = navigator.geolocation;
      geolocation.getCurrentPosition(
        (currentPosition) => {
          resolve({
            success: true,
            position: {
              latitude: currentPosition.coords.latitude,
              longitude: currentPosition.coords.longitude,
            },
          });
        },
        (err) => {
          reject();
        }
      );
    });
  }
}
