import { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./Hero.module.css";
import {
  LocationSearchResult,
  Position,
  getPositionByPlaceId,
  searchByPostalCode,
  searchByCity,
} from "../../lib/services/google-maps-service";
import usePosition from "../../hooks/usePosition";
import { useNavigate } from "react-router-dom";

const Hero: FunctionComponent = () => {
  const [position, setPosition, clearPosition] = usePosition();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>();
  const [searchResults, setSearchResults] = useState<LocationSearchResult[]>(
    []
  );
  const locRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    // Perform both postal code and city search
    Promise.all([searchByPostalCode(value), searchByCity(value)]).then(
      ([postalCodeResults, cityResults]) => {
        setSearchResults([...postalCodeResults, ...cityResults]);
      }
    );
  };

  const handleSelected = (selPosition: Position) => {
    // erase search bar text and callback to golfers component
    setPosition(selPosition);
  };

  const selectLocation = async (placeId: string, description: string) => {
    const position = await getPositionByPlaceId(placeId);
    handleSelected(position);
    setSearchValue(description);
    setSearchResults([]);
  };

  const handleSearch = () => {
    if (position) {
      navigate("/listings");
    }
  };

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.headingAndSupportingText}>
            <div className={styles.headingAndSubheading}>
              <div className={styles.heading}>Play More Golf.</div>
            </div>
            <div className={styles.supportingText}>
              Join our golf community and play with local golfers, organize
              competitions, and host outings.
            </div>
          </div>
          <div className={styles.content1}>
            <div className={styles.inputDropdown}>
              <div className={styles.inputWithLabel}>
                <div className={styles.input}>
                  <div className={styles.searchInner}>
                    <div className={styles.searchInner}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/searchlg.svg"
                      />
                      <input
                        type="text"
                        placeholder="Enter a city or zip code"
                        value={
                          searchValue != null
                            ? searchValue
                            : position
                            ? position.name
                            : ""
                        }
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.hintText}>
                This is a hint text to help user.
              </div>
            </div>
            {searchResults.length > 0 && (
              <div className={styles.dropdown} ref={locRef}>
                {searchResults.slice(0, 10).map((loc) => (
                  <div
                    onClick={() => selectLocation(loc.placeId, loc.description)}
                    className={styles.dropdownRow}
                    key={loc.placeId}
                  >
                    <span className="loc-description">{loc.description}</span>
                  </div>
                ))}
              </div>
            )}
            <div className={styles.button} onClick={handleSearch}>
              <div className={styles.search}>Search</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
