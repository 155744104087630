import { saveApplicationState } from "../../utils/storage";
import appStateReducer, { Reducer } from "./reducer";

/**
 * A wrapper for the app state reducer that persists all updates to local storage.
 *
 * @param {*} state
 * @param {*} action
 * @return {*}
 */
const persistedAppStateReducer: Reducer = (state, action) => {
  const nextState = appStateReducer(state, action);

  // The initialize action is fired when app state is first loaded from storage.
  // No need to persist it again here, it would be identical.
  if (action.type !== "initialize") {
    saveApplicationState(nextState);
  }

  return nextState;
};

export default persistedAppStateReducer;
