import { useNavigate } from "react-router-dom";
import { User } from "../../lib/models";
import styles from "./Members.module.css";
import { useEffect, useState } from "react";

interface MembersProps {
  members: User[];
  onClose: () => void;
}

const Members: React.FC<MembersProps> = ({ members, onClose }) => {
  const navigate = useNavigate();
  const [memberSearch, setMemberSearch] = useState<string>("");
  const [filteredMembers, setFilteredMembers] = useState<User[]>([]);

  const handleUserClick = (username: string) => {
    navigate(`/profile/${username}`);
  };

  useEffect(() => {
    // filter on text
    const filtered = members.filter((member) =>
      member.Username.toLowerCase().includes(memberSearch.toLowerCase())
    );

    // sort
    filtered.sort((a, b) => a.Username.localeCompare(b.Username)); // Sort by username

    setFilteredMembers(filtered);
  }, [memberSearch, members]);
  return (
    <>
      <div className={styles.membersModal}>
        <div className={styles.modalHeader}>
          <div className={styles.content}>
            <div className={styles.wrapSearch}>
              <img src="/searchlg.svg" alt="search for golfers" />
              <input
                type="text"
                placeholder="Search Golfers"
                onChange={(e) => setMemberSearch(e.target.value)}
              ></input>
            </div>
          </div>
          <div className={styles.buttonCloseX} onClick={onClose}>
            <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
          </div>
          <img className={styles.dividerIcon} alt="" src="/divider.svg" />
        </div>
        <div className={styles.content1}>
          <div className={styles.wrapMembers}>
            {filteredMembers &&
              filteredMembers.map((member) => {
                return (
                  <div
                    className={styles.member}
                    key={member.Username}
                    onClick={() => handleUserClick(member.Username)}
                  >
                    <img
                      alt="pfp"
                      src={member.ImageUrl}
                      className={styles.memberPic}
                    />
                    <div className={styles.wrapName}>
                      <span className={styles.userName}>{member.Username}</span>
                      <span className={styles.supportingText}>
                        Handicap: {member.Handicap}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Members;
