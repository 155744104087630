import Hero from "../components/v2_Home/Hero";
import Highlights from "../components/v2_Home/Highlights";
import Showcase1 from "../components/v2_Home/Showcase1";
import Showcase2 from "../components/v2_Home/Showcase2";
import Showcase3 from "../components/v2_Home/Showcase3";
import BecomeAHostCallback from "../components/v2_Home/BecomeAHostCallback";
import styles from "../styles/Homev2.module.css";
import useProcessUserModal from "../hooks/useProcessUserModal";
import ProcessUserModal from "../components/common/auth/process-user/ProcessUserModal";
import { useEffect } from "react";
import useAuth from "../hooks/useAuth";

const Homev2 = () => {
  const { isProcessUserOpen, toggleProcessUser } = useProcessUserModal();
  const [user] = useAuth();
  useEffect(() => {
    if (user) {
      if (!user.Location) {
        toggleProcessUser();
      }
    }
  }, [user]);
  return (
    <div className={styles.indexNoCookie}>
      <div className={styles.heroHeaderSection}>
        <Hero />
      </div>
      <Highlights />
      {/* TODO: SHOW WHEN WE HAVE LESSONS */}
      {/* <Showcase1 /> */}
      <Showcase2 />
      <Showcase3 />
      <BecomeAHostCallback />
      <ProcessUserModal isOpen={isProcessUserOpen} toggle={toggleProcessUser} />
    </div>
  );
};

export default Homev2;
