import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import LicenseUpload from "./LicenseUpload";

interface IdentificationProps {
  stateCB: (state: IdentityInfoState) => void;
  sessionState: IdentityInfoState | null | undefined;
}
export interface IdentityInfoState {
  imageUrl: string;
}

const Identification: React.FC<IdentificationProps> = ({
  stateCB,
  sessionState,
}) => {
  const [formData, setFormData] = useState<IdentityInfoState>({
    imageUrl: "",
  });
  const validateForm = () => {
    if (formData.imageUrl.length < 1) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "You must provide a picture of your license.",
      });
      return false;
    }
    return true;
  };

  const handleImageUpload = (imageUrl: string) => {
    setFormData({ ...formData, imageUrl: imageUrl });
    stateCB({ ...formData, imageUrl: imageUrl });
  };

  useEffect(() => {
    if (sessionState) {
      setFormData(sessionState);
    }
  }, [sessionState]);

  return (
    <div className="signup-form-wrapper">
      <div className="signup-title">
        <h2>Identification</h2>
        <p>
          At Scratchmaker, we take the safety and security of our community very
          seriously. That's why we require all of our SCratchmakers to upload a
          copy of their driver's license, in order to protect both themselves
          and their customers.
        </p>
      </div>
      <div className="signup-form-fields">
        <div className="field-row">
          <p>License Upload:</p>
          <div>
            <LicenseUpload stateCB={handleImageUpload} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Identification;
