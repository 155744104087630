import { useState, useMemo, useEffect } from "react";

interface PaginationOptions {
  array: any[];
  pageSize?: number;
}

interface PaginationResult {
  page: number;
  setPage: (page: number) => void;
  totalPages: number;
  filteredArray: any[];
  handleBackPage: () => void;
  handleForwardPage: () => void;
}

const usePagination = ({
  array,
  pageSize = 2,
}: PaginationOptions): PaginationResult => {
  const [page, setPage] = useState<number>(1);
  const [filteredArray, setFilteredArray] = useState<any[]>([]);

  const totalPages = useMemo(() => {
    if (array) {
      return Math.ceil(array.length / pageSize);
    }
    return 0;
  }, [array, pageSize]);

  const handleBackPage = () => {
    setPage(page - 1);
  };

  const handleForwardPage = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (array) {
      const pageStart = (page - 1) * pageSize;
      const pageEnd = page * pageSize;
      const filtered = array.slice(pageStart, pageEnd);
      setFilteredArray(filtered);
    }
  }, [array, page, pageSize]);

  return {
    page,
    setPage,
    totalPages,
    filteredArray,
    handleBackPage,
    handleForwardPage,
  };
};

export default usePagination;
