import styles from "./ActiveTarget.module.css";
import { Room } from "../../lib/models/chat";
import { User } from "../../lib/models";
import { useMemo, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import ChatMembersModal from "./ChatMembersModal";
interface ActiveTargetProps {
  room: Room;
}
const ActiveTarget: React.FC<ActiveTargetProps> = ({ room }) => {
  const [recipient, setRecipient] = useState<User>();
  const [groupRecipients, setGroupRecipients] = useState<User[]>();
  const [showMembersModal, setShowMembersModal] = useState<boolean>(false);

  const [user] = useAuth();

  useMemo(() => {
    if (user) {
      const roomParticipants = room.MessageRoomParticipants;
      if (!room.IsGroup) {
        // recipient is roomParticipant where roomParticipant.User.Id !== user.Id
        const recipientParticipant = roomParticipants.find(
          (participant) => participant.User.Id !== user.Id
        );

        if (recipientParticipant) {
          setRecipient(recipientParticipant.User);
        }
      } else {
        setGroupRecipients(
          roomParticipants.map((rp) => {
            return rp.User;
          })
        );
      }
    }
  }, [room, user]);

  const navigate = useNavigate();

  const handleClickUser = () => {
    console.log("in");
    if (recipient) {
      navigate(`/profile/${recipient.Username}`);
    } else {
      console.log("failed");
    }
  };

  const memberToggle = () => {
    setShowMembersModal(!showMembersModal);
  };
  return (
    <div className={styles.cardHeader}>
      <div className={styles.content}>
        {room.IsGroup ? (
          <div className={styles.groupTarget}>
            <div className={styles.wrapGroupTitle}>
              <div className={styles.groupTitle}>{room.Title} Golfers</div>
              <div className={styles.supportingText} onClick={memberToggle}>
                {groupRecipients?.length} members
              </div>
            </div>

            {groupRecipients && groupRecipients.length > 0 && (
              <div className={styles.wrapGroupAvatars}>
                {groupRecipients.slice(0, 8).map((gr) => {
                  return (
                    <div className={styles.groupAvatar} key={gr.Username}>
                      <img src={gr.ImageUrl} alt="recip" />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.avatarAndText} onClick={handleClickUser}>
            <div className={styles.avatar}>
              <img src={recipient?.ImageUrl} alt="recip" />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.textAndBadge}>
                <div className={styles.text}>{recipient?.Name}</div>
              </div>
              <div className={styles.supportingText}>
                @{recipient?.Username}
              </div>
            </div>
          </div>
        )}
      </div>
      <img className={styles.dividerIcon} alt="" src="/divider.svg" />
      {groupRecipients && (
        <ChatMembersModal
          isOpen={showMembersModal}
          toggle={memberToggle}
          members={groupRecipients}
        />
      )}
    </div>
  );
};

export default ActiveTarget;
