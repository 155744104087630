import EventsManagerHeader from "./EventsManagerHeader";
import ManagerSection from "./ManagerSection";
import styles from "./EventsManager.module.css";

interface EventsManagerProps {
  handleHostClick: () => void;
}

const EventsManager: React.FC<EventsManagerProps> = ({ handleHostClick }) => {
  return (
    <div className={styles.content}>
      <EventsManagerHeader handleHostClick={handleHostClick} />
      <div className={styles.divider}></div>
      <div className={styles.section}>
        <ManagerSection />
      </div>
    </div>
  );
};

export default EventsManager;
