import * as JSON from "../utils/json";
import {
  StripePaymentIntentResponse,
  StripeRequest,
  StripeRequestv2,
} from "../models/stripeRequest";
import { Booking } from "../models/booking";

export default class CheckoutService {
  static async createPaymentIntent(
    stripeRequest: StripeRequest
  ): Promise<StripePaymentIntentResponse> {
    const res = await fetch(`/api/stripe/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ stripeRequest: stripeRequest }),
    });
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const responseBody: StripePaymentIntentResponse = await res.json();
    return responseBody;
  }

  static async createPaymentIntentv2(
    stripeRequest: StripeRequestv2
  ): Promise<StripePaymentIntentResponse> {
    const res = await fetch(`/api/stripe/create-payment-intentv2`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ stripeRequest: stripeRequest }),
    });
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const responseBody: StripePaymentIntentResponse = await res.json();
    return responseBody;
  }

  static async validatePayment(paymentIntentId: string): Promise<boolean> {
    const res = await fetch(`/api/stripe/validate-payment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ paymentIntentId: paymentIntentId }),
    });
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const booking: Booking = await res.json();
    if (!booking.Paid) {
      return false;
    }
    return true;
  }
}
