import Swal from "sweetalert2";
import { Award } from "../models/award";
import {
  CreateRoomRequest,
  Message,
  ReadRoomRequest,
  Room,
} from "../models/chat";
import { Course, GetCoursesRequest } from "../models/course";
import {
  AddAccreditationRequest,
  CreateGolferRequest,
  Golfer,
  GolferInfo,
} from "../models/golfer";
import { Lesson } from "../models/lesson";
import {
  CreateListingRequest,
  GetListingRequest,
  Listing,
  UpdateListingRequest,
} from "../models/listing";
import { CreateReservationRequest, Reservation } from "../models/reservation";
import { UpdateUserRequest, User } from "../models/user";
import * as JSON from "../utils/json";

interface UpdateRequest {
  newValue: string;
}
export default class ScratchApiService {
  private static apiKey = process.env.REACT_APP_SCRATCHAPI_API_KEY;
  private static storageAccountName =
    process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME;
  static async createGolfer(
    golferRequest: CreateGolferRequest
  ): Promise<Golfer> {
    const result: Golfer = await fetch(`/api/golfers/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<CreateGolferRequest>(golferRequest),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getGolferByUserId(uId: string): Promise<Golfer> {
    const result: Golfer = await fetch(`/api/golfers/by-user/${uId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getGolferByGolferId(gId: string): Promise<Golfer> {
    const result: Golfer = await fetch(`/api/golfers/by-golfer/${gId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getGolfersByLocation(
    lat: number,
    long: number,
    distance: number
  ): Promise<Golfer[]> {
    const result: Golfer[] = await fetch(
      `/api/golfers/by-location/${lat}/${long}/${distance ?? 25}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getGolferInformation(golferId?: string): Promise<GolferInfo> {
    const result: GolferInfo = await fetch(`/api/golfers/info/${golferId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async updateUserDescription(
    userId: string,
    description: string
  ): Promise<User> {
    const result: User = await fetch(
      `/api/users/update-description/${userId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify<UpdateRequest>({
          newValue: description,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async updateGolferPricing(
    golferId: number,
    newPrice: number
  ): Promise<Golfer> {
    const result: Golfer = await fetch(
      `/api/golfers/update-pricing/${golferId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify<UpdateRequest>({
          newValue: newPrice.toString(),
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async updatePicture(user: User, fileName: string): Promise<User> {
    const result: User = await fetch(`/api/users/update-picture/${user.Id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<UpdateRequest>({
        newValue: `${user.Username}_${fileName}`,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async updateUsername(userId: string, username: string): Promise<User> {
    const result: User = await fetch(`/api/users/update-username/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<UpdateRequest>({
        newValue: username,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async updateEmail(userId: string, email: string): Promise<User> {
    const result: User = await fetch(`/api/users/update-email/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<UpdateRequest>({
        newValue: email,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getAccreditations(golferId: number): Promise<Award[]> {
    const result: Award[] = await fetch(`/api/golfers/awards/${golferId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async deleteAccreditation(
    golferId: number,
    awardId: number
  ): Promise<boolean> {
    const result: boolean = await fetch(
      `/api/golfers/awards/${golferId}/${awardId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async addAccreditations(
    golferId: number,
    awards: File[]
  ): Promise<Award[]> {
    const result: Award[] = await fetch(`/api/golfers/awards/${golferId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<AddAccreditationRequest>({
        awards: awards.map((a) => {
          return a.name;
        }),
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async createChatRoom(
    userId: string,
    recipientId: string
  ): Promise<Room> {
    const url = `/api/chat/room/create`;
    const result: Room = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<CreateRoomRequest>({
        UserId: userId,
        RecipientId: recipientId,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async updateReadRoom(
    userId: string,
    roomId: string,
    timeRead: Date
  ): Promise<boolean> {
    const url = `/api/chat/room/read`;
    const result: boolean = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<ReadRoomRequest>({
        UserId: userId,
        RoomId: roomId,
        Time: timeRead,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return true;
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getChatRoom(userId: string, isGolfer: boolean): Promise<Room> {
    const url = `/api/chat/room/by-${isGolfer ? "golfer" : "user"}/${userId}`;
    const result: Room = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getChatRooms(userId: string): Promise<Room[]> {
    const url = `/api/chat/rooms/by-user/${userId}`;
    const result: Room[] = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getChatRoomMessages(roomId: string): Promise<Message[]> {
    const result: Message[] = await fetch(`/api/chat/messages/${roomId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getUnreadCount(userId: string): Promise<number> {
    try {
      const response = await fetch(`/api/chat/messages/count/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      return data.unreadCount;
    } catch (error) {
      throw error;
    }
  }

  static async getUnreadMessages(userId: string): Promise<Message[]> {
    try {
      const response = await fetch(`/api/chat/messages/unread/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      return data.messages;
    } catch (error) {
      throw error;
    }
  }

  static async getListings(
    lat: number,
    long: number,
    distance: number,
    startDate: Date | null,
    endDate: Date | null
  ): Promise<Listing[]> {
    const result: Listing[] = await fetch(`/api/listings/by-location`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<GetListingRequest>({
        latitude: lat,
        longitude: long,
        distance: distance,
        startDate: startDate,
        endDate: endDate,
      }),
    })
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getListingById(listingId: string): Promise<Listing> {
    const result: Listing = await fetch(`/api/listings/by-id/${listingId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getListingsByHostId(hostUserId: string): Promise<Listing[]> {
    const result: Listing[] = await fetch(
      `/api/listings/by-host/${hostUserId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getReservationsByListingId(
    listingId: string
  ): Promise<Reservation[]> {
    const result: Reservation[] = await fetch(
      `/api/reservations/by-listing/${listingId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getReservationsByUserId(userId: string): Promise<Reservation[]> {
    const result: Reservation[] = await fetch(
      `/api/reservations/by-user/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getReservationByReservationId(
    reservationId: number
  ): Promise<Reservation> {
    const result: Reservation = await fetch(
      `/api/reservations/by-id/${reservationId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async createReservation(
    reservationRequest: CreateReservationRequest
  ): Promise<Reservation> {
    const result: Reservation = await fetch(`/api/reservations/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<CreateReservationRequest>(reservationRequest),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          return null;
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
    return result;
  }

  static async createListing(
    listingRequest: CreateListingRequest
  ): Promise<Listing> {
    const result: Listing = await fetch(`/api/listings/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<CreateListingRequest>(listingRequest),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async updateListing(
    listingRequest: UpdateListingRequest
  ): Promise<Listing> {
    const result: Listing = await fetch(
      `/api/listings/update/${listingRequest.listingId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify<UpdateListingRequest>(listingRequest),
      }
    )
      .then((res) => {
        if (!res.ok) {
          if (res.status === 422) {
            const error = new Error(
              "Unprocessable Entity: TotalSlots cannot be increased."
            );
            error.name = "unprocessable_entity";
            throw error;
          } else {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getCourses(searchTerm: string): Promise<Course[]> {
    const result: Course[] = await fetch(`/api/courses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify<GetCoursesRequest>({
        searchTerm: searchTerm,
      }),
    })
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getLessons(
    lat: number,
    long: number,
    distance: number
  ): Promise<Lesson[]> {
    const result: Lesson[] = await fetch(`/api/lessons`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async getUserByUsername(username: string): Promise<User> {
    const result: User = await fetch(`/api/users/by-username/${username}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return null;
        } else {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }

  static async updateUser(
    userId: string,
    updateUserRequest: UpdateUserRequest
  ): Promise<User> {
    const form = new FormData();
    form.append(
      "updateRequest",
      JSON.stringify<UpdateUserRequest>(updateUserRequest)
    );
    if (updateUserRequest.profilePhoto) {
      const profilePhotoBlob = await fetch(updateUserRequest.profilePhoto).then(
        (response) => response.blob()
      );
      const file = new File([profilePhotoBlob], "cropped-image.jpg");
      form.append("files", file, "profile");
    }
    if (updateUserRequest.coverPhoto) {
      const coverPhotoBlob = await fetch(updateUserRequest.coverPhoto).then(
        (response) => response.blob()
      );
      const file = new File([coverPhotoBlob], "cropped-image.jpg");
      form.append("files", file, "cover");
    }

    const result: User = await fetch(`/api/users/update/${userId}`, {
      method: "POST",
      body: form,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((ex) => {
        throw ex;
      });
    return result;
  }
}
