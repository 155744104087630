import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UsernameAlertModal from "../components/common/UsernameAlertModal";
import FAQs from "../components/Home/FAQs";
import Golfers from "../components/Home/Golfers";
import Header from "../components/Home/Hero";
import HowItWorks from "../components/Home/HowItWorks";
import WebsiteSummary from "../components/Home/Mission";
import ProductCards from "../components/Home/ProductCards";
import Testimonials from "../components/Home/Testimonials";
import useAuth from "../hooks/useAuth";

import "../styles/index.css";

const Home = () => {
  // if username DNE, popup to direct them to create one
  const [user] = useAuth();
  const navigate = useNavigate();
  const [showUsernameModal, setShowUsernameModal] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      if (!user.Username) {
        setShowUsernameModal(true);
      }
    }
  }, [user]);

  return (
    <div className="home page">
      <Header />
      <WebsiteSummary />
      <ProductCards />
      <HowItWorks />
      <Golfers />
      <Testimonials />
      <FAQs />
      <UsernameAlertModal
        show={showUsernameModal}
        modalToggle={() => setShowUsernameModal(false)}
      />
    </div>
  );
};

export default Home;
