import styles from "./Outing.module.css";
import {
  Listing,
  ListingTypeEnum,
  ListingViewType,
} from "../../lib/models/listing";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

interface OutingProps {
  listing: Listing;
  onListingClick: (listing: Listing, type: ListingTypeEnum) => void;
  listingViewType: ListingViewType;
}

const Outing: React.FC<OutingProps> = ({
  listing,
  onListingClick,
  listingViewType,
}) => {
  const navigate = useNavigate();

  const handleClickUser = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); //prevent from calling onListingClick
    if (listing.User.Username) {
      navigate(`/profile/${listing.User.Username}`);
    }
  };

  return (
    <div
      className={`${styles.eventCard} ${
        listingViewType === ListingViewType.Write ? styles.clickable : ""
      }`}
      onClick={() => onListingClick(listing, ListingTypeEnum.Outing)}
    >
      <div className={styles.image}>
        <img
          className={styles.listingImage}
          alt=""
          src={listing.Picture ? `${listing.Picture} ` : "/default_event.png"}
        />
        {listing.FeaturedFlag && (
          <div className={styles.badge}>
            <img className={styles.featuredIcon} alt="" src="/award02.svg" />
            <div className={styles.text}>Featured</div>
          </div>
        )}

        {/* TODO: ADD FAVORITING */}
        {/* {listingViewType === ListingViewType.Write && (
            <div className={styles.favoriteButton}></div>
          )} */}
      </div>
      <div className={styles.content}>
        <div className={styles.textAndButton}>
          <div className={styles.textAndCategory}>
            <div className={styles.text1}>{listing.Title}</div>
          </div>
        </div>
        <div className={styles.detailsWrap1}>
          <div className={styles.textAndIcon}>
            <img className={styles.heartIcon} alt="" src="/markerpin02.svg" />
            <div className={styles.text6}>{listing.Course.ClubName}</div>
          </div>
        </div>
        {listing.User && (
          <div className={styles.wrapAvatar}>
            <div className={styles.avatar}>
              <img alt="" src={listing.User.ImageUrl} />

              {/* <div className={styles.avatarInner}>
                <div className={styles.rectangleParent}>
                  <img
                    className={styles.layer2Copy}
                    alt=""
                    src="/Scratchmaker_Strikethrough.png"
                  />
                </div>
              </div> */}
            </div>
            <div className={styles.content1}>
              <div className={styles.textAndSupportingText}>
                <div className={styles.textAndSubtext}>
                  <div
                    className={`${styles.userName}`}
                    onClick={(e) => handleClickUser(e)}
                  >
                    {listing.User.Username}
                  </div>
                </div>
                <div className={styles.rating}>
                  {listing.User.Location && (
                    <div className={styles.supportingText}>
                      <img
                        className={styles.heartIcon}
                        alt=""
                        src="/markerpin02.svg"
                      />
                      {`${listing.User.Location}`}
                    </div>
                  )}
                  {listing.User.Handicap != null && (
                    <div className={styles.supportingText}>
                      Handicap: <span>{`${listing.User.Handicap}`}</span>
                    </div>
                  )}
                  {/* <div className={styles.starsWrap}>
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                  </div>
                  <div className={styles.ratingWrap}>
                    <div className={styles.text30}>4.9</div>
                  </div>
                  <div className={styles.supportingText4}>(202)</div> */}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles.badgesParent}>
          <div className={styles.badges}>
            {listing.Format && (
              <div className={styles.badge1}>
                <img
                  className={styles.featuredIcon}
                  alt=""
                  src="/flag052.svg"
                />
                <div className={styles.text2}>{listing.Format}</div>
              </div>
            )}
            {listing.Price && (
              <div className={styles.badge1}>
                <img
                  className={styles.featuredIcon}
                  alt=""
                  src="/trophy01.svg"
                />
                <div className={styles.text2}>${listing.Prize} Purse</div>
              </div>
            )}
          </div>
          {listing.Price && listingViewType !== ListingViewType.Checkout && (
            <div className={styles.textAndSupportingText1}>
              <div className={styles.text5}>${listing.Price}</div>
            </div>
          )}
        </div>
        <div className={styles.details}>
          <div className={styles.detailsWrap}>
            <div className={styles.textAndIcon}>
              <img className={styles.heartIcon} alt="" src="/calendar1.svg" />
              <div className={styles.text6}>
                <Moment format="MMM DD">{listing.EventDate.toString()}</Moment>
              </div>
            </div>
            <div className={styles.textAndIcon}>
              <img className={styles.heartIcon} alt="" src="/alarmclock.svg" />
              <div className={styles.text6}>
                <Moment format="h:mm A">{listing.EventDate.toString()}</Moment>
              </div>
            </div>
          </div>
        </div>

        {listing.Reservations != null &&
          listing.TotalSlots != null &&
          listingViewType !== ListingViewType.Checkout && (
            <div className={styles.textAndButton1}>
              <div className={styles.textAndCategory}>
                <div className={styles.container}>
                  <div className={styles.chart}>
                    <div className={styles.spotsTargetChart} />
                    <div
                      className={styles.slotsIndicator}
                      style={{
                        width: `${
                          listing.Reservations.length > 0
                            ? `${
                                (listing.Reservations.length /
                                  listing.TotalSlots) *
                                100
                              }%`
                            : "20px"
                        }`,
                      }}
                    />
                  </div>
                  <div className={styles.detail}>
                    <div className={styles.spots}>
                      <div className={styles.spotsFilled}>
                        {listing.Reservations && listing.Reservations.length}
                      </div>
                      <div className={styles.spotsTarget}>
                        {`/${listing.TotalSlots} Spots`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default Outing;
