import { UserInformation } from "../../../../lib/models/user";
import ProfileSummary from "../../ViewProfile/ProfileSummary";

interface SummaryProps {
  userInfo: UserInformation;
}

const Summary: React.FC<SummaryProps> = ({ userInfo }) => {
  return <ProfileSummary userInfo={userInfo} />;
};

export default Summary;
