import styles from "./Success.module.css";
import {
  ListingTypeEnum,
  ListingTypeEnumMap,
  ListingTypeImageMap,
  ListingViewType,
} from "../../lib/models/listing";
import { useEffect, useState } from "react";
import ScratchApiService from "../../lib/services/scratch-api-service";
import { Reservation } from "../../lib/models/reservation";
import Tournament from "../ListingsPageViewAll/Tournament";
import CasualCard from "../ListingsPageViewAll/CasualCard";
import ExclusiveCourse from "../ListingsPageViewAll/ExclusiveCourse";
import Outing from "../ListingsPageViewAll/Outing";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

interface SuccessProps {
  reservationId: number;
}

const Success: React.FC<SuccessProps> = ({ reservationId }) => {
  const [user] = useAuth();
  const [reservation, setReservation] = useState<Reservation>();
  const [imageUrl, setImageUrl] = useState<string>();
  useEffect(() => {
    if (reservationId) {
      ScratchApiService.getReservationByReservationId(reservationId).then(
        setReservation
      );
    }
  }, [reservationId]);

  useEffect(() => {
    if (reservation) {
      setImageUrl(
        ListingTypeImageMap[
          ListingTypeEnumMap[reservation.Listing.ListingTypeId]
        ]
      );
    }
  }, [reservation]);

  const handleClick = () => {
    console.log("");
  };
  return (
    <>
      {reservation && (
        <div className={styles.section}>
          <div className={styles.frameParent}>
            <div className={styles.illust1Parent}>
              <img
                className={styles.star1Icon}
                alt=""
                src={imageUrl ?? "/Scratchmaker_Strikethrough.png"}
              />
            </div>
            <div className={styles.successParent}>
              <div className={styles.orderConfirmed}>Reservation Confirmed</div>
              <div className={styles.orderNumber12345676}>
                Reservation Number: {reservation.Id}
              </div>
              <div className={styles.dolorEnimEu}>
                {reservation.Listing &&
                  (() => {
                    switch (reservation.Listing.ListingType.Type) {
                      case ListingTypeEnum.Competitive:
                        return (
                          <Tournament
                            listing={reservation.Listing}
                            onListingClick={handleClick}
                            listingViewType={ListingViewType.Checkout}
                          />
                        );
                      case ListingTypeEnum.Outing:
                        return (
                          <Outing
                            listing={reservation.Listing}
                            onListingClick={handleClick}
                            listingViewType={ListingViewType.Checkout}
                          />
                        );
                      case ListingTypeEnum.Casual:
                        return (
                          <CasualCard
                            listing={reservation.Listing}
                            onListingClick={handleClick}
                            listingViewType={ListingViewType.Checkout}
                          />
                        );
                      case ListingTypeEnum.Exclusive:
                        return (
                          <ExclusiveCourse
                            listing={reservation.Listing}
                            onListingClick={handleClick}
                            listingViewType={ListingViewType.Checkout}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
              </div>
              <div className={styles.socialShare}>
                <div className={styles.cancel}>
                  <div className={styles.button}>
                    <img
                      className={styles.grid01Icon}
                      alt=""
                      src="/grid01.svg"
                    />
                    <NavLink
                      to={`/profile/${user?.Username}`}
                      className={styles.text}
                    >
                      View Reservations
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Success;
