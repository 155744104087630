import React, { useState } from "react";
import ImageUploading, {
  ErrorsType,
  ImageListType,
  ImageType,
} from "react-images-uploading";
import Cropper, { Area } from "react-easy-crop";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { cropImage } from "../../lib/utils/imageCropv2";
import pica from "pica";

interface ImageUploadingButtonProps {
  value: ImageType[];
  onChange: (newImage: ImageType[]) => void;
}

export const ImageUploadingButton: React.FC<ImageUploadingButtonProps> = ({
  value,
  onChange,
  ...props
}) => {
  const maxFileSize = 1048576; // 5MB in bytes
  const [fileSizeError, setFileSizeError] = useState(false);

  // Create an instance of pica
  const picaInstance = pica();

  const handleImageError = (
    errorType: ErrorsType,
    files: ImageListType | undefined
  ) => {
    if (errorType && errorType.maxFileSize) {
      setFileSizeError(true);
    }
  };

  return (
    <>
      <ImageUploading
        value={value}
        onChange={onChange}
        onError={handleImageError}
      >
        {({ onImageUpload, onImageUpdate }) => (
          <Button
            color="primary"
            onClick={value ? onImageUpload : () => onImageUpdate(0)}
            {...props}
          ></Button>
        )}
      </ImageUploading>
    </>
  );
};

interface ImageCropperProps {
  open: boolean;
  image: string | null;
  onComplete: (image: string) => void;
  containerStyle: React.CSSProperties;
  aspectRatio: number;
  header: string;
  circle: boolean;
}

export const ImageCropper: React.FC<ImageCropperProps> = ({
  open,
  image,
  onComplete,
  containerStyle,
  aspectRatio,
  header,
  circle,
  ...props
}) => {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  // Crop Scratchmaker Event Image
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>
        <div style={containerStyle}>
          <Cropper
            image={image || ""}
            crop={crop}
            zoom={zoom}
            cropShape={circle ? "round" : "rect"}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={async () => {
            if (croppedAreaPixels) {
              const croppedImage = await cropImage(
                image || "",
                croppedAreaPixels
              );
              if (croppedImage) {
                onComplete(croppedImage);
              } else {
                // Handle the case when croppedImage is undefined
                // For example, show an error message or fallback image
              }
            }
          }}
        >
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};
