import { useContext } from "react";
import { AppStateContext } from "../lib/store/app-state/context";
import * as actions from "../lib/store/app-state/actions";
import { Golfer } from "../lib/models/golfer";

/**
 * Hook for accessing current location
 *
 * @return {*}
 */

type UseGolferHook = [Golfer | null, (golfer: Golfer) => void, () => void];
const useGolfer = (): UseGolferHook => {
  const { dispatch, state } = useContext(AppStateContext);

  return [
    state.golfer,
    actions.setGolfer(dispatch),
    actions.clearGolfer(dispatch),
  ];
};

export default useGolfer;
