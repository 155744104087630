import {
  Listing,
  ListingTypeEnum,
  ListingTypePageLimitMap,
  ListingViewType,
} from "../../lib/models/listing";
import styles from "./ListingDrillDown.module.css";
import Tournament from "./Tournament";
import CasualCard from "./CasualCard";
import ExclusiveCourse from "./ExclusiveCourse";
import usePagination from "../../hooks/usePagination";
import Outing from "./Outing";

interface ListingsDrillDownProps {
  listings: Listing[];
  type: ListingTypeEnum;
  onListingClick: (listing: Listing, type: ListingTypeEnum) => void;
}

interface ListingComponentProps {
  listing: Listing;
}

const ListingsDrillDown: React.FC<ListingsDrillDownProps> = ({
  listings,
  type,
  onListingClick,
}) => {
  const pageSize = ListingTypePageLimitMap.find(
    (item) => item.type === type
  )?.pages;

  const {
    page,
    setPage,
    totalPages,
    filteredArray,
    handleBackPage,
    handleForwardPage,
  } = usePagination({
    array: listings,
    pageSize: pageSize || 2,
  });

  const goBack = () => {
    if (page < totalPages && page > 1) {
      handleBackPage();
    }
  };
  const goForward = () => {
    if (page < totalPages) {
      handleForwardPage();
    }
  };

  const ListingComponent: React.FC<ListingComponentProps> = ({ listing }) => {
    switch (type) {
      case ListingTypeEnum.Casual:
        return (
          <CasualCard
            listing={listing}
            onListingClick={onListingClick}
            listingViewType={ListingViewType.Write}
          />
        );
      case ListingTypeEnum.Competitive:
        return (
          <Tournament
            listing={listing}
            onListingClick={onListingClick}
            listingViewType={ListingViewType.Write}
          />
        );
      case ListingTypeEnum.Outing:
        return (
          <Outing
            listing={listing}
            onListingClick={onListingClick}
            listingViewType={ListingViewType.Write}
          />
        );
      // case ListingTypeEnum.Lesson:
      //   return <LessonsListing listing={listing} />;
      case ListingTypeEnum.Exclusive:
        return (
          <ExclusiveCourse
            listing={listing}
            onListingClick={onListingClick}
            listingViewType={ListingViewType.Write}
          />
        );
      // case ListingTypeEnum.Scratchmaker:
      //   return <ScratchmakerListing listing={listing} />;
      default:
        return (
          <Tournament
            listing={listing}
            onListingClick={onListingClick}
            listingViewType={ListingViewType.Write}
          />
        );
    }
  };

  return (
    <div className={styles.eventContainer}>
      <div className={styles.eventsNearYou}>
        {filteredArray.map((l) => (
          <ListingComponent key={l.Id} listing={l} />
        ))}
      </div>
      <div className={styles.pagination}>
        <div className={styles.button12} onClick={goBack}>
          <img
            className={styles.arrowLeftIcon}
            alt=""
            src="/arrowleftred.svg"
          />
          <div className={styles.text109}>Previous</div>
        </div>
        <div className={styles.paginationNumbers}>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            (pageNumber) => (
              <div
                key={pageNumber}
                className={`${styles.paginationNumberBase} ${
                  page === pageNumber ? styles.active : ""
                }`}
                onClick={() => setPage(pageNumber)}
              >
                <div className={styles.content25}>
                  <div>{pageNumber}</div>
                </div>
              </div>
            )
          )}
        </div>
        <div className={styles.button12} onClick={goForward}>
          <div className={styles.text109}>Next</div>
          <img className={styles.arrowLeftIcon} alt="" src="/arrowright.svg" />
        </div>
      </div>
    </div>
  );
};

export default ListingsDrillDown;
