import { useNavigate } from "react-router-dom";
import { UserInformation } from "../../../lib/models/user";
import styles from "./ProfileHeader.module.css";
import useAuth from "../../../hooks/useAuth";

interface ProfileHeaderProps {
  userInfo: UserInformation;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ userInfo }) => {
  const [user] = useAuth();
  const navigate = useNavigate();
  const handleMessageClick = () => {
    navigate(`/chat/${userInfo.User.Id}`);
  };
  return (
    <div className={styles.pageHeader}>
      <div className={styles.imageWrap}>
        <img
          className={styles.imageIcon}
          alt=""
          src={userInfo.User.CoverUrl ?? "/hero-header-section@3x.png"}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.avatarAndContent}>
          <div className={styles.avatarProfilePhoto}>
            <img
              className={styles.contentIcon}
              alt=""
              src={userInfo.User.ImageUrl}
            />
          </div>
          <div className={styles.content}>
            <div className={styles.heading}>
              <div className={styles.textAndSupportingText}>
                <div className={styles.textParent}>
                  <div className={styles.text4}>
                    {userInfo.User.Name ?? userInfo.User.Username}
                  </div>
                </div>
                <div
                  className={styles.supportingText}
                >{`@${userInfo.User.Username}`}</div>
                {userInfo.User.Location && (
                  <div className={styles.supportingText}>
                    <img
                      className={styles.heartIcon}
                      alt=""
                      src="/markerpin02.svg"
                    />
                    {`${userInfo.User.Location}`}
                  </div>
                )}
                {userInfo.User.Handicap != null && (
                  <div className={styles.supportingText}>
                    Handicap: <span>{`${userInfo.User.Handicap}`}</span>
                  </div>
                )}
              </div>

              <div className={styles.actions}>
                {/* <div className={styles.button1}>
                  <img
                    className={styles.homeLineIcon}
                    alt=""
                    src="/dotshorizontal.svg"
                  />
                </div> */}
                {user && user.Username !== userInfo.User.Username && (
                  <div className={styles.button2} onClick={handleMessageClick}>
                    <img
                      className={styles.homeLineIcon}
                      alt=""
                      src="/messagechatsquarewhite.svg"
                    />
                  </div>
                )}

                {/* TODO: Add follow feature */}
                {/* <div className={styles.button3}>
                  <div className={styles.text3}>Follow</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <img className={styles.dividerIcon} alt="" src="/divider.svg" />
      </div>
    </div>
  );
};

export default ProfileHeader;
