import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { ModalType } from "./LoginModal";
import AuthService from "../../../lib/services/auth-service";

interface RegisterUserPassProps {
  changeModalType: (type: ModalType) => void;
  toggle: () => void;
  email: String;
}
export const RegisterUserPass: React.FC<RegisterUserPassProps> = ({
  changeModalType,
  toggle,
  email,
}) => {
  const [username, setUsername] = useState("");
  const [pw, setPassword] = useState("");
  const [failureMessage, setFailureMessage] = useState<String>("");
  const [, setUser] = useAuth();
  const navigate = useNavigate();

  const handleRegister = async (email: String, pw: string) => {
    // check if password passes test
    const regex = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );
    const passRegex = regex.test(pw);
    if (!passRegex) {
      setFailureMessage(
        "Password must be at least 8 characters. It must also include an uppercase character, number, and special character."
      );
      return;
    }
    let registerResult = await AuthService.userRegister(email, username, pw);
    if (!registerResult || !registerResult.success) {
      setFailureMessage(registerResult.message);
    }
    if (registerResult.success) {
      setUser(registerResult.user);
      toggle();
      navigate("/");
    }
  };

  return (
    <>
      <div className="sign-up-form">
        <section className="form-header">
          <img src="/Scratchmaker_Strikethrough.png" alt="ScratchmakerIcon" />
        </section>

        <div className="sign-up-email-form">
          <div className="field">
            <div className="WvIqLXU field-input-wrapper">
              <div className="wrap-email">
                <input
                  className="emailpass-input"
                  type="text"
                  data-lpignore="true"
                  placeholder="Enter a username"
                  id="email"
                  name="email"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="field">
            <div className="WvIqLXU field-input-wrapper">
              <div className="wrap-email">
                <input
                  className="emailpass-input"
                  type="password"
                  data-lpignore="true"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="status-message text-body-2">
            {failureMessage.length > 0 && failureMessage}
          </div>
          <span
            className="submit-email submit-button"
            onClick={() => handleRegister(email, pw)}
          >
            <p>Join</p>
          </span>
          <div className="terms-of-services text-body-3">
            <p>
              {/* TODO: ADD TERMS OF SERVICE LINK */}
              By joining, you agree to Scratchmaker's{" "}
              <a href="#" target="_blank">
                Terms of Service
              </a>
              , <br /> as well as to receive occasional emails from us.
            </p>
          </div>
        </div>
      </div>
      <footer className="login-footer modal-content-footer">
        <div className="signing-footer text-body-2">
          <p>Already a member?</p>
          <button
            type="button"
            className="link-button text-body-2 green"
            onClick={() => changeModalType(ModalType.login)}
          >
            Sign In
          </button>
        </div>
      </footer>
    </>
  );
};
