import { useEffect, useRef, useState } from "react";
import styles from "./MessageInput.module.css";

interface MessageInputProps {
  currentMessage: string;
  onChange: (msg: string) => void;
  onSend: () => void;
}

const MessageInput: React.FC<MessageInputProps> = ({
  currentMessage,
  onChange,
  onSend,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      handleInput();
    }
  }, []);
  return (
    <div className={styles.messageInput}>
      <div className={styles.messageInputWrap}>
        <div className={styles.textareaInputField}>
          <div className={styles.inputWithLabel}>
            <div className={styles.input}>
              <textarea
                ref={textareaRef}
                onInput={handleInput}
                value={currentMessage}
                className={styles.messageInputField}
                placeholder="Hey..."
                onChange={(event) => {
                  onChange(event.target.value);
                }}
                onKeyPress={(event) => {
                  // event.key === "Enter" && sendMessage();
                }}
              />
              <div className={styles.actions} onClick={onSend}>
                <div className={styles.button2}>
                  <div className={styles.text1}>Send</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
