import { useState } from "react";

export default function useCreateListingModal() {
  const [isCreateListingOpen, setIsCreateListingOpen] = useState(false);

  const toggleCreateListing = () => {
    setIsCreateListingOpen(!isCreateListingOpen);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = body.style.overflow === "" ? "hidden" : "";
    }
  };

  return {
    isCreateListingOpen,
    toggleCreateListing,
  };
}
