import Chat from "../components/v2_Chat/Chat";
import styles from "../styles/ChatPage.module.css";
const ChatPage = () => {
  return (
    <div className={styles.chatPage}>
      <div className={styles.chatSection}>
        <Chat></Chat>
      </div>
    </div>
  );
};

export default ChatPage;
