// const Mission = () => {
//   return (
//     <>
//       <div className="mission">
//         <div className="mission-head">
//           <h2>
//             Creating a golf community that connects you with skilled golfers
//             (Scratchmakers) to achieve your golf goals.
//           </h2>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Mission;
// Import required libraries
import React from "react";
import styled from "styled-components";

// Define styled components
const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #666;
  text-align: center;
  line-height: 1.5;
  max-width: 800px;
`;

const CompanyName = styled.span`
  font-family: "Brush Script MT", cursive;
  font-size: 3.4rem;
`;

// Create the React component
const Mission = () => {
  return (
    <SummaryWrapper>
      <Heading>
        Welcome to <CompanyName>Scratchmaker</CompanyName>
      </Heading>
      <Description>
        We are creating a golf community that connects you with skilled golfers
        (Scratchmakers) to achieve your golf goals.
      </Description>
    </SummaryWrapper>
  );
};

export default Mission;
