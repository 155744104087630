import styles from "./Tournament.module.css";
import {
  Listing,
  ListingTypeEnum,
  ListingViewType,
  getTotalPrice,
} from "../../lib/models/listing";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

interface TournamentProps {
  listing: Listing;
  onListingClick: (listing: Listing, type: ListingTypeEnum) => void;
  listingViewType: ListingViewType;
}

const Tournament: React.FC<TournamentProps> = ({
  listing,
  onListingClick,
  listingViewType,
}) => {
  const navigate = useNavigate();

  const handleClickUser = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); //prevent from calling onListingClick
    if (listing.User.Username) {
      navigate(`/profile/${listing.User.Username}`);
    }
  };

  return (
    <div
      className={`${styles.eventCard} ${
        listingViewType === ListingViewType.Write ? styles.clickable : ""
      }`}
      onClick={() => onListingClick(listing, ListingTypeEnum.Competitive)}
    >
      <div className={styles.content}>
        <div className={styles.detailsWrap1}>
          <div className={styles.textAndIcon}>
            <img className={styles.heartIcon} alt="" src="/markerpin02.svg" />
            <div className={styles.text6}>{listing.Course.ClubName}</div>
          </div>
        </div>
        <div className={styles.textAndButton}>
          <div className={styles.textAndCategory}>
            <div className={styles.text1}>{listing.Title}</div>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.detailsWrap}>
            <div className={styles.textAndIcon}>
              <img className={styles.heartIcon} alt="" src="/calendar1.svg" />
              <div className={styles.text6}>
                <Moment format="MMM DD">{listing.EventDate.toString()}</Moment>
              </div>
            </div>
            <div className={styles.textAndIcon}>
              <img className={styles.heartIcon} alt="" src="/alarmclock.svg" />
              <div className={styles.text6}>
                <Moment format="h:mm A">{listing.EventDate.toString()}</Moment>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.badgesParent}>
          <div className={styles.badges}>
            {listing.Format && (
              <div className={styles.badge1}>
                <img
                  className={styles.featuredIcon}
                  alt=""
                  src="/flag052.svg"
                />
                <div className={styles.text2}>{listing.Format}</div>
              </div>
            )}
            {listing.Prize && (
              <div className={styles.badge1}>
                <img
                  className={styles.featuredIcon}
                  alt=""
                  src="/trophy01.svg"
                />
                <div className={styles.text2}>${listing.Prize} Purse</div>
              </div>
            )}
            {listing.TeamSize != null && listing.TeamSize > 0 && (
              <div className={styles.badge1}>
                <div className={styles.text2}>
                  Team Size: {listing.TeamSize}
                </div>
              </div>
            )}
          </div>
          {listing.WagerAmount && (
            <div className={styles.priceDetails}>
              <div className={styles.priceDetail}>
                <span>Green Fees:</span>
                <span className={styles.priceDetailPrice}>
                  ${listing.Price}
                </span>
              </div>
              <div className={styles.priceDetail}>
                <span>{listing.Format}:</span>
                <span className={styles.priceDetailPrice}>
                  ${listing.WagerAmount}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className={styles.wrapAvatarRow}>
          {listing.User && (
            <div className={styles.wrapAvatar}>
              <div className={styles.avatar}>
                <img alt="" src={listing.User.ImageUrl} />

                {/* <div className={styles.avatarInner}>
                <div className={styles.rectangleParent}>
                  <img
                    className={styles.layer2Copy}
                    alt=""
                    src="/Scratchmaker_Strikethrough.png"
                  />
                </div>
              </div> */}
              </div>
              <div className={styles.content1}>
                <div className={styles.textAndSupportingText}>
                  <div className={styles.textAndSubtext}>
                    <div
                      className={`${styles.userName}`}
                      onClick={(e) => handleClickUser(e)}
                    >
                      {listing.User.Username}
                    </div>
                  </div>
                  <div className={styles.rating}>
                    {listing.User.Location && (
                      <div className={styles.supportingText}>
                        <img
                          className={styles.heartIcon}
                          alt=""
                          src="/markerpin02.svg"
                        />
                        {`${listing.User.Location}`}
                      </div>
                    )}
                    {listing.User.Handicap != null && (
                      <div className={styles.supportingText}>
                        Handicap: <span>{`${listing.User.Handicap}`}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {listing.Price && listingViewType !== ListingViewType.Checkout && (
            <div className={styles.greenFeesPrice}>
              <div className={styles.text4}>{`$${getTotalPrice(listing)}`}</div>
              <div className={styles.priceSubtitle}>pay at course</div>
            </div>
          )}
        </div>

        {listing.Reservations != null &&
          listing.TotalSlots != null &&
          listingViewType !== ListingViewType.Checkout && (
            <div className={styles.textAndButton1}>
              <div className={styles.textAndCategory}>
                <div className={styles.container}>
                  <div className={styles.chart}>
                    <div className={styles.spotsTargetChart} />
                    <div
                      className={styles.slotsIndicator}
                      style={{
                        width: `${
                          listing.Reservations.length > 0
                            ? `${
                                (listing.Reservations.length /
                                  listing.TotalSlots) *
                                100
                              }%`
                            : "20px"
                        }`,
                      }}
                    />
                  </div>
                  <div className={styles.detail}>
                    <div className={styles.spots}>
                      <div className={styles.spotsFilled}>
                        {listing.Reservations.length}
                      </div>
                      <div className={styles.spotsTarget}>
                        {`/${listing.TotalSlots} Spots`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default Tournament;
