import { v4 as uuid } from "uuid";
import { Action } from "./actions";
import { AppState } from "./interfaces";

export type Reducer = (state: AppState, action: Action) => AppState;

/**
 * Apply action to app state and return updated state.
 *
 * @param {AppState} state
 * @param {Action} action
 * @return {AppState}
 */
const appStateReducer: Reducer = (state, action) => {
  switch (action.type) {
    case "initialize":
      // intended for async initialization of persisted state from disk
      return action.appState;

    case "set_position":
      return { ...state, position: action.position };

    case "clear_position":
      return { ...state, position: null };

    case "set_user":
      return { ...state, user: action.user };

    case "clear_user":
      return { ...state, user: null };

    case "set_golfer":
      return { ...state, golfer: action.golfer };

    case "clear_golfer":
      return { ...state, golfer: null };

    case "set_messages":
      return { ...state, unreadMessages: action.unreadMessages };

    case "clear_messages":
      return { ...state, unreadMessages: action.unreadMessages };

    case "remove_room_messages":
      return {
        ...state,
        unreadMessages: state.unreadMessages
          ? state.unreadMessages.filter(
              (message) => message.MessageRoomId !== action.roomId
            )
          : [],
      };

    // case "save_coupon":
    //   return {
    //     ...state,
    //     coupons: [
    //       // new coupon
    //       { id: uuid(), state: action.state },

    //       // Filter out any existing coupons with the same NDC and NCPDP from the dispatched action
    //       // before appending the new coupon. This prevents saving duplicate coupons.
    //       ...state.coupons.filter(c => {
    //         const duplicate =
    //           c.state.drug.NDC === action.state.drug.NDC &&
    //           c.state.pharmacy.ncpdp === action.state.pharmacy.ncpdp;

    //         return !duplicate;
    //       }),
    //     ],
    //   };

    // case "delete_coupon":
    //   return {
    //     ...state,
    //     coupons: state.coupons.filter(c => c.id !== action.id),
    //   };

    // case "set_selected_drug_filters":
    //   return { ...state, selectedDrugFilters: action.selectedDrugFilters };

    default:
      return state;
  }
};

export default appStateReducer;
