import styles from "./EditHeader.module.css";
import { UserInformation } from "../../../../lib/models/user";
interface EditHeaderProps {
  userInfo: UserInformation;
  onSave: () => void;
}

const EditHeader: React.FC<EditHeaderProps> = ({ userInfo, onSave }) => {
  return (
    <div className={styles.pageHeader}>
      <div className={styles.imageWrap}>
        <div className={styles.image}>
          <img
            className={styles.profileBg1Icon}
            alt=""
            src={userInfo.User.CoverUrl ?? "/hero-header-section@3x.png"}
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.avatarAndContent}>
          <div className={styles.avatarProfilePhoto}>
            <img
              className={styles.contentIcon}
              alt=""
              src={userInfo.User.ImageUrl}
            />
          </div>
          <div className={styles.content}>
            <div className={styles.heading}>
              <div className={styles.textAndSupportingText}>
                <div className={styles.textParent}>
                  <div className={styles.text4}>
                    {userInfo.User.Name ?? userInfo.User.Username}
                  </div>
                </div>
                <div className={styles.supportingText}>
                  {`@${userInfo.User.Username}`}
                </div>
                {userInfo.User.Location && (
                  <div className={styles.supportingText}>
                    <img
                      className={styles.heartIcon}
                      alt=""
                      src="/markerpin02.svg"
                    />
                    {`${userInfo.User.Location}`}
                  </div>
                )}
                {userInfo.User.Handicap != null && (
                  <div className={styles.supportingText}>
                    Handicap: <span>{`${userInfo.User.Handicap}`}</span>
                  </div>
                )}
              </div>
              <div className={styles.actions}>
                {/* TODO: Give ability to see public profile */}
                {/* <div className={styles.button1}>
                  <div className={styles.text3}>View Public Profile</div>
                </div> */}
                <div className={styles.button2} onClick={onSave}>
                  <img
                    className={styles.homeLineIcon}
                    alt=""
                    src="/save01.svg"
                  />
                  <div className={styles.text3}>Save</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className={styles.dividerIcon} alt="" src="/divider.svg" />
      </div>
    </div>
  );
};

export default EditHeader;
