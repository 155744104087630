import SignUp from "../../Assets/Images/Signupicon.png";
import Play from "../../Assets/Images/Play.png";
import Paid from "../../Assets/Images/Paid.png";

const HowItWorks = () => {
  return (
    <section className="HowItWorks">
      <div className="container">
        <h1>What you can earn as a Scratchmaker</h1>
        <div className="HowItWorksInnerStatistic">
          <div className="box">
            <p>Scratchmakers in your area earned an average of*</p>
            <h2>
              <span className="money">
                <b>$40</b>
              </span>{" "}
              / Session
            </h2>
          </div>
          <div className="box">
            <p>They earn</p>
            <h2>
              <span className="money">
                <b>$400</b>
              </span>{" "}
              / week
            </h2>
          </div>
          <div className="box">
            <p>They book</p>
            <h2>
              <span className="red">
                <b>40</b>
              </span>{" "}
              sessions per month
            </h2>
          </div>
        </div>

        <div className="HowItWorksInner">
          <div className="box">
            <img src={SignUp} alt="signup"></img>
            <h2>Sign up</h2>
            <p>Register to become a Scratchmaker for hire</p>
          </div>
          <div className="box">
            <img src={Play} alt="play"></img>
            <h2>Play</h2>
            <p>Play 18, or hit the range with your client</p>
          </div>
          <div className="box">
            <img src={Paid} alt="paid"></img>
            <h2>Get Paid</h2>
            <p>Get paid both from clients and tournament prizes</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
