import { ListingTypeEnum } from "../../lib/models/listing";
import styles from "../../styles/ListingPageViewAll.module.css";

interface CategoryRowProps {
  onClickCB: (category: ListingTypeEnum) => void;
  activeType: ListingTypeEnum;
}
const CategoryRow: React.FC<CategoryRowProps> = ({ onClickCB, activeType }) => {
  return (
    <div className={styles.section}>
      <div className={styles.categoryRow}>
        <div
          className={`${styles.category} ${
            activeType === ListingTypeEnum.All ? styles.active : ""
          }`}
          onClick={() => onClickCB(ListingTypeEnum.All)}
        >
          <div className={styles.categoryImgWrapper}>
            <img className={styles.categoryIcon} alt="" src="/birdie@2x.png" />
          </div>
          <div className={styles.categoryText}>View All</div>
        </div>
        <div
          className={`${styles.category} ${
            activeType === ListingTypeEnum.Casual ? styles.active : ""
          }`}
          onClick={() => onClickCB(ListingTypeEnum.Casual)}
        >
          <div className={styles.categoryImgWrapper}>
            <img
              className={styles.categoryIcon}
              alt=""
              src="/listing-types/casual_icon.svg"
            />
          </div>
          <div className={styles.categoryText}>Casual</div>
        </div>
        <div
          className={`${styles.category} ${
            activeType === ListingTypeEnum.Competitive ? styles.active : ""
          }`}
          onClick={() => onClickCB(ListingTypeEnum.Competitive)}
        >
          <div className={styles.categoryImgWrapper}>
            <img
              className={styles.categoryIcon}
              alt=""
              src="/listing-types/tournament_icon.svg"
            />
          </div>
          <div className={styles.categoryText}>Competitive</div>
        </div>
        {/* <div
          className={`${styles.category} ${
            activeType === ListingTypeEnum.Lesson ? styles.active : ""
          }`}
          onClick={() => onClickCB(ListingTypeEnum.Lesson)}
        >
          <div className={styles.categoryImgWrapper}>
            <img
              className={styles.categoryIcon}
              alt=""
              src="/listing-types/lessons_icon.svg"
            />
          </div>
          <div className={styles.categoryText}>Lessons</div>
        </div> */}
        <div
          className={`${styles.category} ${
            activeType === ListingTypeEnum.Outing ? styles.active : ""
          }`}
          onClick={() => onClickCB(ListingTypeEnum.Outing)}
        >
          <div className={styles.categoryImgWrapper}>
            <img
              className={styles.categoryIcon}
              alt=""
              src="/listing-types/outing_icon.svg"
            />
          </div>
          <div className={styles.categoryText}>Outing</div>
        </div>
        {/* TODO: Add Exclusive and Scratchmakers/lessons */}
        {/* <div
          className={`${styles.category} ${
            activeType === ListingTypeEnum.Exclusive ? styles.active : ""
          }`}
          onClick={() => onClickCB(ListingTypeEnum.Exclusive)}
        >
          <div className={styles.categoryImgWrapper}>
            <img
              className={styles.categoryIcon}
              alt=""
              src="/listing-types/exclusive_icon.svg"
            />
          </div>
          <div className={styles.categoryText}>Exclusive Course</div>
        </div>

        <div
          className={`${styles.category} ${
            activeType === ListingTypeEnum.Scratchmaker ? styles.active : ""
          }`}
          onClick={() => onClickCB(ListingTypeEnum.Scratchmaker)}
        >
          <div className={styles.categoryImgWrapper}>
            <img
              className={styles.categoryIcon}
              alt=""
              src="/listing-types/Scratchmaker_Strikethrough.png"
            />
          </div>
          <div className={styles.categoryText}>Scratchmakers</div>
        </div> */}
      </div>
    </div>
  );
};

export default CategoryRow;
