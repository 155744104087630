import styles from "./Messages.module.css";
import { Message, Room } from "../../lib/models/chat";
import { format, isWithinInterval, startOfWeek, startOfDay } from "date-fns";
import { User } from "../../lib/models";

interface MessageRowProps {
  msg: Message;
  user: User | null;
}

const MessageRow: React.FC<MessageRowProps> = ({ msg, user }) => {
  const getTimeFormat = () => {
    const now = new Date();
    const date = new Date(msg.TimeSent);
    const isToday = isWithinInterval(date, {
      start: startOfDay(now),
      end: now,
    });
    const isWithinWeek = isWithinInterval(date, {
      start: startOfWeek(now),
      end: now,
    });

    let dateFormat: string;

    if (isToday) {
      // If the date is today, format it as "Today HH:mm a".
      dateFormat = `Today ${format(date, "hh:mm a")}`;
    } else if (isWithinWeek) {
      // If the date is within the last week (but not today), format it as "EEEE hh:mm a"
      // (e.g. "Monday 05:30 PM").
      dateFormat = format(date, "EEEE hh:mm a");
    } else {
      // If the date is more than a week ago, format it as "MM/dd/yyyy" (or whatever format you prefer).
      dateFormat = format(date, "MM/dd/yyyy");
    }
    return dateFormat;
  };

  return (
    <>
      {user && (
        <div className={styles.row}>
          <div className={styles.message}>
            <div className={styles.avatar}>
              <img src={user.ImageUrl} alt="recip" />
            </div>
            <div className={styles.content}>
              <div className={styles.contentDivider}>
                <div className={styles.text1}>{user.Username}</div>
                <div className={styles.subText}>{getTimeFormat()}</div>
              </div>
              <div className={styles.input}>
                <div className={styles.text2}>{msg.Message}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MessageRow;
