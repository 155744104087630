import GolferCard from "../common/GolferCard";
import LocationModal from "./LocationModal";
import ArrowDown from "../../Assets/Images/ArrowDown.png";
import CarrotRight from "../../Assets/Images/carrot-right.png";
import CarrotLeft from "../../Assets/Images/carrot-left.png";
import { Golfer } from "../../lib/models/golfer";
import { useEffect, useState } from "react";
import PositionService from "../../lib/services/position-service";
import usePosition from "../../hooks/usePosition";
import ScratchApiService from "../../lib/services/scratch-api-service";
import { GolferSortBy } from "../../lib/models/golferSortBy";

const Golfers = () => {
  const [position, setPosition] = usePosition();
  const [golfers, setGolfers] = useState<Golfer[]>([]);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [sortBy, setSortBy] = useState<GolferSortBy>(GolferSortBy.Recommended);
  const [showSortBy, setShowSortBy] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    const getPosition = async () => {
      if (!position) {
        const result = await PositionService.getCurrentLocation();
        if (result.success) {
          setPosition(result.position);
        } else {
          // TODO: handle failure?
        }
      }
    };
    getPosition();
  }, [position, setPosition]);

  useEffect(() => {
    async function getGolfers(lat: number, long: number, distance: number) {
      if (position) {
        const golfers = await ScratchApiService.getGolfersByLocation(
          lat,
          long,
          distance
        );
        setGolfers(golfers);

        const nPages = Math.ceil(golfers.length / 8);
        let pageArray = Array.from(Array(nPages).keys());
        pageArray = pageArray.map((p) => {
          return p + 1;
        });
        setPages(pageArray);
        //setIsLoading(false);
      }
    }
    if (position) {
      getGolfers(position.latitude, position.longitude, 25);
    }
  }, [position]);

  const toggleSortBy = () => {
    setShowSortBy(!showSortBy);
  };

  const handleSortByClick = (srtBy: GolferSortBy) => {
    setSortBy(srtBy);
    toggleSortBy();
  };

  const filterGolfers = (golfers: Golfer[]) => {
    switch (sortBy) {
      case GolferSortBy.Distance:
        return golfers
          .filter((g) => {
            return g.Distance !== null && g.Distance !== undefined;
          })
          .sort((a, b) => {
            return a.Distance && b.Distance
              ? a.Distance > b.Distance
                ? 1
                : -1
              : -1;
          });
      case GolferSortBy.Handicap:
        return golfers
          .filter((g) => {
            return g.Handicap !== null && g.Handicap !== undefined;
          })
          .sort((a, b) => {
            return a.Handicap && b.Handicap
              ? a.Handicap > b.Handicap
                ? 1
                : -1
              : -1;
          });
      case GolferSortBy.Price:
        return golfers
          .filter((g) => {
            return g.StartingPrice !== null && g.StartingPrice !== undefined;
          })
          .sort((a, b) => {
            return a.StartingPrice && b.StartingPrice
              ? a.StartingPrice > b.StartingPrice
                ? 1
                : -1
              : -1;
          });
      default:
        return golfers
          .filter((g) => {
            return g.Handicap !== null && g.Handicap !== undefined;
          })
          .sort((a, b) => {
            return a.Handicap && b.Handicap
              ? a.Handicap > b.Handicap
                ? 1
                : -1
              : -1;
          });
    }
  };

  const generateGolfers = () => {
    let pageStart = (page - 1) * 8;
    let pageEnd = page * 8;

    const tsx = filterGolfers(golfers)
      .slice(pageStart, pageEnd)
      .map((g: Golfer, i: number) => {
        return (
          <div key={i}>
            <GolferCard golfer={g} />
          </div>
        );
      });
    return tsx;
  };

  return (
    <section className="productSection">
      <div className="container">
        <div className="anchor" id="golfers"></div>
        <div className="productHeader">
          <div className="leftsideText">
            <h2>Scratchmakers For Hire</h2>
            <div className="golfer-locations">
              {position ? (
                <>Check out Scratchmakers available for hire </>
              ) : (
                <>In order to find Scratchmakers near you, please </>
              )}

              <span onClick={() => setShowLocationModal(true)}>
                <span className="set-location">
                  {position
                    ? position?.name
                      ? `in ${position.name}`
                      : "near your Current Location"
                    : "Set your location"}
                </span>
              </span>
              <LocationModal
                isOpen={showLocationModal}
                onDismiss={() => setShowLocationModal(false)}
              />
            </div>
          </div>
          <div className="sideText">
            <div className="sortby">
              <p>Sort by:</p>
              <div onClick={toggleSortBy}>
                {" "}
                {GolferSortBy[sortBy]} <img alt="arrowdown" src={ArrowDown} />
                {showSortBy && (
                  <div className="wrap-sort-by">
                    <span
                      onClick={() =>
                        handleSortByClick(GolferSortBy.Recommended)
                      }
                    >
                      Recommended
                    </span>
                    <span
                      onClick={() => handleSortByClick(GolferSortBy.Distance)}
                    >
                      Distance
                    </span>
                    <span
                      onClick={() => handleSortByClick(GolferSortBy.Handicap)}
                    >
                      Handicap
                    </span>
                    <span onClick={() => handleSortByClick(GolferSortBy.Price)}>
                      Price
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {golfers && <div className="golferCards">{generateGolfers()}</div>}

        {golfers.length > 8 && (
          <div className="paginate-buttons">
            <img
              className="carrot-left"
              src={CarrotLeft}
              onClick={() => setPage(page - 1)}
              alt="carrot-left"
            />
            <div className="page-nums">
              {pages.map((p) => {
                return (
                  <div
                    className={`page-num ${p === page ? "active-page" : ""}`}
                    key={p}
                    onClick={() => setPage(p)}
                  >
                    {p}
                  </div>
                );
              })}
            </div>
            <img
              className="carrot-right"
              src={CarrotRight}
              onClick={() => setPage(page + 1)}
              alt="carrot-right"
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Golfers;
