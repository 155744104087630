import styles from "./Messages.module.css";
import { Message, MessageRoomParticipant, Room } from "../../lib/models/chat";
import MessageRow from "./MessageRow";
import useAuth from "../../hooks/useAuth";
import MessageSelf from "./MessageSelf";
import { useEffect, useRef, useState } from "react";
import { User } from "../../lib/models";

interface MessagesProps {
  messages: Message[];
  room: Room;
}

const Messages: React.FC<MessagesProps> = ({ messages, room }) => {
  const [user] = useAuth();
  const endOfChatRef = useRef<null | HTMLDivElement>(null);
  const [sortedMessages, setSortedMessages] = useState<Message[]>([]);

  const scrollToBottom = () => {
    endOfChatRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Sort the messages by TimeSent in ascending order
    const sorted = [...messages].sort(
      (a, b) => new Date(a.TimeSent).getTime() - new Date(b.TimeSent).getTime()
    );
    setSortedMessages(sorted);
  }, [messages]);

  useEffect(() => {
    if (sortedMessages.length > 0) {
      scrollToBottom();
    }
  }, [sortedMessages]);

  const getUserById = (userId: string): User | null => {
    const participant = room.MessageRoomParticipants.find(
      (participant: MessageRoomParticipant) => participant.UserId === userId
    );
    return participant ? participant.User : null;
  };

  return (
    <div className={styles.container}>
      {/* <div className={styles.contentDivider}>
        <div className={styles.divider} />
        <div className={styles.text}>Thursday</div>
        <div className={styles.divider} />
      </div> */}
      {user && sortedMessages && (
        <div className={styles.section}>
          {sortedMessages.map((msg) => {
            const userSender = getUserById(msg.SenderUserId);
            if (msg.SenderUserId === user.Id) {
              return <MessageSelf key={msg.Id} msg={msg} user={userSender} />;
            } else {
              return <MessageRow key={msg.Id} msg={msg} user={userSender} />;
            }
          })}
          <div ref={endOfChatRef}></div>
        </div>
      )}
    </div>
  );
};

export default Messages;
