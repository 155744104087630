import Hero from "../components/HowItWorks/Hero";
import styles from "../styles/HowItWorks.module.css";
import Features from "../components/HowItWorks/Features";
import HowToBook from "../components/HowItWorks/HowToBook";
import HowToCreate from "../components/HowItWorks/HowToCreate";
const HowItWorks = () => {
  return (
    <div className={styles.howItWorks}>
      <Hero />
      <Features />
      <HowToBook />
      <HowToCreate />
    </div>
  );
};

export default HowItWorks;
