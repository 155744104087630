import styles from "./ListingModal.module.css";
import { Listing, ListingTypeEnum } from "../../../lib/models/listing";

import ViewListing from "./ViewListing";
import Modal from "../Modal";
import { useEffect } from "react";

interface ListingModalProps {
  listingType: ListingTypeEnum;
  listing: Listing;
  isOpen: boolean;
  toggle: () => void;
}
const ListingModal = (props: ListingModalProps) => {
  const handleToggle = () => {
    props.toggle();
  };

  useEffect(() => {
    return () => {
      const body = document.querySelector("body");
      if (body) {
        body.style.overflow = "";
      }
    };
  }, []);

  const fillModal = () => {
    return (
      <div className={styles.listingModalWrapper}>
        <div className={styles.listingModalBackdrop} />
        <ViewListing
          listingType={props.listingType}
          listing={props.listing}
          onClose={() => props.toggle()}
        />
      </div>
    );
  };
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={handleToggle}
        children={fillModal()}
      />
    </>
  );
};

export default ListingModal;
