import React, { useState } from "react";
import Quotation from "../../Assets/Images/quotationmark.svg";
import TestimonialArrow from "../../Assets/Images/ArrowRightBlack.png";
import { TestimonialData } from "../common/Content";

const Testimonials = () => {
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const handleNextClick = () => {
    if (testimonialIndex + 1 >= TestimonialData.length) {
      setTestimonialIndex(0);
    } else {
      setTestimonialIndex(testimonialIndex + 1);
    }
  };
  return (
    <section className="testimonialSection">
      <div className="container">
        <div className="testimonial">
          <h3>Testimonials</h3>
          {TestimonialData.map((testimonial, index) => {
            const show = testimonialIndex === index ? "show" : "";
            let ratingArray = Array.from(
              { length: Math.ceil(testimonial.rating) },
              (x, i) => i
            );
            let stars = ratingArray.map((idx) => {
              if (testimonial.rating - idx < 1) {
                return (
                  <i className="fa-sharp fa-solid fa-star-half" key={idx}></i>
                );
              }
              return <i className="fa-sharp fa-solid fa-star" key={idx}></i>;
            });
            return (
              <div className={`testimonialCard ${show}`} key={index}>
                <img alt="quotation" src={Quotation} />
                <p>{testimonial.content}</p>
                <div className="testimonialBottom">
                  <div className="testimonialBottomLeft">
                    <img src={testimonial.image} alt="prof" />
                    <div className="testimonialBottomLeftDetails">
                      <h4>{testimonial.name}</h4>
                      <p>{testimonial.company}</p>
                    </div>
                  </div>
                  <div className="stars">{stars}</div>
                </div>
              </div>
            );
          })}

          <div className="sliderButtons">
            <div className="dots">
              {TestimonialData.map((testimonial, index) => {
                const active = testimonialIndex === index ? "activeDot" : "";
                return <div className={`dot ${active}`} key={index}></div>;
              })}
            </div>
            <img src={TestimonialArrow} alt="arrow" onClick={handleNextClick} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
