import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Golfer } from "../../lib/models/golfer";

interface StripeCheckoutProps {
  golfer: Golfer;
}
const StripeCheckout: React.FC<StripeCheckoutProps> = ({ golfer }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `https://www.scratchmakergolf.com//checkout-success/${golfer.Id}`,
        receipt_email: golfer.User?.Email,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setMessage("There was an error with processing your payment");
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        <div id="payment-element2"></div>
      </label>
      <PaymentElement id="payment-element" />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="greenBtn continue"
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Book"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default StripeCheckout;
