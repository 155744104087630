import { FacebookIcon, GoogleIcon } from "../Content";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { ModalType } from "./LoginModal";
import AuthService from "../../../lib/services/auth-service";
import { LoginType } from "../../../lib/models";

interface LoginProps {
  changeModalType: (type: ModalType) => void;
  toggle: () => void;
}
export const Login: React.FC<LoginProps> = ({ changeModalType, toggle }) => {
  const [, setUser] = useAuth();
  const [email, setEmail] = useState("");
  const [pw, setPassword] = useState("");
  const [statusMessage, setStatusMessage] = useState<String>("");
  const [remember, setRemember] = useState<boolean>();
  const navigate = useNavigate();
  const [hideSocial, setHideSocial] = useState<boolean>(false);
  const location = useLocation();

  const toggleRememberMeInput = () => {
    const cb = document.getElementById("remember_me") as HTMLInputElement;
    if (cb) {
      cb.checked = !cb.checked;
    }
  };

  const handleRemember = () => {
    setRemember(!remember);
  };

  useMemo(() => {
    if (location.search.indexOf("src=social") > 0) {
      setHideSocial(true);
    }
  }, [location.search]);

  const handleLogin = async (loginType: LoginType) => {
    switch (loginType) {
      case LoginType.Google:
        AuthService.googleLoginHandler();
        break;
      case LoginType.Facebook:
        AuthService.facebookLoginHandler();
        break;
      default:
        let loginResponse = await AuthService.localLogin(email, pw);

        console.log(loginResponse);
        if (!loginResponse) {
          setStatusMessage(
            "An error occured during sign in. Please try again later."
          );
          return;
        }

        if (!loginResponse.success) {
          setStatusMessage(loginResponse.message);
          return;
        }
        setUser(loginResponse.user);
        toggle();
        navigate("/");
    }
  };

  return (
    <>
      <div className="sign-up-form">
        <section className="form-header">
          <img src="/Scratchmaker_Strikethrough.png" alt="ScratchmakerIcon" />
        </section>
        {!hideSocial && (
          <div className="social-signing">
            {/* TODO: Facebook login */}
            {/* <button
            type="button"
            className="icon-button social-signing-button facebook-signing-button"
            onClick={() => handleLogin(LoginType.Facebook)}
          >
            <FacebookIcon />
            <p>Continue with Facebook</p>
          </button> */}
            <button
              type="button"
              className="icon-button social-signing-button google-signing-button"
              onClick={() => handleLogin(LoginType.Google)}
            >
              <GoogleIcon />
              <p>Continue with Google</p>
            </button>
            <div className="form-separator">
              <span>or</span>
            </div>
          </div>
        )}

        <div className="sign-up-email-form">
          <div className="field">
            <div className="WvIqLXU field-input-wrapper">
              <div className="wrap-email">
                <input
                  className="emailpass-input"
                  type="text"
                  data-lpignore="true"
                  placeholder="Email/Username"
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="field">
            <div className="WvIqLXU field-input-wrapper">
              <div className="wrap-email">
                <input
                  className="emailpass-input"
                  type="password"
                  data-lpignore="true"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="status-message text-body-2">
            {statusMessage.length > 0 && statusMessage}
          </div>
          <span
            className="submit-email submit-button"
            onClick={() => handleLogin(LoginType.Local)}
          >
            <p>Continue</p>
          </span>
          <div className="forgot-password text-body-2">
            <label className="remember-me">
              <input
                type="checkbox"
                id="remember_me"
                name="user_session[remember_me]"
                value="true"
                checked={remember}
                onChange={handleRemember}
              />
              <span
                className="cb-remember-wrap"
                onClick={toggleRememberMeInput}
              >
                <span className="cb-remember" aria-hidden="true">
                  <svg
                    width="11"
                    height="9"
                    viewBox="0 0 11 9"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3.64489 8.10164L0.158292 4.61504C-0.0511769 4.40557 -0.0511769 4.06594 0.158292 3.85645L0.916858 3.09786C1.12633 2.88837 1.46598 2.88837 1.67545 3.09786L4.02419 5.44658L9.05493 0.41586C9.2644 0.206391 9.60405 0.206391 9.81352 0.41586L10.5721 1.17445C10.7816 1.38392 10.7816 1.72355 10.5721 1.93303L4.40348 8.10166C4.19399 8.31113 3.85436 8.31113 3.64489 8.10164V8.10164Z"></path>
                  </svg>
                </span>
              </span>
              <p className="text-body-2">Remember Me</p>
            </label>
            <button
              type="button"
              className="link-button text-body-2 green"
              onClick={() => changeModalType(ModalType.forgotpassword)}
            >
              Forgot Password?
            </button>
          </div>
        </div>
      </div>
      <footer className="login-footer modal-content-footer">
        <div className="signing-footer text-body-2">
          <p>Not a member yet?</p>
          <button
            type="button"
            className="link-button text-body-2 green"
            onClick={() => changeModalType(ModalType.register)}
          >
            Join now
          </button>
        </div>
      </footer>
    </>
  );
};
