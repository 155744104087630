import { FunctionComponent, useState } from "react";
import styles from "./HowToCreate.module.css";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useCreateListingModal from "../../hooks/useCreateListingModal";
import LoginModal from "../common/auth/LoginModal";
import CreateListingModal from "../common/listings/create-listing-modal/CreateListingModal";
import ProcessUserModal from "../common/auth/process-user/ProcessUserModal";
import useLoginModal from "../../hooks/useLoginModal";
import useProcessUserModal from "../../hooks/useProcessUserModal";
const HowToCreate: FunctionComponent = () => {
  const [step, setStep] = useState<number>(1);
  const [user] = useAuth();
  const { isCreateListingOpen, toggleCreateListing } = useCreateListingModal();
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const { isProcessUserOpen, toggleProcessUser } = useProcessUserModal();

  const handleHostClick = () => {
    if (user) {
      if (user.Location) {
        toggleCreateListing();
      } else {
        toggleProcessUser();
      }
    } else {
      toggleLogin();
    }
  };
  return (
    <div className={styles.featuresSection} id="create">
      <div className={styles.wrapFeatures}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.headingAndSupportingText}>
              <div className={styles.headingAndSubheading}>
                <div className={styles.subheading}>
                  Finding players is easier than ever before
                </div>
                <div className={styles.heading}>Create listings in minutes</div>
              </div>
              <div className={styles.supportingText}>
                Hosting a golf event has never been easier! Follow the three
                simple steps below and your listing will be made available to
                players near you.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container1}>
          <div className={styles.content1}>
            <div
              className={`${
                step === 1 ? styles.featureTabSelected : styles.featureTab
              }`}
              onClick={() => setStep(1)}
            >
              <div className={styles.textAndSupportingText}>
                <div className={styles.step}>Create Events</div>
                <div className={styles.supportingText1}>
                  Using the “Host a Round” tab, select the type of listing you
                  want to host and fill in the required details. Share the
                  listing link on your social media to spread the word!
                </div>
              </div>
              <div className={styles.button} onClick={handleHostClick}>
                <div className={styles.text1}>Host a Round</div>
                <img
                  className={styles.arrowRightIcon}
                  alt=""
                  src="/arrowright.svg"
                />
              </div>
            </div>
            {/* if step = 2, class is featureTabSelected. onclick will change step to 2 */}
            <div
              className={`${
                step === 2 ? styles.featureTabSelected : styles.featureTab
              }`}
              onClick={() => setStep(2)}
            >
              <div className={styles.textAndSupportingText}>
                <div className={styles.step}>Manage Events</div>
                <div className={styles.supportingText1}>
                  Use the Event Manager on your Profile page to view, edit, or
                  communicate with the golfers attending your event.
                </div>
              </div>
              <NavLink
                to={`/profile/${user?.Username}`}
                className={styles.button}
              >
                <div className={styles.text1}>Manage Listings</div>
                <img
                  className={styles.arrowRightIcon}
                  alt=""
                  src="/arrowright.svg"
                />
              </NavLink>
            </div>
            <div
              className={`${
                step === 3 ? styles.featureTabSelected : styles.featureTab
              }`}
              onClick={() => setStep(3)}
            >
              <div className={styles.textAndSupportingText}>
                <div className={styles.step}>
                  Meet Up, Collect Funds + Play ⛳️
                </div>
                <div className={styles.supportingText1}>
                  Meet up on the course, collect any competitive round buy-ins
                  or outing fees, and play!
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content2}>
            {step === 1 ? (
              <div className={styles.screenMockup}>
                <div className={styles.mockupShadow} />
                <img
                  className={styles.screenMockupReplaceFill}
                  alt=""
                  src="/create_step1.png"
                />
                {/* if step = 3, show a different picture */}
              </div>
            ) : step === 2 ? (
              <div className={styles.screenMockup}>
                <div className={styles.mockupShadow} />
                <img
                  className={styles.screenMockupReplaceFill}
                  alt=""
                  src="/create_step2.png"
                />
                {/* if step = 3, show a different picture */}
              </div>
            ) : (
              <div className={styles.wrapImage}>
                <img src="/meetup.jpg" alt="meetup with golfers" />
              </div>
            )}
          </div>
        </div>
      </div>
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
      <CreateListingModal
        isOpen={isCreateListingOpen}
        toggle={toggleCreateListing}
      />
      <ProcessUserModal isOpen={isProcessUserOpen} toggle={toggleProcessUser} />
    </div>
  );
};

export default HowToCreate;
