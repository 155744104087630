import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Golfer } from "../../lib/models/golfer";
import { Link } from "react-router-dom";
import GolferInfoModal from "./GolferInfoModal";

interface GolferProps {
  golfer: Golfer;
}
const GolferCard: React.FC<GolferProps> = ({ golfer }) => {
  const [showInfo, setShowInfo] = useState(false);

  const renderTooltip = (props: any) => (
    <Tooltip id="info-tooltip" {...props} className="golfer-info-tooltip">
      Click for more info
    </Tooltip>
  );

  return (
    <>
      <div className="box" id="cardDetails">
        <div className="infoIcon">
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <i
              className="fa-solid fa-circle-info"
              onClick={() => setShowInfo(true)}
            ></i>
          </OverlayTrigger>
        </div>
        <img
          alt="profile-pic"
          src={`https://scratchmaker.blob.core.windows.net/profiles/${golfer.ImageUrl}`}
        ></img>
        <div className="productHeading1">
          <h3>{golfer.UserName}</h3>
          <h3>${golfer.StartingPrice}</h3>
        </div>
        <div className="productHeading2">
          <div className="productHeading3">
            {/* <div className="stars">
              <i className="fa-sharp fa-solid fa-star"></i>
            </div>
            <span className="golfer-handicap">{golfer.Rating}</span> */}
            <span className="golfer-location">{golfer.PositionName}</span>
          </div>
          {golfer.Handicap && (
            <div className="productHeading4">
              <span className="hdcp-lbl">HDCP:</span>
              <p className="hdcp">{golfer.Handicap}</p>
            </div>
          )}
        </div>
        {/* <div className="productTags">
          <div className="tag">Long Drive</div>
          <div className="tag">Wedge Pro</div>
          <div className="tag">Chipper</div>
        </div>{" "} */}
        <Link
          className="cardButton"
          to={`/checkout/${golfer.Id}`}
          state={{
            golfer: golfer,
          }}
        >
          HIRE NOW
        </Link>{" "}
      </div>

      {showInfo && (
        <GolferInfoModal
          show={showInfo}
          modalToggle={setShowInfo}
          golfer={golfer}
        />
      )}
    </>
  );
};

export default GolferCard;
