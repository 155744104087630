// ./src/azure-storage-blob.ts

// <snippet_package>
// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import {
  BlobServiceClient,
  BlobUploadCommonResponse,
  ContainerClient,
} from "@azure/storage-blob";
import { User } from "../models";
import { v4 as uuidv4 } from "uuid";

export default class AzureBlobService {
  private static profileSasToken =
    process.env.REACT_APP_AZURE_STORAGE_PROFILES_SAS_TOKEN;
  private static awardsSasToken =
    process.env.REACT_APP_AZURE_STORAGE_AWARDS_SAS_TOKEN;
  private static identitySasToken =
    process.env.REACT_APP_AZURE_STORAGE_IDENTITY_SAS_TOKEN;
  private static listingsSasToken =
    process.env.REACT_APP_AZURE_STORAGE_LISTINGS_SAS_TOKEN;
  private static storageAccountName =
    process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME;

  static async createBlobInContainer(
    file: File,
    newName: string,
    container: string
  ) {
    let uploadUrl = "";
    switch (container) {
      case "awards":
        uploadUrl = `https://${this.storageAccountName}.blob.core.windows.net/?${this.awardsSasToken}`;
        break;
      case "identity":
        uploadUrl = `https://${this.storageAccountName}.blob.core.windows.net/?${this.identitySasToken}`;
        break;
      case "listings":
        uploadUrl = `https://${this.storageAccountName}.blob.core.windows.net/?${this.listingsSasToken}`;
        break;
      default:
        uploadUrl = `https://${this.storageAccountName}.blob.core.windows.net/?${this.profileSasToken}`;
        break;
    }

    const blobService = new BlobServiceClient(uploadUrl);

    // create blobClient for container
    const containerClient: ContainerClient =
      blobService.getContainerClient(container);

    const blobClient = containerClient.getBlockBlobClient(newName);

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    // upload file
    let res = await blobClient.uploadData(file, options);
    console.log("File Uploaded");

    return res;
  }

  static async addProfilePicture(
    file: File | null,
    user: User | null
  ): Promise<Boolean> {
    if (!file) return false;
    if (!user) return false;
    const newName = `${user.Username}_${file.name}`;
    // upload file
    const res = await AzureBlobService.createBlobInContainer(
      file,
      newName,
      "profiles"
    );
    if (!res) return false;
    return true;
  }

  static async addIdentity(
    file: File | null,
    user: User | null
  ): Promise<Boolean> {
    if (!file) return false;
    if (!user) return false;
    const newName = `${user.Username}_${file.name}`;
    // upload file
    const res = await AzureBlobService.createBlobInContainer(
      file,
      newName,
      "identity"
    );
    if (!res) return false;
    return true;
  }

  static async addAccreditations(
    username: string,
    files: File[]
  ): Promise<Boolean> {
    let queries: Promise<BlobUploadCommonResponse>[] = [];
    files.forEach((file) => {
      const newName = `${username}_${file.name}`;
      queries.push(
        AzureBlobService.createBlobInContainer(file, newName, "awards")
      );
    });
    let res = await Promise.all<BlobUploadCommonResponse>(queries);
    if (!res) return false;
    return true;
  }

  static async addListing(file: File | null): Promise<string | null> {
    if (!file) return null;
    const newName = `${uuidv4()}_${file.name}`;
    // upload file
    const res = await AzureBlobService.createBlobInContainer(
      file,
      newName,
      "listings"
    );
    if (!res) return null;

    const url = `https://scratchmaker.blob.core.windows.net/listings/${newName}`;
    return url;
  }
}
