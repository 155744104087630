import { FunctionComponent } from "react";
import styles from "./Hero.module.css";
import Tournament from "../ListingsPageViewAll/Tournament";
import { Listing, ListingViewType } from "../../lib/models/listing";
import CasualCard from "../ListingsPageViewAll/CasualCard";
import useLoginModal from "../../hooks/useLoginModal";
import LoginModal from "../common/auth/LoginModal";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
const Hero: FunctionComponent = () => {
  const exampleListing1: Listing = {
    Id: "123456789",
    ListingType: {
      Id: 1,
      Type: "Sale",
      createdAt: "2022-05-01T09:00:00.000Z",
      updatedAt: "2022-05-02T15:30:00.000Z",
    },
    ListingTypeId: 1,
    User: {
      Id: "987654321",
      Email: "example@example.com",
      Name: "John Doe",
      Username: "JohnPentley",
      ImageUrl: "/avatar@2x.png",
      CoverUrl: "https://example.com/cover.jpg",
      Description: "I'm an avid golfer and love to play whenever I can!",
      Handicap: 10,
      PhoneNumber: "+1 123-456-7890",
      Location: "Charlotte, NC",
    },
    Title: "8-man Skins Match",
    Format: "Skins",
    Course: {
      Id: 1,
      FacilityID: "ABC123",
      ClubName: "Green Valley Golf Club",
      ClubMembership: "Gold",
      NumberOfHoles: 18,
      Latitude: 40.7128,
      Longitude: -74.006,
      Address: "123 Main Street",
      City: "New York",
      State: "NY",
      Country: "USA",
      PostalCode: "10001",
      Phone: "+1 234-567-8901",
      Fax: "+1 234-567-8902",
      Website: "https://example.com/greenvalley",
      ContactName: "Jane Smith",
      ContactTitle: "Golf Club Manager",
      EmailAddress: "info@greenvalley.com",
      DrivingRange: true,
      PuttingGreen: true,
      ChippingGreen: true,
      PracticeBunker: true,
      MotorCart: true,
      PullCart: true,
      GolfClubsRental: true,
      ClubFitting: true,
      ProShop: true,
      GolfLessons: true,
      CaddieHire: false,
      Restaurant: true,
      ReceptionHall: false,
      ChangingRoom: true,
      Lockers: true,
      LodgingOnSite: false,
      createdAt: "2022-05-01T09:00:00.000Z",
      updatedAt: "2022-05-02T15:30:00.000Z",
    },
    Description: "Selling a brand new set of golf clubs. Never been used.",
    FeaturedFlag: true,
    Picture: "https://example.com/listing.jpg",
    EventDate: "2023-06-15 9:00",
    Price: 60,
    Prize: 160,
    WagerFormat: null,
    WagerAmount: 20,
    TeamSize: 1,
    GreenFeesIncluded: null,
    HandicapLimit: null,
    HandicapLimitValue: null,
    TotalSlots: 8,
    SlotsTaken: 5,
    Reservations: null,
    createdAt: "2023-05-30T10:30:00.000Z",
    updatedAt: "2023-05-31T14:45:00.000Z",
    TotalPrice: 80,
  };
  exampleListing1.Reservations = [
    {
      Id: 2,
      Listing: exampleListing1,
      User: {
        Id: "222222",
        Email: "user2@example.com",
        Name: "User2",
        Username: "user2",
        ImageUrl: "https://example.com/user2.jpg",
        CoverUrl: "https://example.com/cover2.jpg",
        Description: "Looking forward to playing with fellow golf enthusiasts!",
        Handicap: 12,
        PhoneNumber: "+1 234-567-8901",
        Location: "California, USA",
      },
      createdAt: "2023-05-30T12:30:00.000Z",
      updatedAt: "2023-05-31T10:45:00.000Z",
    },
    {
      Id: 2,
      Listing: exampleListing1,
      User: {
        Id: "222222",
        Email: "user2@example.com",
        Name: "User2",
        Username: "user2",
        ImageUrl: "https://example.com/user2.jpg",
        CoverUrl: "https://example.com/cover2.jpg",
        Description: "Looking forward to playing with fellow golf enthusiasts!",
        Handicap: 12,
        PhoneNumber: "+1 234-567-8901",
        Location: "California, USA",
      },
      createdAt: "2023-05-30T12:30:00.000Z",
      updatedAt: "2023-05-31T10:45:00.000Z",
    },
    {
      Id: 2,
      Listing: exampleListing1,
      User: {
        Id: "222222",
        Email: "user2@example.com",
        Name: "User2",
        Username: "user2",
        ImageUrl: "https://example.com/user2.jpg",
        CoverUrl: "https://example.com/cover2.jpg",
        Description: "Looking forward to playing with fellow golf enthusiasts!",
        Handicap: 12,
        PhoneNumber: "+1 234-567-8901",
        Location: "California, USA",
      },
      createdAt: "2023-05-30T12:30:00.000Z",
      updatedAt: "2023-05-31T10:45:00.000Z",
    },
    {
      Id: 2,
      Listing: exampleListing1,
      User: {
        Id: "222222",
        Email: "user2@example.com",
        Name: "User2",
        Username: "user2",
        ImageUrl: "https://example.com/user2.jpg",
        CoverUrl: "https://example.com/cover2.jpg",
        Description: "Looking forward to playing with fellow golf enthusiasts!",
        Handicap: 12,
        PhoneNumber: "+1 234-567-8901",
        Location: "California, USA",
      },
      createdAt: "2023-05-30T12:30:00.000Z",
      updatedAt: "2023-05-31T10:45:00.000Z",
    },
    {
      Id: 2,
      Listing: exampleListing1,
      User: {
        Id: "222222",
        Email: "user2@example.com",
        Name: "User2",
        Username: "user2",
        ImageUrl: "https://example.com/user2.jpg",
        CoverUrl: "https://example.com/cover2.jpg",
        Description: "Looking forward to playing with fellow golf enthusiasts!",
        Handicap: 12,
        PhoneNumber: "+1 234-567-8901",
        Location: "California, USA",
      },
      createdAt: "2023-05-30T12:30:00.000Z",
      updatedAt: "2023-05-31T10:45:00.000Z",
    },
  ];

  const exampleListing2: Listing = {
    Id: "123456789",
    ListingType: {
      Id: 1,
      Type: "Sale",
      createdAt: "2022-05-01T09:00:00.000Z",
      updatedAt: "2022-05-02T15:30:00.000Z",
    },
    ListingTypeId: 1,
    User: {
      Id: "987654321",
      Email: "example@example.com",
      Name: "John Doe",
      Username: "GWoodley",
      ImageUrl: "/avatar1@3x.png",
      CoverUrl: "https://example.com/cover.jpg",
      Description: "I'm an avid golfer and love to play whenever I can!",
      Handicap: 10,
      PhoneNumber: "+1 123-456-7890",
      Location: "Newport News, VA",
    },
    Title: "Need a 4th - Casual Golf",
    Format: "",
    Course: {
      Id: 1,
      FacilityID: "ABC123",
      ClubName: "Green Valley Golf Club",
      ClubMembership: "Gold",
      NumberOfHoles: 18,
      Latitude: 40.7128,
      Longitude: -74.006,
      Address: "123 Main Street",
      City: "New York",
      State: "NY",
      Country: "USA",
      PostalCode: "10001",
      Phone: "+1 234-567-8901",
      Fax: "+1 234-567-8902",
      Website: "https://example.com/greenvalley",
      ContactName: "Jane Smith",
      ContactTitle: "Golf Club Manager",
      EmailAddress: "info@greenvalley.com",
      DrivingRange: true,
      PuttingGreen: true,
      ChippingGreen: true,
      PracticeBunker: true,
      MotorCart: true,
      PullCart: true,
      GolfClubsRental: true,
      ClubFitting: true,
      ProShop: true,
      GolfLessons: true,
      CaddieHire: false,
      Restaurant: true,
      ReceptionHall: false,
      ChangingRoom: true,
      Lockers: true,
      LodgingOnSite: false,
      createdAt: "2022-05-01T09:00:00.000Z",
      updatedAt: "2022-05-02T15:30:00.000Z",
    },
    Description: "Selling a brand new set of golf clubs. Never been used.",
    FeaturedFlag: true,
    Picture: "https://example.com/listing.jpg",
    EventDate: "2023-06-4 12:00",
    Price: 40,
    Prize: null,
    WagerFormat: null,
    WagerAmount: null,
    TeamSize: null,
    GreenFeesIncluded: null,
    HandicapLimit: null,
    HandicapLimitValue: null,
    TotalSlots: 4,
    SlotsTaken: 3,
    Reservations: null,
    createdAt: "2023-05-30T10:30:00.000Z",
    updatedAt: "2023-05-31T14:45:00.000Z",
    TotalPrice: 40,
  };
  exampleListing2.Reservations = [
    {
      Id: 2,
      Listing: exampleListing2,
      User: {
        Id: "222222",
        Email: "user2@example.com",
        Name: "User2",
        Username: "user2",
        ImageUrl: "https://example.com/user2.jpg",
        CoverUrl: "https://example.com/cover2.jpg",
        Description: "Looking forward to playing with fellow golf enthusiasts!",
        Handicap: 12,
        PhoneNumber: "+1 234-567-8901",
        Location: "California, USA",
      },
      createdAt: "2023-05-30T12:30:00.000Z",
      updatedAt: "2023-05-31T10:45:00.000Z",
    },
    {
      Id: 2,
      Listing: exampleListing2,
      User: {
        Id: "222222",
        Email: "user2@example.com",
        Name: "User2",
        Username: "user2",
        ImageUrl: "https://example.com/user2.jpg",
        CoverUrl: "https://example.com/cover2.jpg",
        Description: "Looking forward to playing with fellow golf enthusiasts!",
        Handicap: 12,
        PhoneNumber: "+1 234-567-8901",
        Location: "California, USA",
      },
      createdAt: "2023-05-30T12:30:00.000Z",
      updatedAt: "2023-05-31T10:45:00.000Z",
    },
    {
      Id: 2,
      Listing: exampleListing2,
      User: {
        Id: "222222",
        Email: "user2@example.com",
        Name: "User2",
        Username: "user2",
        ImageUrl: "https://example.com/user2.jpg",
        CoverUrl: "https://example.com/cover2.jpg",
        Description: "Looking forward to playing with fellow golf enthusiasts!",
        Handicap: 12,
        PhoneNumber: "+1 234-567-8901",
        Location: "California, USA",
      },
      createdAt: "2023-05-30T12:30:00.000Z",
      updatedAt: "2023-05-31T10:45:00.000Z",
    },
  ];
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const [user] = useAuth();
  const navigate = useNavigate();

  const handleFindListings = () => {
    navigate("/listings");
  };

  return (
    <div className={styles.heroHeaderSection}>
      <div className={styles.section}>
        <div className={styles.container1}>
          <div className={styles.content7}>
            <div className={styles.headingAndSupportingText}>
              <div className={styles.headingAndBadge}>
                <div className={styles.heading}>
                  Golf More Than Ever Before
                  <div className={styles.supportingText}>
                    Find more ways to play with Scratchmaker. Whether you're
                    looking to compete for prizes, play in local outings, or
                    connect with players in your area, we have you covered.
                  </div>
                  <div className={styles.ctaText}>
                    Join Now to <b>Play More Golf</b>!
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.actions}>
              {user ? (
                <div
                  className={styles.buttonWrapper}
                  onClick={handleFindListings}
                >
                  <div className={styles.button2}>
                    <div className={styles.text14}>Find Listings</div>
                  </div>
                </div>
              ) : (
                <div className={styles.buttonWrapper} onClick={toggleLogin}>
                  <div className={styles.button2}>
                    <div className={styles.text14}>Join Now!</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.content8}>
            <div className={styles.blueCard}>
              <CasualCard
                listing={exampleListing2}
                onListingClick={() => console.log("")}
                listingViewType={ListingViewType.Read}
              />
            </div>
            <div className={styles.redCard}>
              <Tournament
                listing={exampleListing1}
                onListingClick={() => console.log("")}
                listingViewType={ListingViewType.Read}
              />
            </div>
          </div>
        </div>
      </div>
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
    </div>
  );
};

export default Hero;
