import { UserInformation } from "../../../lib/models/user";
import EventsBlock from "../EventsBlock";
import ProfileHighlight from "../ProfileHighlight";
import ReviewsBlock from "../ReviewsBlock";

import styles from "../../../styles/profile.module.css";
import ProfileHeader from "./ProfileHeader";

interface ProfileSummaryProps {
  userInfo: UserInformation;
}

const ProfileSummary: React.FC<ProfileSummaryProps> = ({ userInfo }) => {
  return (
    <div className={styles.wrapSummary}>
      {userInfo && <ProfileHeader userInfo={userInfo} />}
      <div className={styles.section}>
        <div className={styles.container}>
          <div className={styles.section1}>
            <ProfileHighlight userInfo={userInfo} />

            {/* TODO: ADD LESSONS */}
            {/* <LessonBlocks /> */}
            <EventsBlock userInfo={userInfo} />
            {/* TODO: Add either reservations or past listings or something */}
            {/* {userInfo.Reservations && userInfo.Reservations.length > 0 && (
              <ReviewsBlock userInfo={userInfo} />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSummary;
