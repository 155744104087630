import Modal from "../../common/Modal";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ModalType } from "./LoginModal";

interface RegisterFromSocialProps {
  changeModalType: (type: ModalType) => void;
  toggle: () => void;
}
export const RegisterFromSocial: React.FC<RegisterFromSocialProps> = ({
  changeModalType,
  toggle,
}) => {
  const [email, setEmail] = useState("");

  return (
    <>
      <div className="sign-up-form">
        <section className="form-header">
          <img src="/Scratchmaker_Strikethrough.png" alt="ScratchmakerIcon" />
        </section>

        <div className="sign-up-email-form">
          <div className="">Choose a username for yourself</div>
          <div className="field">
            <div className="WvIqLXU field-input-wrapper">
              <div className="wrap-email">
                <input
                  className="emailpass-input"
                  type="text"
                  data-lpignore="true"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <span
            className="submit-email submit-button"
            onClick={() => console.log(email)}
          >
            <p>Join</p>
          </span>
          <div className="terms-of-services text-body-3">
            <p>
              {/* TODO: ADD TERMS OF SERVICE LINK */}
              By joining, you agree to Scratchmaker's{" "}
              <a href="#" target="_blank">
                Terms of Service
              </a>
              , <br /> as well as to receive occasional emails from us.
            </p>
          </div>
        </div>
      </div>
      <footer className="login-footer modal-content-footer">
        <div className="signing-footer text-body-2">
          <p>Already a member?</p>
          <button
            type="button"
            className="link-button text-body-2 green"
            onClick={() => changeModalType(ModalType.login)}
          >
            Sign In
          </button>
        </div>
      </footer>
    </>
  );
};
