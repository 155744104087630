import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Homev2 from "./pages/Homev2";
import { Routes, Route } from "react-router-dom";
import BecomeAGolfer from "./pages/BecomeAGolfer";
import SignUpGolfer from "./pages/SignUpGolfer";
import Checkout from "./pages/Checkout";
import Navbar from "./components/common/Navbar";
import MyProfile from "./pages/MyProfile";
import useAuth from "./hooks/useAuth";
import AuthService from "./lib/services/auth-service";
import PasswordReset from "./pages/PasswordReset";
import useGolfer from "./hooks/useGolfer";
import ScratchApiService from "./lib/services/scratch-api-service";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import ListingPageViewAll from "./pages/ListingPageViewAll";
import Checkoutv2 from "./pages/Checkoutv2";
import Profilev2 from "./pages/Profilev2";
import NewsletterContainer from "./components/v2_Home/NewsletterContainer";
import FooterSection from "./components/common/footer/FooterSection";
import BottomFooter from "./components/common/footer/BottomFooter";
import styles from "./styles/ListingPageViewAll.module.css";
import ChatPage from "./pages/ChatPage";
import HowItWorks from "./pages/HowItWorks";
import useMessages from "./hooks/useMessages";
import { clearUnreadMessages } from "./lib/store/app-state/actions";

function App() {
  const location = useLocation();
  const [user, setUser, clearUser] = useAuth();
  const [unreadMessages, setUnreadMessages] = useMessages();

  const noNavbarRegex =
    /^\/(checkout\/[\w-]+|page1\/[\w-]+|page3\/[\w-]+|page2)$/;
  const shouldRenderNavbar = !noNavbarRegex.test(location.pathname);
  const noFooterRegex =
    /^\/(checkout\/[\w.-]+|profile\/[\w.-]+|chat(\/[\w.-]+)?)$/;

  const shouldRenderFooter = !noFooterRegex.test(location.pathname);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    if (!user) {
      (async () => {
        try {
          const loggedInUser = await AuthService.getLoggedInUser();
          if (!loggedInUser) return;
          setUser(loggedInUser);
        } catch (error) {
          console.error("Error retrieving logged-in user:", error);
        }
      })();
    } else {
      (async () => {
        try {
          const fetchedUser = await ScratchApiService.getUserByUsername(
            user.Username
          );
          if (!fetchedUser) {
            clearUser();
          }
        } catch (error) {
          console.error("Error retrieving user by username:", error);
        }
      })();
    }
  }, [user, setUser, clearUser]);

  useEffect(() => {
    const checkUnreadMessages = async () => {
      try {
        if (user) {
          const unreadResult = await ScratchApiService.getUnreadMessages(
            user.Id
          );
          if (unreadResult && unreadResult.length > 0) {
            setUnreadMessages(unreadResult);
          }
        }
      } catch (error) {
        console.error("Error retrieving unread messages:", error);
      }
    };

    // Initially check for unread messages
    checkUnreadMessages();

    // Set up the interval to check for unread messages every 10 seconds
    const interval = setInterval(checkUnreadMessages, 30000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [user]);

  return (
    <div className="App">
      {shouldRenderNavbar && <Navbar location={location} />}
      {/* <Navbar location={location} /> */}
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Homev2 />} />
        <Route path="/login_failed" element={<Homev2 />} />
        <Route path="/process" element={<Homev2 />} />

        <Route path="/home" element={<Home />} />
        <Route path="/listings" element={<ListingPageViewAll />}>
          <Route path=":typeFilter" element={<ListingPageViewAll />} />
          <Route
            path=":typeFilter/:listingId"
            element={<ListingPageViewAll />}
          />
        </Route>
        <Route path="/becomeagolfer" element={<BecomeAGolfer />} />
        <Route path="/golfersignup" element={<SignUpGolfer />} />
        <Route path="/checkout_old/:golferId" element={<Checkout />} />
        <Route path="/checkout/:listingId" element={<Checkoutv2 />} />
        <Route
          path="/checkout-success/:golferId"
          element={<CheckoutSuccess />}
        />
        {/* <Route path="/profile" element={<MyProfile />} /> */}
        <Route path="/profile" element={<Profilev2 />}>
          <Route path=":userName" element={<Profilev2 />} />
        </Route>

        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route path="/chat" element={<ChatPage />}>
          <Route path=":userId" element={<ChatPage />} />
        </Route>
        <Route path="/how" element={<HowItWorks />} />
        <Route path="/signup" element={<HowItWorks />} />
      </Routes>
      {/* <Footer /> */}
      {shouldRenderFooter && (
        <div className={styles.footer}>
          {!user && <NewsletterContainer />}

          <div className={styles.footerSection}>
            <FooterSection />
            <BottomFooter />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
