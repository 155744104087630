import styles from "./Navbar.module.css";
import { Location, NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useEffect, useRef, useState } from "react";
import AuthService from "../../lib/services/auth-service";
import useLoginModal from "../../hooks/useLoginModal";
import LoginModal from "./auth/LoginModal";
import useGolfer from "../../hooks/useGolfer";
import CreateListingModal from "./listings/create-listing-modal/CreateListingModal";
import useCreateListingModal from "../../hooks/useCreateListingModal";
import usePosition from "../../hooks/usePosition";
import PositionService from "../../lib/services/position-service";
import useProcessUserModal from "../../hooks/useProcessUserModal";
import ProcessUserModal from "./auth/process-user/ProcessUserModal";
import ScratchApiService from "../../lib/services/scratch-api-service";
import useMessages from "../../hooks/useMessages";

interface NavbarProps {
  location: Location;
}

const Navbar: React.FC<NavbarProps> = ({ location }) => {
  const [user, , clearUser] = useAuth();
  const [golfer, , clearGolfer] = useGolfer();
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const { isProcessUserOpen, toggleProcessUser } = useProcessUserModal();
  const { isCreateListingOpen, toggleCreateListing } = useCreateListingModal();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState<Boolean>();
  const [navClass, setNavClass] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("home");
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [position, setPosition] = usePosition();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const mobileDropdownRef = useRef<HTMLDivElement>(null);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [unreadMessages] = useMessages();

  const toggleProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  // Change Header Background on Scrolling
  window.addEventListener("scroll", () => {
    const headerMenu = document.getElementById("header");
    const isMobile = window.innerWidth <= 768; // Adjust the threshold as needed

    if (
      !isMobile &&
      (window.scrollY >= 85 ||
        location.pathname.indexOf("checkout") > 0 ||
        location.pathname.indexOf("chat") > 0 ||
        location.pathname.indexOf("golfersignup") > 0)
    ) {
      headerMenu?.classList.add(styles.onScroll);
    } else {
      headerMenu?.classList.remove(styles.onScroll);
    }
  });

  useEffect(() => {
    if (user) {
      ScratchApiService.getUnreadCount(user.Id).then(setUnreadCount);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user]);

  const handleClick = (item: string) => {
    closeBurger();
  };

  const handleBurger = () => {
    const burgerMenu = document.getElementById("burger");
    const navbarMenu = document.getElementById("header");
    if (burgerMenu && navbarMenu) {
      burgerMenu.classList.toggle(styles.isActive);
      navbarMenu.classList.toggle(styles.isActive);
    }
  };

  const closeBurger = () => {
    const burgerMenu = document.getElementById("burger");
    const navbarMenu = document.getElementById("header");
    if (burgerMenu && navbarMenu) {
      burgerMenu.classList.remove(styles.isActive);
      navbarMenu.classList.remove(styles.isActive);
    }
  };

  const signOutHandler = async () => {
    const signOutResponse = await AuthService.signOut();
    //TODO popup when this fails
    if (signOutResponse) {
      clearUser();
      clearGolfer();
      closeBurger();
      navigate("/");
    }
  };

  const handleHostClick = () => {
    closeBurger();
    if (user) {
      if (user.Location) {
        toggleCreateListing();
      } else {
        toggleProcessUser();
      }
    } else {
      toggleLogin();
    }
  };

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setActiveTab(path);
  }, [location.pathname]);

  useEffect(() => {
    const pathnames = ["/", "/login_failed"]; // Array of pathnames
    setNavClass(
      `${styles.navHeader} ${
        pathnames.includes(location.pathname) ? styles.bgNone : ""
      } `
    );
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes("/login_failed")) {
      toggleLogin();
    }
  }, [location.pathname]);

  useEffect(() => {
    const getPosition = async () => {
      if (!position) {
        const result = await PositionService.getCurrentLocation();
        if (result.success) {
          setPosition(result.position);
        } else {
          // TODO: handle failure?
        }
      }
    };
    getPosition();
  }, [position, setPosition]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isOutsideDropdown =
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node);
      const isOutsideMobileDropdown =
        mobileDropdownRef.current &&
        !mobileDropdownRef.current.contains(event.target as Node);

      if (isOutsideDropdown && isOutsideMobileDropdown) {
        setShowProfileDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleViewProfile = () => {
    closeBurger();
    if (!user) return;
    toggleProfileDropdown();
    navigate(`/profile/${user.Username}`);
  };

  const handleLogin = () => {
    closeBurger();
    toggleLogin();
  };

  return (
    <>
      <nav
        className={`${styles.dropdownHeaderNavigation} ${navClass}`}
        id="header"
      >
        <div id="burger" className={styles.burger} onClick={handleBurger}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <NavLink to="/" className={styles.wrapBurger}>
          <div className={styles.burgerLogo} />
        </NavLink>
        <div className={styles.mobileMenu}>
          {user ? (
            <div className={styles.navItemBase}>
              <NavLink to="/chat" className={styles.actions}>
                <div className={styles.navItemButton}>
                  <div
                    className={`${styles.searchLgIcon} ${styles.chat}`}
                  ></div>
                  {unreadMessages && unreadMessages.length > 0 && (
                    <div className={styles.unread}>{unreadMessages.length}</div>
                  )}
                </div>
                {/* TODO: Add Notifications */}
                {/* <div className={styles.navItemButton}>
                      <div
                        className={`${styles.searchLgIcon} ${styles.bell}`}
                      />
                    </div> */}
              </NavLink>
              <div className={styles.dropdown}>
                <img
                  className={styles.avatarIcon}
                  alt=""
                  src={`${user.ImageUrl}`}
                  onClick={toggleProfileDropdown}
                />
                {showProfileDropdown && (
                  <div
                    className={styles.dropdownContent}
                    ref={mobileDropdownRef}
                  >
                    <span onClick={handleViewProfile}>View Profile</span>

                    <span className={styles.signOut} onClick={signOutHandler}>
                      Sign Out
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className={`${styles.navItemBase} ${styles.button1}`}
              onClick={handleLogin}
            >
              <div className={`${styles.text} ${styles.register} text `}>
                Register
              </div>
            </div>
          )}
        </div>
        <div id="menu" className={styles.menu}>
          <div className={styles.content}>
            <NavLink
              className={(isActive) => (isActive ? " active" : "")}
              to="/"
              onClick={() => handleClick("home")}
            >
              <div className={styles.logoIcon} id="logo"></div>
            </NavLink>

            <div className={styles.navigation}>
              <div
                className={`${styles.navItemBase} ${
                  activeTab === "" ? styles.active : ""
                }`}
              >
                <div className={styles.navigationActions}>
                  <NavLink to="/" onClick={() => handleClick("home")}>
                    <div className={`${styles.text} text `}>Home</div>
                  </NavLink>
                </div>
              </div>
              <div
                className={`${styles.navItemBase} ${
                  activeTab === "listings" ? styles.active : ""
                }`}
              >
                <div className={styles.navigationActions}>
                  <NavLink
                    to="/listings/All"
                    onClick={() => handleClick("listings")}
                  >
                    <div className={`${styles.text} text `}>Listings</div>
                  </NavLink>
                </div>
              </div>
              <NavLink
                to="/how"
                className={styles.navItemBase}
                onClick={() => handleClick("how")}
              >
                <div className={styles.navigationActions}>
                  <div className={`${styles.text} text`}>How It Works</div>
                </div>
              </NavLink>
            </div>
          </div>
          <div className={styles.rightNav}>
            <div className={styles.navigation}>
              <div className={styles.navItemBase}>
                <div className={styles.navigationActions}>
                  <div
                    className={`${styles.text} ${styles.rightLink}`}
                    onClick={handleHostClick}
                  >
                    Host a Round
                  </div>
                </div>
              </div>
              {/* TODO: Add Become A Scratchmaker */}
              {/* {!golfer && (
                <div
                  className={`${styles.navItemBase} ${
                    activeTab === "becomeagolfer" ? styles.active : ""
                  }`}
                >
                  <div className={styles.navigationActions}>
                    <NavLink
                      to="/becomeagolfer"
                      onClick={() => handleClick("becomeagolfer")}
                    >
                      <div className={`${styles.text} ${styles.rightLink}`}>
                        Become a Scratchmaker
                      </div>
                    </NavLink>
                  </div>
                </div>
              )} */}
              {!user && (
                <div className={styles.navItemBase}>
                  <div className={styles.navigationActions}>
                    <span
                      className={`${`${styles.text} text`}`}
                      onClick={handleLogin}
                    >
                      Login
                    </span>
                  </div>
                </div>
              )}

              {user ? (
                <div className={styles.navItemBase}>
                  <NavLink
                    to="/chat"
                    onClick={() => handleClick("chat")}
                    className={styles.actions}
                  >
                    <div className={styles.navItemButton}>
                      <div
                        className={`${styles.searchLgIcon} ${styles.chat}`}
                      ></div>
                      {unreadMessages && unreadMessages.length > 0 && (
                        <div className={styles.unread}>
                          {unreadMessages.length}
                        </div>
                      )}
                    </div>
                    {/* TODO: Add Notifications */}
                    {/* <div className={styles.navItemButton}>
                      <div
                        className={`${styles.searchLgIcon} ${styles.bell}`}
                      />
                    </div> */}
                  </NavLink>
                  <div className={styles.dropdown}>
                    <img
                      className={styles.avatarIcon}
                      alt=""
                      src={`${user.ImageUrl}`}
                      onClick={toggleProfileDropdown}
                    />
                    {showProfileDropdown && (
                      <div className={styles.dropdownContent} ref={dropdownRef}>
                        <span onClick={handleViewProfile}>View Profile</span>

                        <span
                          className={styles.signOut}
                          onClick={signOutHandler}
                        >
                          Sign Out
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.navItemBase} ${styles.button1}`}
                  onClick={handleLogin}
                >
                  <div className={`${styles.text} ${styles.register} text `}>
                    Register
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.placeholderNavLine1}></div>
        <div className={styles.placeholderNavLine2}></div>
      </nav>
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
      <CreateListingModal
        isOpen={isCreateListingOpen}
        toggle={toggleCreateListing}
      />
      <ProcessUserModal isOpen={isProcessUserOpen} toggle={toggleProcessUser} />
    </>
  );
};

export default Navbar;
