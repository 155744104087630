import {
  Listing,
  ListingTypeEnum,
  ListingViewType,
} from "../../lib/models/listing";
import styles from "./TournamentBlock.module.css";
import usePagination from "../../hooks/usePagination";
import CasualCard from "./CasualCard";

interface CasualBlockProps {
  listings: Listing[];
  seeAllCB: () => void;
  onListingClick: (listing: Listing, type: ListingTypeEnum) => void;
}
const CasualBlock: React.FC<CasualBlockProps> = ({
  listings,
  seeAllCB,
  onListingClick,
}) => {
  const { page, totalPages, filteredArray, handleBackPage, handleForwardPage } =
    usePagination({
      array: listings,
      pageSize: 3,
    });

  return (
    <div className={styles.eventsNearYou}>
      <div className={styles.title}>
        <div className={styles.content}>
          <div className={styles.headingAndSupportingText}>
            <div className={styles.headingAndSubheading}>
              <img
                className={styles.badgeIcon}
                alt=""
                src="/listing-types/casual_icon.svg"
              />
              <div className={styles.heading}>Casual Play</div>
            </div>
            <div className={styles.badge}>
              <img className={styles.flag05Icon} alt="" src="/flag051.svg" />
              <div className={styles.text}>
                {listings ? listings.length : 0} Listings in Search Area
              </div>
            </div>
          </div>
          {totalPages > 1 && (
            <div className={styles.subheadingParent}>
              <div
                className={`${styles.subheading} ${styles.seeAll}`}
                onClick={seeAllCB}
              >
                See All
              </div>
              <div className={styles.frameParent}>
                {page > 1 && (
                  <div className={styles.arrowWrapper} onClick={handleBackPage}>
                    <img
                      className={styles.arrowLeftIcon}
                      alt=""
                      src="/arrowleft.svg"
                    />
                  </div>
                )}
                {page < totalPages && (
                  <div
                    className={styles.arrowWrapper}
                    onClick={handleForwardPage}
                  >
                    <img
                      className={styles.arrowLeftIcon}
                      alt=""
                      src="/arrownarrowright.svg"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {filteredArray.length > 0 ? (
        <div className={styles.featuredRow}>
          {filteredArray.map((listing) => {
            return (
              <CasualCard
                listing={listing}
                key={listing.Id}
                onListingClick={onListingClick}
                listingViewType={ListingViewType.Write}
              />
            );
          })}
        </div>
      ) : (
        <div className={styles.noListings}>
          No listings found in search area
        </div>
      )}
    </div>
  );
};

export default CasualBlock;
