import ChatRoomCard from "./ChatRoomCard";
import styles from "./SidePanel.module.css";
import { Room } from "../../lib/models/chat";
import ScratchApiService from "../../lib/services/scratch-api-service";
import useAuth from "../../hooks/useAuth";
import useMessages from "../../hooks/useMessages";
interface SidePanelProps {
  room?: Room;
  rooms?: Room[];
  handleSelect: (room: Room) => void;
}
const SidePanel: React.FC<SidePanelProps> = ({ room, rooms, handleSelect }) => {
  const [user] = useAuth();
  const [unreadMessages, , , removeRoomMessages] = useMessages();
  const handleSelectRoom = async (room: Room) => {
    removeRoomMessages(room.Id);
    handleSelect(room);
    try {
      if (user) {
        await ScratchApiService.updateReadRoom(user.Id, room.Id, new Date());
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className={styles.sidePanel}>
      <div className={styles.cardHeader}>
        <div className={styles.content}>
          <div className={styles.textAndSupportingText}>
            <div className={styles.textAndBadge}>
              <div className={styles.text}>Messages</div>
              {/* TODO: Show unread number */}
              {/* <div className={styles.badge}>
                <div className={styles.text1}>1</div>
              </div> */}
            </div>
          </div>
        </div>
        <img className={styles.dividerIcon} alt="" src="/divider.svg" />
      </div>
      {/* TODO: Search */}
      {/* <div className={styles.searchWrap}>
        <div className={styles.inputDropdown}>
          <div className={styles.inputWithLabel}>
            <div className={styles.label}>Search</div>
            <div className={styles.input}>
              <div className={styles.content1}>
                <img className={styles.edit05Icon} alt="" src="/searchlg.svg" />
                <div className={styles.text5}>Search</div>
              </div>
            </div>
          </div>
          <div className={styles.hintText}>
            This is a hint text to help user.
          </div>
        </div>
      </div> */}
      {rooms && rooms.length > 0 ? (
        rooms?.map((r) => {
          const roomUnreadCount = unreadMessages
            ? unreadMessages.filter((msg) => msg.MessageRoomId === r.Id).length
            : 0;
          return (
            <div
              className={`${styles.wrapCard} ${
                r.Id === room?.Id ? styles.selected : ""
              }`}
              key={r.Id}
              onClick={() => handleSelectRoom(r)}
            >
              <ChatRoomCard room={r} unreadCount={roomUnreadCount} />
            </div>
          );
        })
      ) : (
        <span className={styles.noMessages}>No Messages Found...</span>
      )}
    </div>
  );
};

export default SidePanel;
