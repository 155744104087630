import { Booking } from "../models/booking";

export default class BookingService {
  static async getBookingByPaymentIntentId(
    paymentIntentId: string
  ): Promise<Booking> {
    const res = await fetch(`/api/bookings/${paymentIntentId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const responseBody: Booking = await res.json();
    return responseBody;
  }

  static async getBookingsByUserId(userId: string): Promise<Booking[]> {
    const res = await fetch(`/api/bookings/by-user/${userId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const responseBody: Booking[] = await res.json();
    return responseBody;
  }

  static async getBookingsByGolferId(golferId: number): Promise<Booking[]> {
    const res = await fetch(`/api/bookings/by-golfer/${golferId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const responseBody: Booking[] = await res.json();
    return responseBody;
  }
}
