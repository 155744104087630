import { useEffect, useRef, useState } from "react";
import styles from "./DatePickerMenu.module.css";
import { DateRangePicker, Range } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

interface DateRangeState {
  selection: Range;
}

interface DatePickerMenuProps {
  onDateRangeSelected: (dateRange: Range) => void;
  closeDatePicker: () => void;
}

const DatePickerMenu: React.FC<DatePickerMenuProps> = ({
  onDateRangeSelected,
  closeDatePicker,
}) => {
  const [dateRange, setDateRange] = useState<DateRangeState>({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const menuRef = useRef<HTMLDivElement>(null);

  const handleDateRangeSelected = (item: DateRangeState) => {
    setDateRange(item);
    if (item.selection.startDate && item.selection.endDate) {
      onDateRangeSelected(item.selection);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeDatePicker();
      }
    };
    const handleScroll = () => {
      closeDatePicker();
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [closeDatePicker]);

  return (
    <div ref={menuRef}>
      <DateRangePicker
        ranges={[dateRange.selection]}
        className={styles.datePicker}
        showPreview={true}
        minDate={new Date()}
        onChange={(item) =>
          handleDateRangeSelected({
            selection: {
              startDate: item.selection.startDate,
              endDate: item.selection.endDate,
              key: "selection",
            },
          })
        }
      />
    </div>
  );
};

export default DatePickerMenu;
