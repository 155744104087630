interface HeaderProps {
  buttonClick: () => void;
}
const Header = (props: HeaderProps) => {
  return (
    <section className="header">
      <div className="container">
        <div className="wrap-headers">
          <h1>Get Paid to Golf</h1>
          <h2>Turn your hard earned golf game into cash.</h2>
        </div>
        <div className="headerBtns">
          <span className="headerBtn1" onClick={props.buttonClick}>
            Become a Scratchmaker
          </span>
        </div>
      </div>
    </section>
  );
};

export default Header;
