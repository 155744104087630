import { useEffect, useState } from "react";
import ClientStories from "../components/BecomeAGolfer/ClientStories";
import Header from "../components/BecomeAGolfer/Header";
import HowItWorks from "../components/BecomeAGolfer/HowItWorks";
import FAQs from "../components/BecomeAGolfer/FAQs";
import SignUpBanner from "../components/BecomeAGolfer/SignUpBanner";
import useLoginModal from "../hooks/useLoginModal";
import "../styles/becomeAGolfer.css";
import LoginModal from "../components/common/auth/LoginModal";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const BecomeAGolfer = () => {
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const [user] = useAuth();
  const [loggedIn, setLoggedIn] = useState<Boolean>();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user]);

  const goToSignup = () => {
    navigate("/golfersignup");
  };
  return (
    <div className="becomeAGolfer page">
      {loggedIn ? (
        <Header buttonClick={goToSignup} />
      ) : (
        <Header buttonClick={toggleLogin} />
      )}

      <HowItWorks />
      <ClientStories />
      <FAQs />
      {loggedIn ? (
        <SignUpBanner buttonClick={goToSignup} />
      ) : (
        <SignUpBanner buttonClick={toggleLogin} />
      )}
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
    </div>
  );
};

export default BecomeAGolfer;
