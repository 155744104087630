import { useContext } from "react";
import { AppStateContext } from "../lib/store/app-state/context";
import * as actions from "../lib/store/app-state/actions";
import { Position } from "../lib/models/position";

/**
 * Hook for accessing current location
 *
 * @return {*}
 */

type UsePositionHook = [
  Position | null,
  (position: Position) => void,
  () => void
];
const usePosition = (): UsePositionHook => {
  const { dispatch, state } = useContext(AppStateContext);

  return [
    state.position,
    actions.setPosition(dispatch),
    actions.clearPosition(dispatch),
  ];
};

export default usePosition;
