import React from "react";
import closeModal from "../../Assets/Images/Close.png";
import { Booking } from "../../lib/models/booking";
import Moment from "react-moment";
import { Link } from "react-router-dom";

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
  booking: Booking;
}

const SuccessModal: React.FC<Props> = ({ isOpen, onDismiss, booking }) => {
  return (
    <>
      {isOpen && (
        <div className="bookSuccessModal show" id="showlocationpopup">
          <div className="popup">
            <div className="popupHeading">
              <h3>Scratchmaker Session Successfully Booked!</h3>
              <img
                alt="close"
                src={closeModal}
                id="closelocationpopupwithcancelbtn"
                onClick={onDismiss}
              />
            </div>
            <div className="popupBody">
              <div className="intro-text">
                You just booked a session with a Scratchmaker!{" "}
              </div>
              <div className="wrap-confirmation">
                <div className="wrap-golfer-highlight">
                  <p>
                    {booking.Golfer &&
                      booking.Golfer.User &&
                      `${booking.Golfer.User.Username}`}
                  </p>
                  <img
                    alt="profile-pic"
                    src={`https://scratchmaker.blob.core.windows.net/profiles/${booking.Golfer.User?.ImageUrl}`}
                  ></img>
                </div>
                <div className="wrap-confirmations">
                  <div className="confirmation-div">
                    <h3>Location</h3>
                    <p>{booking.LocationShort}</p>
                  </div>
                  <div className="confirmation-div">
                    <h3>Time</h3>
                    <p>
                      <Moment format="LLL">{booking.Time.toString()}</Moment>
                    </p>
                  </div>
                  <div className="confirmation-div">
                    <h3>
                      Grand Total:{" "}
                      <span className="total-dollars money">
                        ${booking.TotalPrice.toFixed(2)}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="wrap-buttons">
                <div className="footer-button">
                  <p>Questions?</p>
                  <Link
                    className="contact"
                    to={`/chat/${booking.UserId}/${booking.Golfer.User?.Id}`}
                  >
                    Chat with {booking.Golfer.User?.Username}
                  </Link>
                </div>
                <div className="footer-button">
                  <p>Upcoming Sessions</p>
                  <Link className="contact" to="/profile">
                    View
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessModal;
