import GolferSection from "../components/Checkout/GolferSection";
import Hero from "../components/Checkout/Hero";
import { useLocation, useParams } from "react-router-dom";
import "../styles/checkoutPageStyle.css";
import CheckoutSection from "../components/Checkout/CheckoutSection";
import { useEffect, useState } from "react";
import ScratchApiService from "../lib/services/scratch-api-service";
import { Golfer, GolferInfo } from "../lib/models/golfer";

type CheckoutParams = {
  golferId: string;
};

const Checkout: React.FC = () => {
  const { golferId } = useParams<CheckoutParams>();
  // const { golfer } = location.state;
  const [golfer, setGolfer] = useState<Golfer>();
  const [golferInfo, setGolferInfo] = useState<GolferInfo>();

  useEffect(() => {
    if (golferId) {
      ScratchApiService.getGolferByGolferId(golferId).then(setGolfer);
    }
  }, [golferId]);

  useEffect(() => {
    if (golferId) {
      ScratchApiService.getGolferInformation(golferId).then(setGolferInfo);
    }
  }, [golferId]);

  return (
    <div className="checkout page">
      <Hero />
      {golfer && golferInfo && (
        <section className="checkOutSection">
          <div className="container">
            <GolferSection golfer={golfer} golferInfo={golferInfo} />
            <CheckoutSection golfer={golfer} />
          </div>
        </section>
      )}
    </div>
  );
};

export default Checkout;
