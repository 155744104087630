import { FunctionComponent, useEffect, useMemo, useState } from "react";
import useAuth from "../hooks/useAuth";
import ScratchApiService from "../lib/services/scratch-api-service";
import { UserInformation } from "../lib/models/user";
import { useNavigate, useParams } from "react-router-dom";
import ViewProfile from "../components/v2_Profile/ViewProfile/ViewProfile";
import MyProfile from "../components/v2_Profile/MyProfile/MyProfile";
const Profilev2: FunctionComponent = () => {
  const [user, , clearUser] = useAuth();
  const [userInformation, setUserInformation] = useState<UserInformation>();
  const [selfProfile, setSelfProfile] = useState<boolean>(false);
  const { userName } = useParams();
  const navigate = useNavigate();

  useMemo(() => {
    if (user && userName && user.Username === userName) {
      setSelfProfile(true);
    } else {
      setSelfProfile(false);
    }
  }, [user, userName]);

  useEffect(() => {
    if (userName) {
      const fetchData = async () => {
        ScratchApiService.getUserByUsername(userName)
          .then((viewingUser) => {
            // TODO: Announce that we can't find that user
            if (!viewingUser) navigate("/");
            ScratchApiService.getReservationsByUserId(viewingUser.Id).then(
              (reservations) => {
                setUserInformation({
                  User: viewingUser,
                  Reservations: reservations,
                });
              }
            );
          })
          .catch((err) => {
            navigate("/");
          });
      };

      fetchData();
    }
    // if userName -> if user
  }, [navigate, selfProfile, user, userName]);

  return (
    <>
      {!selfProfile && userInformation && (
        <ViewProfile userInfo={userInformation} />
      )}
      {selfProfile && userInformation && (
        <MyProfile userInfo={userInformation} />
      )}
    </>
  );
};

export default Profilev2;
