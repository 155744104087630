import styles from "./ListingCreated.module.css";
import {
  Listing,
  ListingTypeEnum,
  ListingTypeEnumMap,
  ListingTypeImageMap,
  ListingViewType,
} from "../../../../lib/models/listing";
import { useEffect, useState } from "react";
import Tournament from "../../../ListingsPageViewAll/Tournament";
import CasualCard from "../../../ListingsPageViewAll/CasualCard";
import ExclusiveCourse from "../../../ListingsPageViewAll/ExclusiveCourse";
import Outing from "../../../ListingsPageViewAll/Outing";
import ShareModal from "../ShareModal";
import useShareModal from "../../../../hooks/useShareModal";

interface ListingCreatedProps {
  onClose: () => void;
  listing: Listing;
}
const ListingCreated: React.FC<ListingCreatedProps> = ({
  onClose,
  listing,
}) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const [listingType, setListingType] = useState<ListingTypeEnum>();
  const { isShareOpen, toggleShare } = useShareModal();

  useEffect(() => {
    if (listing) {
      setImageUrl(
        ListingTypeImageMap[ListingTypeEnumMap[listing.ListingTypeId]]
      );
      setListingType(ListingTypeEnumMap[listing.ListingTypeId]);
    }
  }, [listing]);

  const handleClick = () => {
    console.log("");
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        <div className={styles.buttonCloseX} onClick={onClose}>
          <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
        </div>
        <img className={styles.dividerIcon} alt="" src="/divider.svg" />
      </div>
      <div className={styles.whiteBoxContentParent}>
        <div className={styles.whiteBoxContent}>
          <img
            className={styles.star1Icon}
            alt=""
            src={imageUrl ?? "/Scratchmaker_Strikethrough.png"}
          />
          <div className={styles.listingCreated}>Listing Created!</div>
          {/* <div className={styles.yourListingIs}>
            Your listing is currently in review...
          </div> */}
          <div className={styles.wrapCard}>
            {listing &&
              listingType &&
              (() => {
                switch (listingType) {
                  case ListingTypeEnum.Outing:
                    return (
                      <Outing
                        listing={listing}
                        onListingClick={handleClick}
                        listingViewType={ListingViewType.Read}
                      />
                    );
                  case ListingTypeEnum.Competitive:
                    return (
                      <Tournament
                        listing={listing}
                        onListingClick={handleClick}
                        listingViewType={ListingViewType.Read}
                      />
                    );
                  case ListingTypeEnum.Casual:
                    return (
                      <CasualCard
                        listing={listing}
                        onListingClick={handleClick}
                        listingViewType={ListingViewType.Read}
                      />
                    );
                  case ListingTypeEnum.Exclusive:
                    return (
                      <ExclusiveCourse
                        listing={listing}
                        onListingClick={handleClick}
                        listingViewType={ListingViewType.Read}
                      />
                    );
                  default:
                    return null;
                }
              })()}
          </div>
          <div className={styles.socialShare}>
            <div className={styles.cancel}>
              <div className={styles.button} onClick={toggleShare}>
                <img className={styles.send01Icon} alt="" src="/send01.svg" />
                <div className={styles.text1}>Share</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareModal isOpen={isShareOpen} toggle={toggleShare} listing={listing} />
    </div>
  );
};

export default ListingCreated;
