import { useEffect, useState } from "react";
import { Listing } from "../../../../lib/models/listing";
import styles from "./EditListing.module.css";
import { charLeft } from "../../../../lib/helpers/texthelper";
import ScratchApiService from "../../../../lib/services/scratch-api-service";
import Swal from "sweetalert2";

interface EditListingProps {
  listing: Listing;
  onSave: (updatedListing: Listing) => void;
}

interface ListingData {
  title: string;
  description: string;
  totalSlots: number;
}

const EditListing: React.FC<EditListingProps> = ({ listing, onSave }) => {
  const [listingData, setListingData] = useState<ListingData>({
    title: listing.Title,
    description: listing.Description,
    totalSlots: listing.TotalSlots,
  });

  useEffect(() => {
    setListingData({
      title: listing.Title,
      description: listing.Description,
      totalSlots: listing.TotalSlots,
    });
  }, [listing]);

  const handleInputChange = (fieldName: keyof ListingData, value: any) => {
    setListingData((prevData) => {
      let newData = {
        ...prevData,
        [fieldName]: value,
      };

      return newData;
    });
  };

  const handleSave = async () => {
    if (
      listing.Title !== listingData.title ||
      listing.Description !== listingData.description ||
      listing.TotalSlots !== listingData.totalSlots
    ) {
      try {
        const newListing = await ScratchApiService.updateListing({
          listingId: listing.Id,
          title: listingData.title,
          description: listingData.description,
          totalSlots: listingData.totalSlots,
        });
        if (newListing) {
          onSave(newListing);
          Swal.fire({
            title: "Listing Updated",
            text: "Your listing has been successfully updated.",
            showCloseButton: true,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
            confirmButtonAriaLabel: "Thumbs up, great!",
          }).then((result) => {});
        }
      } catch (err: any) {
        if (err.name === "unprocessable_entity") {
          Swal.fire({
            icon: "error",
            title: "Edit Listing Failed",
            text: "TotalSlots cannot be less than the existing number of reservations",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Edit Listing Failed",
            text: "An issue occurred when editing your listing.",
          });
        }
      }
    }
  };
  return (
    <div className={styles.content1}>
      <div className={styles.header}>
        Edit Listing: <span> {listing.Title} </span>
      </div>
      <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
      <div className={styles.form}>
        <div className={styles.row}>
          <div className={styles.textParent}>
            <div className={styles.text1}>Competition Title</div>
          </div>
          <div className={styles.inputField}>
            <div className={styles.inputWithLabel}>
              <div className={styles.input}>
                <div className={styles.content2}>
                  <input
                    type="text"
                    className={styles.inputText}
                    value={listingData.title}
                    placeholder="Enter Tournament Title"
                    onChange={(e) => handleInputChange("title", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
        <div className={styles.row}>
          <div className={styles.textParent}>
            <div className={styles.text1}>Description</div>
          </div>
          <div className={styles.textareaInputField}>
            <div className={styles.inputWithLabel3}>
              <div className={styles.label}>Description*</div>
              <div className={styles.input4}>
                <textarea
                  className={styles.textarea}
                  value={listingData.description}
                  placeholder="Write a few sentences about the tournament..."
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                ></textarea>
              </div>
            </div>
            <div className={styles.hintText}>
              {charLeft(listingData.description, 1000)} characters left
            </div>
          </div>
        </div>
        <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
        <div className={styles.row}>
          <div className={styles.textParent}>
            <div className={styles.text11}>How Many Players?</div>
            <div className={styles.subtitle}>Including yourself</div>
          </div>
          <div className={styles.inputField}>
            <div className={styles.inputWithLabel}>
              <div className={styles.input2}>
                <div className={styles.addOn}>
                  <img
                    className={styles.searchLgIcon}
                    alt=""
                    src="/users01.svg"
                  />
                </div>
                <div className={styles.textInput}>
                  <input
                    type="number"
                    value={listingData.totalSlots}
                    className={styles.inputText}
                    placeholder="Enter Total Number of Players"
                    onChange={(e) =>
                      handleInputChange("totalSlots", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.submit} onClick={handleSave}>
          Save
        </div>
      </div>
    </div>
  );
};

export default EditListing;
