import { useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import styles from "./ProcessUser.module.css";
import ScratchApiService from "../../../../lib/services/scratch-api-service";

import useAuth from "../../../../hooks/useAuth";
import { charLeft } from "../../../../lib/helpers/texthelper";
import { ImageType } from "react-images-uploading";
import { ImageCropper, ImageUploadingButton } from "../../ImageUpload";
import {
  LocationSearchResult,
  Position,
  getPositionByPlaceId,
  searchByCity,
} from "../../../../lib/services/google-maps-service";
import Swal from "sweetalert2";
import { UpdateUserRequest } from "../../../../lib/models/user";

interface ProcessUserProps {
  onClose: () => void;
}

interface UserData {
  fullName: string | null;
  userName: string | null;
  bio: string | null;
  location: string | null;
  handicap: number | null;
  phoneNumber: string | null;
  profilePhoto: string | null;
  coverPhoto: string | null;
}

const ProcessUser: React.FC<ProcessUserProps> = ({ onClose }) => {
  const [user, setUser] = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<LocationSearchResult[]>(
    []
  );
  const locRef = useRef<HTMLDivElement>(null);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [image, setImage] = useState<ImageType[]>([]);
  const [croppedProfileImage, setCroppedProfileImage] = useState<string | null>(
    null
  );
  const [croppedCoverImage, setCroppedCoverImage] = useState<string | null>(
    null
  );
  const [profileDialogOpen, setProfileDialogOpen] = useState<boolean>(false);
  const [coverDialogOpen, setCoverDialogOpen] = useState<boolean>(false);

  const selectLocation = async (placeId: string, description: string) => {
    const position = await getPositionByPlaceId(placeId);
    setSearchValue(description);

    if (position.name) {
      setUserFormData((prevData) => {
        const newData = {
          ...prevData,
          location: position.name ?? null,
        };
        return newData;
      });
      setSearchResults([]);
    }
  };

  const [userFormData, setUserFormData] = useState<UserData>({
    fullName: user?.Name ?? null,
    userName: user?.Username ?? null,
    handicap: user?.Handicap ?? null,
    bio: user?.Description ?? null,
    phoneNumber: user?.PhoneNumber ?? null,
    location: user?.Location ?? null,
    profilePhoto: user?.ImageUrl ?? null,
    coverPhoto: user?.CoverUrl ?? null,
  });

  const handleInputChange = (fieldName: keyof UserData, value: any) => {
    setUserFormData((prevData) => {
      const newData = {
        ...prevData,
        [fieldName]: value,
      };
      return newData;
    });
  };

  const handleCroppedProfile = (image: string) => {
    setCroppedProfileImage(image);
    setProfileDialogOpen(false);
    setUserFormData((prevData) => ({
      ...prevData,
      profilePhoto: image,
    }));
  };

  const handleCroppedCover = (image: string) => {
    setCroppedCoverImage(image);
    setCoverDialogOpen(false);
    setUserFormData((prevData) => ({
      ...prevData,
      coverPhoto: image,
    }));
  };

  const handleProfilePhotoUpload = (newImage: ImageType[]) => {
    setProfileDialogOpen(true);
    setImage(newImage);
  };

  const handleCoverPhotoUpload = (newImage: ImageType[]) => {
    setCoverDialogOpen(true);
    setImage(newImage);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    searchByCity(value).then(setSearchResults);
  };

  const checkInputs = (data: UserData) => {
    const errors: Record<string, boolean> = {};

    if (data.userName === null) {
      errors.userName = true;
    }
    if (data.handicap === null) {
      errors.handicap = true;
    }
    if (
      data.phoneNumber === null ||
      data.phoneNumber.length < 10 ||
      isNaN(parseInt(data.phoneNumber))
    ) {
      errors.phoneNumber = true;
    }
    if (data.location === null) {
      errors.location = true;
    }

    setErrors(errors);
    return Object.keys(errors).length !== 0;
  };

  const onSave = async () => {
    if (!user) return;
    let {
      fullName,
      userName,
      handicap,
      bio,
      phoneNumber,
      location,
      profilePhoto,
      coverPhoto,
    } = userFormData;
    const hasErrors = checkInputs(userFormData);
    if (hasErrors) {
      return;
    }
    // check if the username exists
    if (userName) {
      const userFound = await ScratchApiService.getUserByUsername(userName);
      if (userFound && userFound.Id !== user.Id) {
        Swal.fire({
          title: "Username Already Exists",
          text: "Please change your desired username, as the request username already exists.",
          icon: "warning",
        });
        return;
      }
    }
    if (bio) {
      if (bio.length > 1000) {
        Swal.fire({
          title: "Bio Description Exceeds Max Characters",
          text: "Please do not exceed 1000 characters in your bio.",
          icon: "warning",
        });
        return;
      }
    }
    const updateUserRequest: UpdateUserRequest = {
      fullName,
      userName,
      bio,
      handicap,
      phoneNumber,
      location,
      profilePhoto,
      coverPhoto,
    };
    const hasValues = Object.values(updateUserRequest).some((value) => !!value);
    if (hasValues) {
      ScratchApiService.updateUser(user.Id, updateUserRequest)
        .then((updatedUser) => {
          if (updatedUser) {
            setUser(updatedUser);
            onClose();
          }
        })
        .catch((err) => {
          // Handle unsuccessful update
          Swal.fire({
            title: "Error",
            text: "Failed to update user settings.",
            icon: "error",
          });
        });
    } else {
      Swal.fire({
        title: "No User Settings to Update",
        text: "Please provide at least one value to update your user settings.",
        icon: "info",
      });
    }
  };
  return (
    <>
      <div className={styles.processUserModal}>
        <div className={styles.modalHeader}>
          <div className={styles.content}>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>
                Complete Your Profile for a Better Experience!
              </div>
              <div className={styles.supportingText}>
                To fully utilize Scratchmaker, we must learn more about you!
              </div>
            </div>
          </div>
          <div className={styles.buttonCloseX} onClick={onClose}>
            <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
          </div>
          <img className={styles.dividerIcon} alt="" src="/divider.svg" />
        </div>
        <div className={styles.content1}>
          <div className={styles.pageHeader}>
            <div className={styles.imageWrap}>
              <div className={styles.image}>
                <img
                  className={styles.profileBg1Icon}
                  alt=""
                  src={user?.CoverUrl ?? "/hero-header-section@3x.png"}
                />
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.avatarAndContent}>
                <div className={styles.avatarProfilePhoto}>
                  <img
                    className={styles.contentIcon}
                    alt=""
                    src={user?.ImageUrl}
                  />
                </div>
                <div className={styles.wrapUserName}>
                  <div className={styles.heading}>
                    <div className={styles.textAndSupportingText}>
                      <div className={styles.textParent}>
                        <div className={styles.text4}>
                          {user?.Name ?? user?.Username}
                        </div>
                      </div>
                      <div className={styles.supportingText}>
                        {`@${user?.Username}`}
                      </div>
                      {user?.Location && (
                        <div className={styles.supportingText}>
                          <img
                            className={styles.heartIcon}
                            alt=""
                            src="/markerpin02.svg"
                          />
                          {`${user?.Location}`}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.row}>
              <div className={styles.text1}>Username*</div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/user.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="text"
                        className={styles.inputText}
                        placeholder="Enter Username"
                        value={userFormData.userName ?? ""}
                        onChange={(e) =>
                          handleInputChange("userName", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.userName && (
                    <div className={styles.errorText}>
                      Please enter a username.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Your Location*</div>
              <div className={styles.inputWithLabel}>
                <div className={styles.courseSearch}>
                  <div className={styles.content3}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/searchlg.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        className={styles.inputText}
                        type="text"
                        placeholder={user?.Location ?? "Enter a city"}
                        value={
                          searchValue != null
                            ? searchValue
                            : userFormData.location
                            ? userFormData.location
                            : ""
                        }
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                  {searchResults.length > 0 && (
                    <div className={styles.searchResults} ref={locRef}>
                      {searchResults.slice(0, 10).map((loc) => (
                        <div
                          onClick={() =>
                            selectLocation(loc.placeId, loc.description)
                          }
                          className={styles.searchResultItem}
                          key={loc.placeId}
                        >
                          <span className="loc-description">
                            {loc.description}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {errors.location && (
                  <div className={styles.errorText}>
                    Please search for a location.
                  </div>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Handicap*</div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/golf-stroke.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        value={userFormData.handicap ?? ""}
                        placeholder="Enter Handicap"
                        onChange={(e) =>
                          handleInputChange("handicap", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.handicap && (
                    <div className={styles.errorText}>
                      Please enter a handicap.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>
                Phone Number*{" "}
                <div className={styles.supportingText1}>Get Notifications</div>
              </div>

              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/phone.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="tel"
                        maxLength={10}
                        className={styles.inputText}
                        value={userFormData.phoneNumber ?? ""}
                        placeholder="Phone Number"
                        onChange={(e) =>
                          handleInputChange("phoneNumber", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.phoneNumber && (
                    <div className={styles.errorText}>
                      Please enter a valid 10-digit phone number.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.text1}>Profile Photo</div>
              <div className={styles.avatarAndFileUpload}>
                <div className={styles.fileUpload}>
                  <div className={styles.fileUploadBase}>
                    {croppedProfileImage ? (
                      <>
                        <div
                          className={styles.deleteIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCroppedProfileImage(null);
                          }}
                        >
                          <AiOutlineCloseCircle />
                        </div>
                        <img
                          className={styles.uploadedImage}
                          src={croppedProfileImage}
                          alt="ProfilePhoto"
                        />
                      </>
                    ) : (
                      <label className={styles.fileUploadContent}>
                        <img
                          className={styles.featuredIcon}
                          alt=""
                          src="/featured-icon3.svg"
                        />

                        <div className={styles.textAndSupportingText1}>
                          <div className={styles.action}>
                            <div className={styles.uploadButton}>
                              <div className={styles.uploadText}>
                                Click to upload
                              </div>
                            </div>
                            {/* <div className={styles.uploadSubtext}>
                              or drag and drop
                            </div> */}
                          </div>
                          <div className={styles.supportingText1}>
                            SVG, PNG, or JPG
                          </div>
                        </div>
                        <ImageUploadingButton
                          value={image}
                          onChange={handleProfilePhotoUpload}
                        />
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Cover Photo</div>
              <div className={styles.avatarAndFileUpload}>
                <div className={styles.fileUpload}>
                  <div className={styles.fileUploadBase}>
                    {croppedCoverImage ? (
                      <>
                        <div
                          className={styles.deleteIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCroppedCoverImage(null);
                          }}
                        >
                          <AiOutlineCloseCircle />
                        </div>
                        <img
                          className={styles.uploadedImage}
                          src={croppedCoverImage}
                          alt="ProfilePhoto"
                        />
                      </>
                    ) : (
                      <label className={styles.fileUploadContent}>
                        <img
                          className={styles.featuredIcon}
                          alt=""
                          src="/featured-icon3.svg"
                        />

                        <div className={styles.textAndSupportingText1}>
                          <div className={styles.action}>
                            <div className={styles.uploadButton}>
                              <div className={styles.uploadText}>
                                Click to upload
                              </div>
                            </div>
                            {/* <div className={styles.uploadSubtext}>
                              or drag and drop
                            </div> */}
                          </div>
                          <div className={styles.supportingText1}>
                            SVG, PNG, or JPG
                          </div>
                        </div>
                        <ImageUploadingButton
                          value={image}
                          onChange={handleCoverPhotoUpload}
                        />
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.text1}>Description</div>
              <div className={styles.textareaInputField}>
                <div className={styles.inputWithLabel3}>
                  <div className={styles.input4}>
                    <textarea
                      className={styles.textarea}
                      placeholder="Write a few sentences about yourself..."
                      onChange={(e) => handleInputChange("bio", e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className={styles.hintText}>
                  {charLeft(userFormData.bio, 1000)} characters left
                </div>
              </div>
            </div>
          </div>
          <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
        </div>
        <div className={styles.modalActions}>
          <div className={`${styles.dividerWrap} ${styles.dividerActions}`}>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
          </div>
          <div className={styles.content4}>
            <div className={`${styles.nextButton} `} onClick={onSave}>
              <div className={styles.text14}>Update Profile</div>
            </div>
          </div>
        </div>
      </div>
      <ImageCropper
        open={profileDialogOpen}
        image={image.length > 0 ? image[0].dataURL ?? null : null}
        onComplete={handleCroppedProfile}
        header={"Crop Profile Photo"}
        circle={true}
        containerStyle={{
          position: "relative",
          width: "100%",
          height: 300,
          background: "#333",
        }}
        aspectRatio={1.6}
      />
      <ImageCropper
        open={coverDialogOpen}
        image={image.length > 0 ? image[0].dataURL ?? null : null}
        onComplete={handleCroppedCover}
        header={"Crop Profile Cover Photo"}
        circle={false}
        containerStyle={{
          position: "relative",
          width: "100%",
          height: 300,
          background: "#333",
        }}
        aspectRatio={4 / 1.5}
      />
    </>
  );
};

export default ProcessUser;
