import styles from "./OrderSummary.module.css";
import { Listing } from "../../lib/models/listing";

interface OrderSummaryProps {
  listing: Listing;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ listing }) => {
  return (
    <div className={styles.summary}>
      <div className={styles.price}>
        <div className={styles.expand}>
          <div className={styles.title}>
            <div className={styles.weAreProud}>
              <b>{listing.Title}</b>
            </div>
            <div className={styles.weAreProud1}>{`$${listing.Price?.toFixed(
              2
            )}`}</div>
          </div>
          {listing.WagerAmount && (
            <>
              <div className={styles.title}>
                <div className={styles.weAreProud}>Buy-In</div>
                <div className={styles.weAreProud1}>x1</div>
              </div>
            </>
          )}
          <div className={styles.title}>
            <div className={styles.weAreProud}>Subtotal</div>
            <div className={styles.weAreProud1}>
              {`$${listing.Price?.toFixed(2)} due at course`}
            </div>
          </div>
          {/* TODO: Figure out taxes */}
          {/* <div className={styles.title}>
            <div className={styles.weAreProud}>Tax</div>
            <div className={styles.weAreProud1}>$30.32</div>
          </div> */}
          {/* <div className={styles.title}>
            <div className={styles.weAreProud}>Discount</div>
            <div className={styles.weAreProud1}>$10</div>
          </div> */}
          <span className={styles.dividerIcon1}></span>
          <div className={styles.title3}>
            <div className={styles.weAreProud6}>Total Due at Course</div>
            <div className={styles.weAreProud7}>
              {`$${listing.Price?.toFixed(2)}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
