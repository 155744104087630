import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AuthService from "../../../lib/services/auth-service";

interface ResetPasswordProps {
  userId: String;
  token: String;
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({
  userId,
  token,
}) => {
  const [pw, setPw] = useState<String>("");
  const [statusMessage, setStatusMessage] = useState<String>("");
  const navigate = useNavigate();

  const handlePwChange = async () => {
    const resetResult = await AuthService.resetPassword(userId, pw, token);
    if (!resetResult) {
      setStatusMessage(
        "An error occurred assigning your new password. Please try again"
      );
      return;
    }

    if (!resetResult.success) {
      setStatusMessage(resetResult.message);
      return;
    }
    navigate("/");
  };

  return (
    <>
      <section className="standalone email-reset">
        <div className="entry">
          <div id="UserSession-ResetPasswordForm-component">
            <div className="user_session-package user-session-package entry">
              <div className="signing-entry standard">
                <form className="">
                  <div className="reset-password-form">
                    <section className="form-header">
                      <h4>Reset Password</h4>
                    </section>
                    <div className="field">
                      <div className="WvIqLXU field-input-wrapper">
                        <div className="+T1b6wH">
                          <input
                            className="pw-reset-input field-input"
                            type="password"
                            data-lpignore="true"
                            placeholder="New Password"
                            id="password"
                            name="user[password]"
                            maxLength={100}
                            onChange={(e) => setPw(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <div className="WvIqLXU field-input-wrapper">
                        <div className="+T1b6wH">
                          <input
                            className="pw-reset-input field-input"
                            type="password"
                            data-lpignore="true"
                            placeholder="Confirm Password"
                            id="passwordConfirmation"
                            name="user[password_confirmation]"
                            maxLength={100}
                            autoComplete="new_password"
                          />
                        </div>
                      </div>
                      <p className="hint text-body-2">
                        8 characters or longer. Combine upper and lowercase
                        letters and numbers.
                      </p>
                    </div>
                    <div className="status-message text-body-2">
                      {statusMessage.length > 0 && statusMessage}
                    </div>
                    <span className="submit-email" onClick={handlePwChange}>
                      <p>Change</p>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
