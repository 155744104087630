import ArrowUp from "../../Assets/Images/ArrowUp.png";
import ArrowDown from "../../Assets/Images/ArrowDown.png";
import React, { useState } from "react";

interface FAQProps {
  Question: string;
  Answer: string;
}

const FAQQuestion: React.FC<FAQProps> = ({ Question, Answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const handleFAQ = () => {
    setShowAnswer(!showAnswer);
  };
  return (
    <div className="faqbox" key={Question}>
      <div className="faqHeading" id="faq6" onClick={handleFAQ}>
        <h2>{Question}</h2>
        {showAnswer ? (
          <img src={ArrowUp} alt="faq6" />
        ) : (
          <img src={ArrowDown} alt="faq6" />
        )}
      </div>
      {showAnswer && <p className="inVisibleAns visibleAns">{Answer}</p>}
    </div>
  );
};

export default FAQQuestion;
