import styles from "./CasualCard.module.css";
import {
  Listing,
  ListingTypeEnum,
  ListingViewType,
  getTotalPrice,
} from "../../lib/models/listing";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

interface CasualCardProps {
  listing: Listing;
  onListingClick: (listing: Listing, type: ListingTypeEnum) => void;
  listingViewType: ListingViewType;
}

const CasualCard: React.FC<CasualCardProps> = ({
  listing,
  onListingClick,
  listingViewType,
}) => {
  const navigate = useNavigate();

  const handleClickUser = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); //prevent from calling onListingClick
    if (listing.User.Username) {
      navigate(`/profile/${listing.User.Username}`);
    }
  };

  return (
    <div
      className={`${styles.eventCard} ${
        listingViewType === ListingViewType.Write ? styles.clickable : ""
      }`}
      onClick={() => onListingClick(listing, ListingTypeEnum.Casual)}
    >
      <div className={styles.content}>
        <div className={styles.details}>
          <div className={styles.detailsWrap}>
            <div className={styles.textAndIcon}>
              <img
                className={styles.markerPin02Icon}
                alt=""
                src="/markerpin02.svg"
              />
              <div className={styles.text}>{listing.Course.ClubName}</div>
            </div>
          </div>
          {/* TODO: ADD FAVORITING */}
          {/* {listingViewType === ListingViewType.Write && (
            <div className={styles.favoriteButton}></div>
          )} */}
        </div>
        <div className={styles.textAndButton}>
          <div className={styles.text1}>{listing.Title}</div>
        </div>
        <div className={styles.textAndSubtext}>
          <div className={styles.detailsWrap1}>
            <div className={styles.textAndIcon}>
              <img
                className={styles.markerPin02Icon}
                alt=""
                src="/calendar2.svg"
              />
              <div className={styles.text}>
                <Moment format="MMM DD">{listing.EventDate.toString()}</Moment>
              </div>
            </div>
            <div className={styles.textAndIcon}>
              <img
                className={styles.markerPin02Icon}
                alt=""
                src="/alarmclock.svg"
              />
              <div className={styles.text}>
                <Moment format="h:mm A">{listing.EventDate.toString()}</Moment>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.textAndCategory}>
          <div className={styles.wrapAvatar}>
            <div className={styles.avatar}>
              <img alt="" src={listing.User.ImageUrl} />
              {/* <div className={styles.avatarInner}>
                <div className={styles.rectangleParent}>
                  <img
                    className={styles.layer2Copy}
                    alt=""
                    src="/Scratchmaker_Strikethrough.png"
                  />
                </div>
              </div> */}
            </div>
            <div className={styles.content1}>
              <div className={styles.textAndSupportingText}>
                <div className={styles.textAndSubtext}>
                  <div
                    className={`${styles.userName}`}
                    onClick={(e) => handleClickUser(e)}
                  >
                    {listing.User.Username}
                  </div>
                </div>
                <div className={styles.rating}>
                  {listing.User.Location && (
                    <div className={styles.supportingText}>
                      <img
                        className={styles.heartIcon}
                        alt=""
                        src="/markerpin02.svg"
                      />
                      {`${listing.User.Location}`}
                    </div>
                  )}
                  {listing.User.Handicap != null && (
                    <div className={styles.supportingText}>
                      Handicap: <span>{`${listing.User.Handicap}`}</span>
                    </div>
                  )}
                  {/* <div className={styles.starsWrap}>
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                  </div>
                  <div className={styles.ratingWrap}>
                    <div className={styles.text30}>4.9</div>
                  </div>
                  <div className={styles.supportingText4}>(202)</div> */}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.details2}>
            <div className={styles.textAndSupportingText2}>
              {listing.Price &&
                listingViewType !== ListingViewType.Checkout && (
                  <div className={styles.greenFeesPrice}>
                    <div className={styles.text4}>{`$${getTotalPrice(
                      listing
                    )}`}</div>
                    <div className={styles.priceSubtitle}>
                      green fees at course
                    </div>
                  </div>
                )}
              {/* {!listing.GreenFeesIncluded &&
                listingViewType !== ListingViewType.Checkout && (
                  <div className={styles.greenFeeCourse}>
                    Green Fees at Course
                  </div>
                )} */}
            </div>
          </div>
        </div>
        {listing.Reservations != null &&
          listing.TotalSlots != null &&
          listingViewType !== ListingViewType.Checkout && (
            <div className={styles.container}>
              <div className={styles.chart}>
                <div className={styles.spotsTargetChart} />
                <div
                  className={styles.slotsIndicator}
                  style={{
                    width: `${
                      listing.Reservations.length > 0
                        ? `${
                            (listing.Reservations.length / listing.TotalSlots) *
                            100
                          }%`
                        : "20px"
                    }`,
                  }}
                />
              </div>
              <div className={styles.detail}>
                <div className={styles.spots}>
                  <div className={styles.spotsFilled}>
                    {listing.Reservations.length}
                  </div>
                  <div className={styles.spotsTarget}>
                    {`/${listing.TotalSlots} Spots`}
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default CasualCard;
