import React, { ReactNode } from "react";
import { useEffect, useReducer, useState } from "react";
import {
  AppStateContext,
  initialState,
} from "../../lib/store/app-state/context";
import persistedAppStateReducer from "../../lib/store/app-state/persisted-reducer";
import { fetchApplicationState } from "../../lib/utils/storage";

interface AppStateProviderProps {
  children?: ReactNode;
}

const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [state, dispatch] = useReducer(persistedAppStateReducer, initialState);

  useEffect(() => {
    console.log("6");
    fetchApplicationState().then((appState) => {
      if (appState !== null) {
        dispatch({ type: "initialize", appState });
      }

      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    // TODO: replace with spinner or splash screen
    return null;
  }

  return (
    <AppStateContext.Provider value={{ dispatch, state }}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
