import { useContext } from "react";
import { AppStateContext } from "../lib/store/app-state/context";
import * as actions from "../lib/store/app-state/actions";
import { User } from "../lib/models";

type UseAuthHook = [User | null, (user: User) => void, () => void];

const useAuth = (): UseAuthHook => {
  const { dispatch, state } = useContext(AppStateContext);

  return [state.user, actions.setUser(dispatch), actions.clearUser(dispatch)];
};

export default useAuth;
