import { CustomerFAQs } from "../common/Content";
import FAQQuestion from "../common/FAQQuestion";

const FAQs = () => {
  return (
    <section className="faqsSection">
      <div className="container">
        <div className="faqs">
          <h3>FAQs</h3>
          <div className="faq_wrapper">
            {CustomerFAQs.map((faq) => {
              return (
                <FAQQuestion Question={faq.question} Answer={faq.answer} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default FAQs;
