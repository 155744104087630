import { createContext, Dispatch } from "react";
import { Action } from "./actions";
import { AppState } from "./interfaces";

export interface ContextProps {
  state: AppState;
  dispatch: Dispatch<Action>;
}

/**
 * The initial app state on startup before persisted state is loaded.
 */
export const initialState: AppState = {
  // coupons: [],
  // pharmacy: null,
  // recentSearches: [],
  user: null,
  position: null,
  golfer: null,
  unreadMessages: [],
  // selectedDrugFilters: null,
};

/**
 * The app state context initialized with defaults.
 */
export const AppStateContext = createContext<ContextProps>({
  dispatch: () => null,
  state: initialState,
});
