interface SignUpBannerProps {
  buttonClick: () => void;
}

  const SignUpBanner = (props: SignUpBannerProps) => {
  return (
    <section className="bannerSection">
      <div className="container">
        <div className="bannerInfo">
          <h2>Sign up and start earning</h2>
          <span className="whiteBtn" onClick={props.buttonClick}>
            Sign Up
          </span>
        </div>
      </div>
    </section>
  );
};

export default SignUpBanner;
