import { useEffect, useState } from "react";
import Modal from "../Modal";
import styles from "./ShareModal.module.css";
import { Listing } from "../../../lib/models/listing";

interface ShareModalProps {
  isOpen: boolean;
  toggle: () => void;
  listing: Listing;
}
export enum ModalType {
  login,
  register,
  registeruserpass,
  registersocial,
  forgotpassword,
}

const ShareModal = (props: ShareModalProps) => {
  const handleToggle = () => {
    props.toggle();
  };
  const shareToFacebook = () => {
    let url = new URL(window.location.href);
    url.search = "";
    url.pathname = "";
    const appendedUrl = `${url.toString()}listings/All/${props.listing.Id}`;

    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      appendedUrl
    )}`;
    window.open(facebookShareUrl, "_blank");
  };
  const shareToTwitter = () => {
    let url = new URL(window.location.href);
    url.search = "";
    url.pathname = "";
    const appendedUrl = `${url.toString()}listings/All/${props.listing.Id}`;
    const text = "Check out this listing I found on Scratchmaker!";
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(appendedUrl)}`;
    window.open(twitterShareUrl, "_blank");
  };

  const copyLink = () => {
    let url = new URL(window.location.href);
    url.search = "";
    url.pathname = "";
    const appendedUrl = `${url.toString()}listings/All/${props.listing.Id}`;
    navigator.clipboard
      .writeText(appendedUrl)
      .then(() => {
        // Clipboard successfully copied
        console.log("Link copied to clipboard!");
      })
      .catch((error) => {
        // Error occurred while copying
        console.error("Failed to copy link to clipboard:", error);
      });
  };

  const fillModal = () => {
    return (
      <>
        <div className={styles.shareModal}>
          <div className={styles.modalHeader}>
            <div className={styles.content}>
              <img className={styles.featuredIcon} alt="" src="/send01.svg" />
              <div className={styles.textAndSupportingText}>
                <div className={styles.text}>Share this listing via...</div>
                <div className={styles.supportingText}>
                  Please choose a share method below.
                </div>
              </div>
            </div>
            <div className={styles.buttonCloseX} onClick={handleToggle}>
              <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
            </div>
            <img className={styles.dividerIcon} alt="" src="/divider.svg" />
          </div>
        </div>
        <div className={styles.shareButtons}>
          <button className={styles.shareButton} onClick={shareToFacebook}>
            <img
              className={styles.shareIcon}
              alt="Facebook Share"
              src="/facebook.svg"
            />
            Facebook
          </button>
          <button className={styles.shareButton} onClick={shareToTwitter}>
            <img
              className={styles.shareIcon}
              alt="Twitter Share"
              src="/twitter.svg"
            />
            Twitter
          </button>
          <button className={styles.shareButton} onClick={copyLink}>
            <img
              className={styles.shareIcon}
              alt="Copy Link"
              src="/copy-link.svg"
            />
            Copy Link
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={handleToggle}
        children={fillModal()}
      />
    </>
  );
};

export default ShareModal;
