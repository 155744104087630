import styles from "../../styles/Checkoutv2.module.css";
import {
  Listing,
  ListingTypeEnum,
  ListingViewType,
} from "../../lib/models/listing";
import Tournament from "../ListingsPageViewAll/Tournament";
import ExclusiveCourse from "../ListingsPageViewAll/ExclusiveCourse";
import CasualCard from "../ListingsPageViewAll/CasualCard";
import Outing from "../ListingsPageViewAll/Outing";
import OrderSummary from "./OrderSummary";
import ScratchApiService from "../../lib/services/scratch-api-service";
import useAuth from "../../hooks/useAuth";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

interface OrderDetailsProps {
  listing: Listing;
  handleNextClick: () => void;
  handleBackClick: () => void;
  handleReservation: (reservationId: number) => void;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({
  listing,
  handleNextClick,
  handleBackClick,
  handleReservation,
}) => {
  const [user] = useAuth();
  const navigate = useNavigate();
  const handleClick = () => {
    console.log("");
  };

  const handleSubmit = async () => {
    if (!user) return;
    const reservation = await ScratchApiService.createReservation({
      User: user,
      Listing: listing,
    });
    // TODO: Track this failure
    if (!reservation) {
      Swal.fire({
        icon: "error",
        title: "Reservation Failed",
        text: "Unable to make reservation. Listing is full.",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/listings/All/${listing.Id}`);
        }
      });
    }

    handleReservation(reservation.Id);
    handleNextClick();
  };
  return (
    <>
      <div className={styles.section1}>
        <div className={styles.container1}>
          <div className={styles.sectionHeader}>
            <div className={styles.content}>
              <div className={styles.textAndSupportingText}>
                <div className={styles.text}>Order Details</div>
                <div className={styles.supportingText}>
                  Please review your order and confirm dates and time are
                  correct.
                </div>
              </div>
            </div>
            <span className={styles.dividerIcon1}></span>
          </div>

          <div className={styles.wrapCard}>
            {listing &&
              (() => {
                switch (listing.ListingType.Type) {
                  case ListingTypeEnum.Competitive:
                    return (
                      <Tournament
                        listing={listing}
                        onListingClick={handleClick}
                        listingViewType={ListingViewType.Read}
                      />
                    );
                  case ListingTypeEnum.Outing:
                    return (
                      <Outing
                        listing={listing}
                        onListingClick={handleClick}
                        listingViewType={ListingViewType.Read}
                      />
                    );
                  case ListingTypeEnum.Casual:
                    return (
                      <CasualCard
                        listing={listing}
                        onListingClick={handleClick}
                        listingViewType={ListingViewType.Read}
                      />
                    );
                  case ListingTypeEnum.Exclusive:
                    return (
                      <ExclusiveCourse
                        listing={listing}
                        onListingClick={handleClick}
                        listingViewType={ListingViewType.Read}
                      />
                    );
                  default:
                    return null;
                }
              })()}
          </div>
          <OrderSummary listing={listing} />
        </div>
      </div>
      <div className={styles.modalActions}>
        <div className={styles.content1}>
          <div
            className={`${styles.nextButton} ${styles.actionButton}`}
            onClick={handleSubmit}
          >
            <div className={styles.text5}>Reserve</div>
          </div>
          <div
            className={`${styles.backButton} ${styles.actionButton}`}
            onClick={handleBackClick}
          >
            <div className={styles.text5}>Back</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
