import React, { useEffect, useState } from "react";
import CloseButton from "../../Assets/Images/Close.png";
import { Course } from "../../lib/models/course";
import { Golfer, GolferInfo } from "../../lib/models/golfer";
import ScratchApiService from "../../lib/services/scratch-api-service";

interface InfoProps {
  golfer: Golfer;
  show: boolean;
  modalToggle: (val: boolean) => void;
}

const GolferInfoModal: React.FC<InfoProps> = ({
  show,
  modalToggle,
  golfer,
}) => {
  const [golferInfo, setGolferInfo] = useState<GolferInfo>();
  const [coursesPlayed, setCoursesPlayed] = useState<Course[]>();
  const [courseWishlist, setCourseWishlist] = useState<Course[]>();
  useEffect(() => {
    async function getGolferInfo() {
      if (golfer) {
        const info = await ScratchApiService.getGolferInformation(
          golfer.Id?.toString()
        );
        setGolferInfo(info);

        if (info && info.Courses) {
          // const played = info.Courses.filter((c) => {
          //   return c.Wishlist === false;
          // });
          // const wishlist = info.Courses.filter((c) => {
          //   return c.Wishlist === true;
          // });
          // setCoursesPlayed(played);
          // setCourseWishlist(wishlist);
        }
      }
    }
    getGolferInfo();
  }, [golfer]);

  const toggleSeeMore = () => {
    const aboutCard = document.getElementById("about-card");
    const collapseButton = document.getElementById("collapse-button");
    aboutCard?.classList.toggle("show");
    if (collapseButton) {
      collapseButton.innerHTML = aboutCard?.classList.contains("show")
        ? "- See Less"
        : "+ See More";
    }
  };

  return (
    <div
      className={`cardDetailsPopup ${show ? "show" : ""}`}
      id="showcarddetailspopup"
    >
      <div className="popupCardDetails">
        <img
          src={CloseButton}
          id="closecarddetailspopup"
          alt="close"
          onClick={() => modalToggle(false)}
          className="close-golfer-info"
        />
        <div className="wrap-golfer-info">
          <div className="popupCardWrapProfilePicture">
            <img
              src={`https://scratchmaker.blob.core.windows.net/profiles/${
                golfer.ImageUrl ? golfer.ImageUrl : golfer.User?.ImageUrl
              }`}
              alt="profilepic"
              onClick={() => modalToggle(false)}
              className="profilePic-golfer-info"
            ></img>
          </div>

          <div className="popBasicCardInfo">
            <div className="popupcardbasicinfo">
              <div className="productDetailCardHeading1">
                <h3>{golfer.UserName ?? golfer.User?.Username}</h3>
                <h3>${golfer.StartingPrice}</h3>
              </div>
              <div className="golferCardstarsDiv">
                <div className="productCardRating">
                  {/* <div className="stars golfer-info-stars">
                    <i className="fa-sharp fa-solid fa-star"></i>
                  </div>
                  <span className="golfer-handicap">{golfer.Rating}</span> */}
                  <span className="golfer-location">{golfer.PositionName}</span>
                </div>
                <div className="productHeading4">
                  <span className="hdcp-lbl">HDCP:</span>
                  <p className="hdcp">{golfer.Handicap}</p>
                </div>
              </div>
              {/* <div className="popCardBasicTags">
                <div className="tag">Long Drive</div>
                <div className="tag">Wedge Pro</div>
                <div className="tag">Chipper</div>
              </div> */}
            </div>
          </div>
          <div className="horizLine"></div>
          <div className="popupCardAbout" id="about-card">
            <h4>About</h4>
            <p>
              {golfer.Description
                ? golfer.Description
                : golfer.User?.Description}
            </p>

            {golferInfo &&
              golferInfo.Courses &&
              golferInfo.Courses.length > 0 && (
                <div className="popupCardTags">
                  {coursesPlayed && coursesPlayed.length > 0 && (
                    <div className="popupCardBigTags">
                      <h4>Courses Played</h4>
                      {coursesPlayed.slice(0, 3).map((course, i) => {
                        return (
                          <div className="BigTag" key={i}>
                            {course.ClubName}
                            {course.Id === golfer.HomeCourseId && (
                              <i className="fa-solid fa-house-chimney"></i>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {courseWishlist && courseWishlist.length > 0 && (
                    <>
                      <div className="hrLine"></div>
                      <div className="popupCardBigTags">
                        <h4>Course Wishlist</h4>
                        {courseWishlist.map((course, i) => {
                          if (i < 3) {
                            return (
                              <div className="BigTag" key={i}>
                                {course.ClubName}
                                {course.Id === golfer.HomeCourseId && (
                                  <i className="fa-solid fa-house-chimney"></i>
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </>
                  )}
                </div>
              )}
          </div>
        </div>
        <div className="cover"></div>
        <button
          className="collapse-button"
          onClick={toggleSeeMore}
          id="collapse-button"
        >
          + See More
        </button>
      </div>
    </div>
  );
};

export default GolferInfoModal;
