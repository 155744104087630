import { FunctionComponent } from "react";
import styles from "./Highlights.module.css";
import { NavLink } from "react-router-dom";

const Highlights: FunctionComponent = () => {
  return (
    <div className={styles.featuresSection}>
      <div className={`${styles.container} content-div`}>
        <div className={styles.content}>
          <div className={styles.featureText}>
            <div className={styles.wrapHighlightImage}>
              <img
                className={styles.featureTextChild}
                alt=""
                src="/listing-types/casual_icon.svg"
              />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>Casual Play Finder</div>
              <div className={styles.supportingText}>
                Need to fill a tee time, or want to curate your foursome?
                Quickly find local golfers to play casual golf with.
              </div>
              <NavLink to="/listings/Casual" className={styles.button}>
                <div className={styles.text1}>Find Tee Times</div>
                <img
                  className={styles.arrowRightIcon}
                  alt=""
                  src="/arrowright.svg"
                />
              </NavLink>
            </div>
          </div>
          <div className={styles.featureText}>
            <div className={styles.wrapHighlightImage}>
              <img
                className={styles.featureTextChild}
                alt=""
                src="/listing-types/tournament_icon.svg"
              />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>Compete.</div>
              <div className={styles.supportingText}>
                Participate in various competitive events with as little as 4
                players.
              </div>
              <NavLink to="/listings/Competitive" className={styles.button}>
                <div className={styles.text1}>Browse Competitive Events</div>
                <img
                  className={styles.arrowRightIcon}
                  alt=""
                  src="/arrowright.svg"
                />
              </NavLink>
            </div>
          </div>
          {/* TODO: SHOW WHEN WE HAVE LESSONS */}
          {/* <div className={styles.featureText}>
            <img
              className={styles.featureTextChild}
              alt=""
              src="/listing-types/lessons_icon.svg"
            />
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>Lessons: Cheaper and Easier</div>
              <div className={styles.supportingText}>
                Hire a local Scratchmaker, a qualified lesson giver who can push
                you one step closer to scratch.
              </div>
            </div>
            <div className={styles.button}>
              <div className={styles.text1}>Find a Scratchmaker</div>
              <img
                className={styles.arrowRightIcon}
                alt=""
                src="/arrowright.svg"
              />
            </div>
          </div> */}

          <div className={styles.featureText}>
            <div className={styles.wrapHighlightImage}>
              <img
                className={styles.featureTextChild}
                alt=""
                src="/listing-types/Scratchmaker_Strikethrough.png"
              />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>Become a Scratchmaker</div>
              <div className={styles.supportingText}>
                Want to get paid to play golf? Become a Scratchmaker and put
                your skills to the test.
              </div>
              <div className={styles.button}>
                <div className={styles.text1}>COMING SOON</div>
                {/* <img
                  className={styles.arrowRightIcon}
                  alt=""
                  src="/arrowright.svg"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlights;
