import { FunctionComponent } from "react";
import Footer from "./Footer";
import styles from "./FooterSection.module.css";
import { NavLink } from "react-router-dom";
import LoginModal from "../auth/LoginModal";
import CreateListingModal from "../listings/create-listing-modal/CreateListingModal";
import useLoginModal from "../../../hooks/useLoginModal";
import useCreateListingModal from "../../../hooks/useCreateListingModal";
import useAuth from "../../../hooks/useAuth";
import useProcessUserModal from "../../../hooks/useProcessUserModal";
import ProcessUserModal from "../auth/process-user/ProcessUserModal";

const FooterSection: FunctionComponent = () => {
  const [user, , clearUser] = useAuth();
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const { isCreateListingOpen, toggleCreateListing } = useCreateListingModal();
  const { isProcessUserOpen, toggleProcessUser } = useProcessUserModal();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.logoAndSupportingText}>
            <img className={styles.logoIcon} alt="" src="/logo1.svg" />
            <div className={styles.supportingText}>
              The first ever golf community app that connects golfers for
              competitions and casual games!
            </div>
          </div>
          <div className={styles.links}>
            <div className={styles.footerLinksColumn}>
              <div className={styles.heading}>Product</div>
              <div className={styles.footerLinks}>
                <div className={styles.footerLink}>
                  <NavLink to="/listings/All" className={styles.button}>
                    <div className={styles.text}>Listings</div>
                  </NavLink>
                </div>
                <div className={styles.footerLink}>
                  <NavLink to="/how" className={styles.button}>
                    <div className={styles.text}>How It Works</div>
                  </NavLink>
                </div>
                <div className={styles.footerLink2}>
                  <NavLink to="#" className={styles.button}>
                    <div className={styles.text}>Lessons</div>
                  </NavLink>
                  <div className={styles.badge}>
                    <div className={styles.text3}>Coming Soon</div>
                  </div>
                </div>
                <div className={styles.footerLink}>
                  <div
                    className={styles.button}
                    onClick={
                      user
                        ? user.Location
                          ? toggleCreateListing
                          : toggleProcessUser
                        : toggleLogin
                    }
                  >
                    <div className={styles.text}>Host a Round</div>
                  </div>
                </div>
              </div>
            </div>

            <Footer title="Company" />
            {/* <Footer title="Resources" />
          <Footer title="Social" />
          <Footer title="Legal" /> */}
          </div>
        </div>
      </div>
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
      <CreateListingModal
        isOpen={isCreateListingOpen}
        toggle={toggleCreateListing}
      />
      <ProcessUserModal isOpen={isProcessUserOpen} toggle={toggleProcessUser} />
    </>
  );
};

export default FooterSection;
