import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import AzureBlobService from "../../lib/services/blob-service";

interface LicenseUploadProps {
  stateCB: (imageUrl: string) => void;
}
const LicenseUpload: React.FC<LicenseUploadProps> = ({ stateCB }) => {
  const [user] = useAuth();
  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>("");

  const handleImageChange = async (e: any) => {
    if (e.target && e.target.files && e.target.files[0]) {
      const fileSelected = e.target.files[0];
      // *** UPLOAD TO AZURE STORAGE ***
      await AzureBlobService.addIdentity(fileSelected, user);

      setImage(fileSelected);
      setImageUrl(
        `https://scratchmaker.blob.core.windows.net/identity/${user?.Username}_${fileSelected.name}`
      );
      stateCB(
        `https://scratchmaker.blob.core.windows.net/identity/${user?.Username}_${fileSelected.name}`
      );
    }
  };

  return (
    <div className="wrap-license-upload">
      <input
        type="file"
        accept="image/*"
        capture="environment" // or "user" for front camera
        onChange={handleImageChange}
      />
      {imageUrl && <img src={imageUrl} alt="Preview" />}
    </div>
  );
};

export default LicenseUpload;
