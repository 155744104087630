import { ChangeEvent, useState } from "react";
import styles from "./CreateCasual.module.css";
import ScratchApiService from "../../../../lib/services/scratch-api-service";
import { Course } from "../../../../lib/models/course";
import {
  CreateListingRequest,
  Listing,
  ListingTypeEnum,
} from "../../../../lib/models/listing";
import useAuth from "../../../../hooks/useAuth";
import {
  charLeft,
  convertStateToAbbreviation,
} from "../../../../lib/helpers/texthelper";
import { isNull } from "util";
interface CreateCasualProps {
  handleCompleteClick: (listing: Listing) => void;
  onClose: () => void;
}

interface CasualData {
  casualTitle: string;
  golfCourse: Course | null;
  casualDate: string;
  startingTime: string;
  casualFormat: string;
  description: string;
  buyInPrice: number;
  prizeValue: number;
  slotsAvailable: number;
  wagerActive: boolean;
  wagerFormat: string;
  wagerAmount: number;
  greenFees: number;
  casualGraphic: File | null;
}

const CreateCasual: React.FC<CreateCasualProps> = ({
  onClose,
  handleCompleteClick,
}) => {
  const [casualData, setCasualData] = useState<CasualData>({
    casualTitle: "",
    golfCourse: null,
    casualDate: "",
    startingTime: "",
    casualFormat: "",
    description: "",
    buyInPrice: 0,
    prizeValue: 0,
    slotsAvailable: 0,
    greenFees: 0,
    casualGraphic: null,
    wagerActive: false,
    wagerFormat: "",
    wagerAmount: 0,
  });

  const [user] = useAuth();
  const [searchResults, setSearchResults] = useState<Course[]>([]);
  const [courseSelected, setCourseSelected] = useState<boolean>();
  const [searchValue, setSearchValue] = useState("");
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [greenFees, setGreenFees] = useState(false);

  const handleInputChange = (fieldName: keyof CasualData, value: any) => {
    setCasualData((prevData) => {
      const newData = {
        ...prevData,
        [fieldName]: value,
      };

      return newData;
    });
  };

  const checkInputs = (data: CasualData) => {
    const errors: Record<string, boolean> = {};

    if (data.casualTitle === null || data.casualTitle === "") {
      errors.casualTitle = true;
    }
    if (data.golfCourse === null) {
      errors.golfCourse = true;
    }
    if (data.casualDate === null || data.casualDate === "") {
      errors.casualDate = true;
    }
    if (data.startingTime === null || data.startingTime === "") {
      errors.startingTime = true;
    }
    if (data.description === null || data.description === "") {
      errors.description = true;
    }
    if (!greenFees && (data.greenFees === null || data.greenFees === 0)) {
      errors.greenFees = true;
    }
    if (
      data.wagerActive &&
      (data.wagerFormat === null || data.wagerFormat === "")
    ) {
      errors.wagerFormat = true;
    }
    if (
      data.wagerActive &&
      (data.wagerAmount === null || data.wagerAmount === 0)
    ) {
      errors.wagerAmount = true;
    }
    if (data.slotsAvailable === null || data.slotsAvailable === 0) {
      errors.slotsAvailable = true;
    }
    setErrors(errors);
    return Object.keys(errors).length !== 0;
  };

  const handleCreate = async () => {
    if (!user) return;

    const hasErrors = checkInputs(casualData);
    if (hasErrors) {
      return;
    }
    if (!casualData.golfCourse) return;

    // call api service
    const request: CreateListingRequest = {
      listingType: ListingTypeEnum.Casual,
      userId: user.Id,
      title: casualData.casualTitle,
      format: null,
      courseId: casualData.golfCourse.Id,
      description: casualData.description,
      picture: null,
      eventDate: casualData.casualDate,
      eventTime: casualData.startingTime,
      //TODO: Green Fees toggle?
      // price: greenFees ? casualData.greenFees : null,
      price: casualData.greenFees,
      prize: null,
      teamSize: null,
      //TODO: Green Fees toggle?
      // greenFeesIncluded: greenFees,
      wagerFormat: casualData.wagerActive ? casualData.wagerFormat : null,
      wagerAmount: casualData.wagerActive ? casualData.wagerAmount : null,
      greenFeesIncluded: false,
      featuredFlag: false,
      handicapLimit: false,
      handicapLimitValue: null,
      totalSlots: casualData.slotsAvailable,
    };

    const listing = await ScratchApiService.createListing(request);

    // next
    handleCompleteClick(listing);
  };

  const handleGreenFeesToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setGreenFees(event.target.checked);
  };

  const handleWagerToggle = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChange("wagerActive", event.target.checked);
  };

  const handleCourseSearch = (searchText: string) => {
    setSearchValue(searchText);
    setCourseSelected(false);

    if (searchText !== "") {
      ScratchApiService.getCourses(searchText).then(setSearchResults);
    }
  };

  const handleCourseSearchClick = (result: Course) => {
    setCourseSelected(true);
    handleInputChange("golfCourse", result);
  };

  return (
    <>
      <div className={styles.casualModal}>
        <div className={styles.modalHeader}>
          <div className={styles.content}>
            <div className={styles.event}>
              <img
                className={styles.trophyIcon}
                alt=""
                src="/listing-types/casual_icon.svg"
              />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>Build your Casual Play Listing</div>
              <div className={styles.supportingText}>
                Please fill in all the details below.
              </div>
            </div>
          </div>
          <div className={styles.buttonCloseX} onClick={onClose}>
            <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
          </div>
          <span className={styles.dividerIcon}></span>
        </div>
        <div className={styles.content1}>
          <div className={styles.form}>
            <div className={styles.row}>
              <div className={styles.text1}>Title</div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input}>
                    <div className={styles.content2}>
                      <input
                        type="text"
                        className={styles.inputText}
                        placeholder="Enter Casual Title"
                        onChange={(e) =>
                          handleInputChange("casualTitle", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.casualTitle && (
                    <div className={styles.errorText}>
                      Please enter a title.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Golf Course</div>
              <div className={styles.inputWithLabel}>
                <div className={styles.courseSearch}>
                  <div className={styles.content3}>
                    <img
                      className={styles.searchLgIcon}
                      alt=""
                      src="/searchlg.svg"
                    />
                    <input
                      type="text"
                      className={styles.inputText}
                      placeholder="Search Course"
                      value={
                        courseSelected
                          ? casualData.golfCourse
                            ? casualData.golfCourse.ClubName
                            : searchValue
                          : searchValue
                      }
                      onChange={(e) => handleCourseSearch(e.target.value)}
                    />
                  </div>
                  {!courseSelected && searchResults.length > 0 && (
                    <div className={styles.searchResults}>
                      {searchResults.map((result) => (
                        <div
                          className={styles.searchResultItem}
                          key={result.Id}
                          onClick={() => handleCourseSearchClick(result)}
                        >
                          <img
                            className={styles.markerPin02Icon}
                            alt=""
                            src="/markerpin02.svg"
                          />
                          {result.ClubName} (
                          {`${result.City}, ${convertStateToAbbreviation(
                            result.State
                          )}`}
                          )
                        </div>
                      ))}
                      {/* TODO: Add the manual entry */}
                      {/* <div className={styles.manualAlert} key="manualListing">
                        Don't see the course your looking for?{" "}
                        <span> Enter details manually</span>
                      </div> */}
                    </div>
                  )}
                </div>
                {errors.golfCourse && (
                  <div className={styles.errorText}>
                    Please search for a golf course.
                  </div>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Date</div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/calendar.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="date"
                        className={styles.inputText}
                        placeholder="Select Date"
                        min={new Date().toISOString().split("T")[0]}
                        onChange={(e) =>
                          handleInputChange("casualDate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.casualDate && (
                    <div className={styles.errorText}>
                      Please choose a date.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Time</div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/alarmclock.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="time"
                        className={styles.inputText}
                        placeholder="Start Time"
                        onChange={(e) =>
                          handleInputChange("startingTime", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.startingTime && (
                    <div className={styles.errorText}>
                      Please choose a time.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.text1}>Description</div>
              <div className={styles.textareaInputField}>
                <div className={styles.inputWithLabel3}>
                  <div className={styles.label}>Description*</div>
                  <div className={styles.input4}>
                    <textarea
                      className={styles.textarea}
                      placeholder="Write a few sentences about the casual..."
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
                <div className={styles.hintText}>
                  {charLeft(casualData.description, 1000)} characters left
                </div>
                {errors.description && (
                  <div className={styles.errorText}>
                    Please enter a description.
                  </div>
                )}
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            {/* TODO: add green fees toggle?? */}
            {/* <div className={styles.row}>
              <div className={styles.text1}>Are Green Fees Paid?</div>
              <div className={styles.toggle}>
                <div className={styles.toggleBase}>
                  {" "}
                  <input
                    type="checkbox"
                    id="greenFeesToggle"
                    onChange={handleGreenFeesToggle} // Replace with your toggle change handler function
                  />
                  <label htmlFor="greenFeesToggle"></label>
                </div>
                <div className={styles.textAndSupportingText}>
                  <div className={styles.text1}>Fee already paid</div>
                </div>
              </div>
            </div> */}

            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>Green Fees</div>
                <div className={styles.subtitle}>
                  What should golfers expect to pay?
                </div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/currencydollar.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        placeholder="$50.00"
                        onChange={(e) =>
                          handleInputChange("greenFees", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.subText}>
                    Green fees and/or any additional costs.
                  </div>
                  {errors.greenFees && (
                    <div className={styles.errorText}>
                      Please enter the green fees amount.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />

            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text1}>How Many Players?</div>
                <div className={styles.subtitle}>Including yourself</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/users01.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        placeholder="Enter Total Number of Players"
                        onChange={(e) =>
                          handleInputChange("slotsAvailable", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.slotsAvailable && (
                    <div className={styles.errorText}>
                      Please set the number of slots available.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
          </div>
        </div>
        <div className={styles.modalActions}>
          <div className={`${styles.dividerWrap} ${styles.dividerActions}`}>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
          </div>
          <div className={styles.content4}>
            <div className={styles.cancelButton} onClick={onClose}>
              <div className={styles.text14}>Cancel</div>
            </div>
            <div className={`${styles.nextButton} `} onClick={handleCreate}>
              <div className={styles.text14}>Create</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCasual;
