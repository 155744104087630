import Moment from "react-moment";
import { Listing } from "../../../../lib/models/listing";
import styles from "./EventsTable.module.css";
import { useMemo, useState } from "react";

interface EventRowProps {
  listing: Listing;
  selectedListing: Listing | null | undefined;
  onClick: (listing: Listing) => void;
}

const EventRow: React.FC<EventRowProps> = ({
  listing,
  selectedListing,
  onClick,
}) => {
  const [percentage, setPercentage] = useState<number>();

  useMemo(() => {
    if (listing && listing.Reservations != null && listing.TotalSlots != null) {
      const perc = (100 * listing.Reservations.length) / listing.TotalSlots;
      setPercentage(Math.round(perc));
    }
  }, [listing]);

  const handleViewListing = () => {
    const url = `/listings/All/${listing.Id}`;
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    }
  };

  return (
    <tr
      className={`${styles.eventRow} ${
        selectedListing?.Id === listing.Id ? styles.selected : ""
      }`}
    >
      <td className={styles.column1}>
        <div className={styles.eventTitle}>
          <img className={styles.avatarIcon} alt="" src="/avatar10@2x.png" />
          <div className={styles.percentage}>{listing.Title}</div>
        </div>
      </td>
      <td className={styles.toggle}>
        <div className={styles.eventInfoCell}>
          <div className={styles.text8}>{listing.ListingType.Type}</div>
        </div>
      </td>
      <td className={styles.toggle}>
        <div className={styles.eventInfoCell}>
          <div className={styles.text8}>
            <Moment format="LLL">{listing.EventDate}</Moment>
          </div>
        </div>
      </td>
      <td className={styles.column4}>
        <div className={styles.eventInfoCell}>
          <div className={styles.progressBar}>
            <div className={styles.progressBar1}>
              <div className={styles.background} />
              {listing.Reservations != null &&
                listing.TotalSlots != null &&
                percentage != null && (
                  <div
                    className={styles.progress}
                    style={{
                      width: `${
                        listing.Reservations.length > 0
                          ? (listing.Reservations.length / listing.TotalSlots) *
                            100
                          : 10
                      }px`,
                    }}
                  />
                )}
            </div>
            <div className={styles.percentage}>{percentage}%</div>
          </div>
        </div>
      </td>
      <td className={styles.column5}>
        <div className={styles.eventInfoCell}>
          <div className={styles.button1} onClick={handleViewListing}>
            <img
              className={styles.messageTextSquare01Icon}
              alt=""
              src="/eye.svg"
            />
          </div>
          <div className={styles.button1} onClick={() => onClick(listing)}>
            <img
              className={styles.messageTextSquare01Icon}
              alt=""
              src="/edit.svg"
            />
          </div>
          {/* TODO: Support Deleting */}
          {/* <div className={styles.button1}>
            <img
              className={styles.messageTextSquare01Icon}
              alt=""
              src="/trash01.svg"
            />
          </div> */}
        </div>
      </td>
    </tr>
  );
};
export default EventRow;
