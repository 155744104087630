import { useEffect } from "react";
import styles from "./ListingModal.module.css";
import { Listing } from "../../../lib/models/listing";

interface ListingLocationMapProps {
  listing: Listing;
}

const ListingLocationMap: React.FC<ListingLocationMapProps> = ({ listing }) => {
  useEffect(() => {
    const mapElement = document.getElementById("map");
    if (mapElement) {
      const map = new google.maps.Map(mapElement, {
        center: { lat: listing.Course.Latitude, lng: listing.Course.Longitude },
        zoom: 12,
      });

      const marker = new google.maps.Marker({
        position: {
          lat: listing.Course.Latitude,
          lng: listing.Course.Longitude,
        },
        map: map,
        title: listing.Course.ClubName,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: "<div><h1>San Francisco</h1><p>Population: 883,305</p></div>",
      });

      marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });
    }
  }, [
    listing.Course.ClubName,
    listing.Course.Latitude,
    listing.Course.Longitude,
  ]);

  const handleGetDirections = () => {
    const latitude = listing.Course.Latitude;
    const longitude = listing.Course.Longitude;
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;

    window.open(url);
  };
  return (
    <div className={styles.wrapMap}>
      <div id="map" className={styles.listingMap}></div>
      <div className={styles.locationCard}>
        <div className={styles.content52}>
          <div className={styles.headingAndIcon}>
            <div className={styles.textAndSupportingText43}>
              <div className={styles.heading4}>{listing.Course.ClubName}</div>
              <div className={styles.supportingText46}>
                <p className={styles.someStreetName}>
                  {listing.Course.Address}
                </p>
                <p
                  className={styles.someStreetName}
                >{`${listing.Course.City}, ${listing.Course.State} ${listing.Course.PostalCode}`}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionFooter}>
          <img className={styles.dividerIcon} alt="" src="/divider.svg" />
          <div className={styles.content53}>
            <div className={styles.actions2}>
              <div className={styles.button44} onClick={handleGetDirections}>
                <div className={styles.getDirections}>Get Directions</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingLocationMap;
