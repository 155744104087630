import { FunctionComponent } from "react";
import styles from "./Showcase2.module.css";
import { NavLink } from "react-router-dom";

const Showcase2: FunctionComponent = () => {
  return (
    <div className={styles.chooseAndCreate}>
      <div className="content-div">
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.text}>
              <p className={styles.playWithOther}>Golf Local.</p>
              <p className={styles.playWithOther}>Golf For Fun.</p>
              <p className={styles.playWithOther}>Golf More.</p>
            </div>
            <div className={styles.supportingText}>
              Find opportunities for casual play with local players, whether you
              just want to have fun, or want to golf with similarly skilled
              players.
            </div>
            <NavLink to="/listings/Casual" className={styles.button}>
              <div className={styles.text1}>Find Local Golfers</div>
              <img
                className={styles.arrowNarrowRightIcon}
                alt=""
                src="/arrowrightwhite.svg"
              />
            </NavLink>
          </div>

          <div className={`${styles.indexCardWrapper} `}>
            <div className={`${styles.redCard} ${styles.rotationNeg15}`}>
              <img
                src="/Frame-47882.png"
                loading="lazy"
                width="258"
                height="197"
                alt=""
                className={`${styles.cardImageContainer}`}
              />
              <div className={`${styles.bottomContainer}`}>
                <div className={`${styles.topContent}`}>
                  <div className={`${styles.contentContainer}`}>
                    <div className={`${styles.userDetails}`}>
                      <div className={`${styles.scratchCardUserDetails}`}>
                        Competitive Player{" "}
                      </div>
                      <div
                        className={`${styles.scratchCardUserDetails} ${styles.bold}`}
                      >
                        Long Bomber
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.divider}`}>
                    <div
                      className={`${styles.cardDivider} ${styles.white}`}
                    ></div>
                  </div>
                </div>
                <div className={`${styles.bottomContent}`}>
                  <div className={`${styles.avatarContainer}`}>
                    <div className={`${styles.avatar}`}>
                      <img
                        src="/avatar1@3x.png"
                        loading="lazy"
                        width="36"
                        height="36"
                        alt=""
                        className={`${styles.avatar}`}
                      />
                    </div>
                    <div className={`${styles.textContainer}`}>
                      <div className={`${styles.scratchCardUserDetails}`}>
                        Player
                      </div>
                      <div
                        className={`${styles.scratchCardUserDetails} ${styles.bold}`}
                      >
                        Jeff Sweeney
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.blueCard} ${styles.rotation15}`}>
              <img
                src="/image-container.png"
                loading="lazy"
                width="258"
                height="197"
                srcSet="/image-container.png 500w, /image-container.png 517w"
                sizes="(max-width: 479px) 326.2762145996094px, (max-width: 991px) 326.27618408203125px, 33vw"
                alt=""
                className={`${styles.cardImageContainer}`}
              />
              <div className={`${styles.bottomContainer}`}>
                <div className={`${styles.topContent}`}>
                  <div className={`${styles.contentContainer}`}>
                    <div className={`${styles.userDetails}`}>
                      <div className={`${styles.scratchCardUserDetails}`}>
                        PGA Professional{" "}
                      </div>
                      <div
                        className={`${styles.scratchCardUserDetails} ${styles.bold}`}
                      >
                        Instructor
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.divider}`}>
                    <div className={`${styles.cardDivider}`}></div>
                  </div>
                </div>
                <div className={`${styles.bottomContent}`}>
                  <div className={`${styles.avatarContainer}`}>
                    <div className={`${styles.avatar}`}>
                      <img
                        src="/ellipse-221@2x.png"
                        loading="lazy"
                        width="36"
                        height="36"
                        alt=""
                        className={`${styles.avatar}`}
                      />
                    </div>
                    <div className={`${styles.textContainer}`}>
                      <div className={`${styles.scratchCardUserDetails}`}>
                        Scratchmaker
                      </div>
                      <div
                        className={`${styles.scratchCardUserDetails} ${styles.bold}`}
                      >
                        Mark Deleway
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcase2;
