import win from "../../Assets/Images/win.png";
import learn from "../../Assets/Images/learn.png";
import grow from "../../Assets/Images/grow.png";

const HowItWorks = () => {
  return (
    <section className="HowItWorks" id="HowItWorks">
      <div className="container">
        <div className="anchor" id="howitworks"></div>
        <div className="HowItWorksInner">
          <div className="box">
            <img alt="win" src={win}></img>
            <h2>Win</h2>
            <p>Hire a Scratchmaker and start winning local outings </p>
          </div>
          <div className="box">
            <img alt="learn" src={learn}></img>
            <h2>Learn</h2>
            <p>Play a round with a top golfer who can give you a tip or two</p>
          </div>
          <div className="box">
            <img alt="grow" src={grow}></img>
            <h2>Improve</h2>
            <p>Enable athletes to connect with regional business leaders</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
