import { useMemo, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import styles from "./ChatRoomCard.module.css";
import { Message, Room } from "../../lib/models/chat";
import { User } from "../../lib/models";
import useAuth from "../../hooks/useAuth";

interface ChatRoomCardProps {
  room: Room;
  unreadCount: number;
}

const ChatRoomCard: React.FC<ChatRoomCardProps> = ({ room, unreadCount }) => {
  const [recipient, setRecipient] = useState<User>();
  const [messagesDescending, setMessagesDescending] = useState<Message[]>();
  const [groupRecipients, setGroupRecipients] = useState<User[]>();
  const [user] = useAuth();

  useMemo(() => {
    if (user && room) {
      const roomParticipants = room.MessageRoomParticipants;
      if (!room.IsGroup) {
        // recipient is roomParticipant where roomParticipant.User.Id !== user.Id
        const recipientParticipant = roomParticipants.find(
          (participant) => participant.User.Id !== user.Id
        );

        if (recipientParticipant) {
          setRecipient(recipientParticipant.User);
        }
      } else {
        setGroupRecipients(
          roomParticipants.map((rp) => {
            return rp.User;
          })
        );
      }
    }
  }, [room, user]);

  const timeAgo = useMemo(() => {
    if (room.Messages.length > 0) {
      // I'm assuming here that TimeSent is a property of Message that can be parsed into a Date.
      // You may need to adjust this according to the actual structure of your data.
      const sortedByTime = [...room.Messages].sort(
        (a, b) =>
          new Date(b.TimeSent).getTime() - new Date(a.TimeSent).getTime()
      );
      setMessagesDescending(sortedByTime);
      const lastMessageTime = new Date(sortedByTime[0].TimeSent);
      return formatDistanceToNow(lastMessageTime) + " ago";
    }
    return "";
  }, [room]);

  return (
    <div className={styles.message}>
      <div className={styles.avatarAndTime}>
        {room.IsGroup ? (
          <div className={styles.avatarLabelGroup}>
            <div className={styles.avatarGroupChat}>
              <img src="/group-chat.svg" alt="recip" />
            </div>
            <div className={styles.groupTitle}>{room.Title} Golfers</div>
          </div>
        ) : (
          <div className={styles.avatarAndDot}>
            <div className={styles.avatarLabelGroup}>
              <div className={styles.avatar}>
                <img src={recipient?.ImageUrl} alt="recip" />
                {/* <div className={styles.avatarOnlineIndicator} /> */}
              </div>
              <div className={styles.textAndSupportingText}>
                <div className={styles.text}>{recipient?.Name}</div>
                <div className={styles.supportingText}>
                  @{recipient?.Username}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.wrapTimeUnread}>
          <div className={styles.supportingText}>{timeAgo}</div>
          {unreadCount > 0 && (
            <div className={styles.unread}>{unreadCount}</div>
          )}
        </div>
      </div>
      <div className={styles.messagePreviewWrap}>
        <div className={styles.messagePreview}>
          {messagesDescending && messagesDescending.length > 0
            ? messagesDescending[0].Message.length > 30
              ? messagesDescending[0].Message.substring(0, 30) + "..."
              : messagesDescending[0].Message
            : `Start a conversation`}
        </div>
      </div>
    </div>
  );
};

export default ChatRoomCard;
