import FiltersBar from "./FiltersBar";
import EventsTable from "./EventsTable";
import styles from "./ManagerSection.module.css";
import useAuth from "../../../../hooks/useAuth";
import { useEffect, useState } from "react";
import ScratchApiService from "../../../../lib/services/scratch-api-service";
import { Listing } from "../../../../lib/models/listing";
import EditListing from "./EditListing";

const ManagerSection: React.FC = () => {
  const [user] = useAuth();
  const [listings, setListings] = useState<Listing[]>();
  const [selectedListing, setSelectedListing] = useState<Listing>();

  useEffect(() => {
    const getListings = async () => {
      if (user) {
        ScratchApiService.getListingsByHostId(user.Id).then(setListings);
      }
    };
    if (user) {
      getListings();
    }
  }, [user]);
  const handleListingUpdate = (updatedListing: Listing) => {
    if (listings) {
      const updatedListings = listings.map((listing) => {
        if (listing.Id === updatedListing.Id) {
          return updatedListing;
        }
        return listing;
      });
      setListings(updatedListings);
    }
  };
  return (
    <div className={styles.form}>
      {listings && listings.length > 0 ? (
        <div className={styles.table}>
          <div className={styles.cardHeader}>
            <div className={styles.content}>
              <div className={styles.textAndSupportingText}>
                <div className={styles.text}>Events Manager</div>
                <div className={styles.supportingText}>
                  Create and Manage the listings you create.
                </div>
              </div>
            </div>
            <img className={styles.dividerIcon} alt="" src="/divider11.svg" />
          </div>
          <div className={styles.divider}></div>
          {/* <FiltersBar /> */}
          <EventsTable listings={listings} onClick={setSelectedListing} />
        </div>
      ) : (
        <div className={styles.noListings}>
          <img src="/warning.svg" alt="warning" className={styles.warning} />
          You are not currently hosting any listings. Click the button above to
          host your first listing. It's easy!
        </div>
      )}
      {selectedListing && (
        <EditListing listing={selectedListing} onSave={handleListingUpdate} />
      )}
    </div>
  );
};

export default ManagerSection;
