import { FunctionComponent } from "react";
import styles from "./BottomFooter.module.css";

const BottomFooter: FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.footerText}>
          © 2023 Scratchmaker LLC. All rights reserved.
        </div>
        <div className={styles.socialIcons}>
          <a
            href="https://twitter.com/Scratchmakerapp"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.socialIcon} alt="" src="/social-icon.svg" />
          </a>
          <a
            href="https://www.instagram.com/scratchmakergolf/"
            target="_blank"
            rel="noreferrer"
          >
            {/* TODO FILL THIS ICON WITH INSTAGRAM */}
            <img className={styles.socialIcon} alt="" src="/insta-100.png" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100089613042497"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.socialIcon} alt="" src="/social-icon2.svg" />
          </a>
          <a
            href="https://twitter.com/Scratchmakerapp"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.socialIcon} alt="" src="/social-icon1.svg" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BottomFooter;
