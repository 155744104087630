import React, { useEffect, useState } from "react";
import ArrowRight from "../../Assets/Images/buttonArrowWhite.png";
import "react-datepicker/dist/react-datepicker.css";
import { Golfer } from "../../lib/models/golfer";
import LocationSearch from "./LocationSearch";
import Swal from "sweetalert2";
import {
  StripePaymentIntentResponse,
  StripeRequest,
} from "../../lib/models/stripeRequest";
import useAuth from "../../hooks/useAuth";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckout from "./StripeCheckout";
import CheckoutService from "../../lib/services/checkout-service";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { Dayjs } from "dayjs";
import Moment from "react-moment";
import { Button, Modal } from "react-bootstrap";

interface CheckoutSectionProps {
  golfer: Golfer;
}

let stripeKey =
  "pk_test_51MdEF1AHo7uo4ZdsyubFZxFLg4C5ULTdSl9XC6yGabbc6laLlv0D8iAKqMVetuubGUrjdk5RtB7Chl9G5ma01v4b00m6acjaGp";
const stripePromise = loadStripe(stripeKey);

const CheckoutSection: React.FC<CheckoutSectionProps> = ({ golfer }) => {
  const [user] = useAuth();
  const [matchTime, setMatchTime] = useState<Dayjs | null>(null);
  const [txLocation, setTxLocation] =
    useState<google.maps.places.AutocompletePrediction>();
  const [fee, setFee] = useState<number>();
  const [timeSet, setTimeSet] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    if (!termsAccepted) {
      setShowModal(true);
    } else {
      setTermsAccepted(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleAcceptTerms = () => {
    setTermsAccepted(true);
    setShowModal(false);
    console.log(`terms:${termsAccepted}`);
  };

  const handleContinue1 = () => {
    if (matchTime && txLocation && matchTime.isValid() && termsAccepted) {
      setShow1(false);
      setShow2(true);
      setStep(2);
    } else {
      if (!matchTime || !matchTime.isValid()) {
        Swal.fire({
          icon: "warning",
          title: "Missing Input",
          text: "Must select enter a proper time for your session.",
        });
        return false;
      } else if (!txLocation) {
        Swal.fire({
          icon: "warning",
          title: "Missing Input",
          text: "Must select a location for your session.",
        });
        return false;
      } else if (!termsAccepted) {
        Swal.fire({
          icon: "warning",
          title: "Must Accept Terms",
          text: "You must accept Scratchmaker's terms and services agreement to proceed.",
        });
      }
    }
  };

  const [step, setStep] = useState(1);
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [options, setOptions] = useState<StripePaymentIntentResponse>();

  useEffect(() => {
    if (golfer && golfer.StartingPrice) {
      setFee(golfer.StartingPrice > 100 ? golfer.StartingPrice * 0.1 : 10);
    }
  }, [golfer]);

  useEffect(() => {
    async function createPaymentIntent(stripeItem: StripeRequest) {
      let res = await CheckoutService.createPaymentIntent(stripeItem);
      return res;
    }
    if (fee && golfer && matchTime && txLocation && user && timeSet && show2) {
      // validate matchtime
      const stripeRequest: StripeRequest = {
        golfer: golfer,
        user: user,
        service: `Scratchmaker Event at ${txLocation} on ${matchTime}`,
        StartingPrice: golfer.StartingPrice ?? 0,
        fee: fee,
        txLocation: txLocation,
        matchTime: matchTime.toISOString(),
      };
      createPaymentIntent(stripeRequest).then(setOptions);
    }
  }, [fee, golfer, matchTime, timeSet, txLocation, user, show2]);

  return (
    <>
      <div className="checkOutInfoSection" id="b">
        <div className="head">
          <h1>Checkout</h1>
        </div>
        <div className="checkoutSection">
          <div className="checkOutInfo">
            <div className="acccord-title" id="secondStep">
              <div className="personalHead">
                <h3>1. Scratchmaker Session Information</h3>
                {step > 1 && (
                  <b>
                    <span
                      className="changeStep"
                      onClick={() => {
                        setShow1(true);
                        setShow2(false);
                        setStep(1);
                      }}
                    >
                      change
                    </span>
                  </b>
                )}
              </div>
              {step > 1 && (
                <div className="hideInfo match-information-summary">
                  <p>
                    <b>Location:</b>{" "}
                    {txLocation?.structured_formatting.main_text}
                  </p>
                  <p>
                    <b>Date:</b>{" "}
                    <Moment format="LLL">{matchTime?.toISOString()}</Moment>
                  </p>
                  <p>
                    <b>Rate:</b> ${golfer.StartingPrice}
                  </p>
                </div>
              )}
            </div>
            {show1 && (
              <div className="tabDetails">
                <div className="searchDiv">
                  <h3>Location</h3>
                  <LocationSearch
                    handleSelectLocation={setTxLocation}
                    curValue={txLocation}
                  />
                </div>
                <div className="wrap-match-date">
                  <h3>Session Time</h3>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      className="dt-picker"
                      label="Session Time"
                      value={matchTime}
                      disablePast
                      onChange={(newValue) => {
                        setMatchTime(newValue);
                      }}
                      onClose={() => setTimeSet(true)}
                    />
                  </LocalizationProvider>
                </div>
                <div className="wrap-terms-acceptance">
                  <div className="cb-div">
                    <input
                      type="checkbox"
                      id="cbTerms"
                      onChange={handleShowModal}
                      checked={termsAccepted}
                    ></input>
                  </div>
                  <div className="terms-label" onClick={handleShowModal}>
                    I have read and agree to the Scratchmaker Terms and Services
                    Agreement
                  </div>
                </div>
                <div className="greenBtn continue" onClick={handleContinue1}>
                  Continue
                  <img src={ArrowRight} alt="arrow-right" />
                </div>
              </div>
            )}
          </div>

          <div className="checkOutInfo " id="fourthStepMain">
            <div className="acccord-title" id="fourthStep">
              <div className="personalHead">
                <h3>2. Checkout Summary</h3>
              </div>
            </div>
            <div className="tabDetails">
              <div className="personalHead line-item">
                <p>
                  Scratchmaker Session
                  {txLocation?.structured_formatting.main_text
                    ? ` at ${txLocation?.structured_formatting.main_text}`
                    : ""}
                </p>
                <p>
                  <b>${golfer.StartingPrice?.toFixed(2)}</b>
                </p>
              </div>
              <div className="personalHead line-item">
                <p>Service Fee</p>
                <p>
                  <b>
                    {golfer.StartingPrice && (
                      <span>
                        $
                        {golfer.StartingPrice > 100
                          ? (golfer.StartingPrice * 0.1).toFixed(2)
                          : 10}
                      </span>
                    )}
                  </b>
                </p>
              </div>
              <hr />
              <div className="personalHead line-item total">
                <p>
                  <b>Total</b>
                </p>
                <p>
                  <b>
                    {golfer.StartingPrice && (
                      <span>
                        $
                        {golfer.StartingPrice > 100
                          ? (golfer.StartingPrice * 1.1).toFixed(2)
                          : golfer.StartingPrice + 10}
                      </span>
                    )}
                  </b>
                </p>
              </div>
            </div>
            {stripePromise && options && show2 && options.clientSecret && (
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: options.clientSecret }}
              >
                <StripeCheckout golfer={golfer} />
              </Elements>
            )}
          </div>
        </div>
      </div>
      <div
        className="terms-modal fade"
        id="confirm-submit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <Modal
          size="lg"
          show={showModal}
          onHide={handleCloseModal}
          fullscreen="xl-down"
          scrollable={true}
          className="mx-auto"
        >
          <Modal.Header closeButton>
            <Modal.Title>Scratchmaker Terms and Services Agreement</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-dialog terms-body">
            Terms
            {/* <Terms hosting={hosting} miner={miner} quantity={quantity} /> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleAcceptTerms}>
              Accept Terms
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default CheckoutSection;
