import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import useAuth from "../../hooks/useAuth";

interface GolferInfoProps {
  currentStep: number;
  clickCB: (step: number) => void;
  stateCB: (state: GolferInfoState) => void;
  sessionState: GolferInfoState | null | undefined;
}

export interface GolferInfoState {
  ghin: string;
  description: string;
  accreditations: File[];
}

const GolferInfo: React.FC<GolferInfoProps> = ({
  currentStep,
  clickCB,
  stateCB,
  sessionState,
}) => {
  const [user] = useAuth();
  const [inputKey, setInputKey] = useState(Math.random().toString(36));

  const [formData, setFormData] = useState<GolferInfoState>({
    ghin: "",
    description: "",
    accreditations: [],
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleContinue = () => {
    if (validateForm()) {
      stateCB(formData);
      clickCB(currentStep + 1);
    }
  };

  const validateForm = () => {
    if (formData.ghin.length < 7) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "Must enter a valid GHIN number",
      });
      return false;
    } else if (formData.description.length < 50) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "Must enter some information about yourself (exceeding 50 characters).",
      });
      return false;
    }
    return true;
  };

  const handleFilesUploaded = (event: any) => {
    // capture file into state
    if (event.target.files[0]) {
      if (formData.accreditations) {
        let currentState = Array.from(formData.accreditations);
        let newState = currentState.concat(Array.from(event.target.files));
        setFormData({ ...formData, accreditations: newState });
      } else {
        setFormData({
          ...formData,
          accreditations: Array.from(event.target.files),
        });
      }

      setInputKey(Math.random().toString(36));
    }
  };

  const removeFile = (file: File) => {
    if (!formData.accreditations) {
      return;
    }
    let currentState = Array.from(formData.accreditations);
    const idx = currentState.indexOf(file);
    if (idx > -1) {
      currentState.splice(idx, 1);
      setFormData({
        ...formData,
        accreditations: currentState,
      });
    }
  };

  useEffect(() => {
    console.log("2");
    if (user) {
      if (user.Description && !formData.description) {
        setFormData({ ...formData, description: user.Description });
      }
    }
  }, [formData, user]);

  useEffect(() => {
    console.log("1");
    if (sessionState) {
      setFormData(sessionState);
    }
  }, [sessionState]);

  return (
    <div className="signup-form-wrapper">
      <div className="signup-title">
        <h2>Golfer Information</h2>
        <p>
          Tell us more about your golf expertise and credibility. The
          information you provide will be displayed on your public profile,
          allowing prospective customers to learn more about your background and
          expertise.
        </p>
      </div>
      <div className="signup-form-fields">
        <div className="field-row">
          <p>USGA GHIN #:</p>
          <div>
            <input
              type="text"
              name="ghin"
              value={formData.ghin}
              onChange={handleChange}
              placeholder="GHIN"
              required
              maxLength={8}
            />
          </div>
        </div>
        <div className="field-row">
          <p>About You:</p>
          <div>
            <textarea
              name="description"
              className="inputField textarea"
              rows={12}
              value={
                formData.description
                  ? formData.description
                  : user?.Description
                  ? user?.Description
                  : ""
              }
              placeholder={
                user?.Description ??
                "Description (Please enter at least one sentence about yourself)"
              }
              required
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="field-row accreditations">
          <p>Awards and Accreditations:</p>
          <div className="award-wrap">
            <div className="searchField">
              <input
                type="file"
                id="awards-input"
                multiple={true}
                onChange={handleFilesUploaded}
                key={inputKey || ""}
                className="awards-input"
                accept="image/png, image/jpeg, application/pdf"
              />
              <label htmlFor="awards-input">
                <div className="awards-btn">Choose Files</div>
              </label>
            </div>
            {formData.accreditations && formData.accreditations.length > 0 && (
              <div className="inputRowGroup">
                <div className=" files">
                  <h2>Awards Selected</h2>
                  <ul>
                    {formData.accreditations.map((a) => {
                      return (
                        <li key={a.name}>
                          {a.name}{" "}
                          <i
                            className="material-icons md-48"
                            onClick={() => removeFile(a)}
                          >
                            delete
                          </i>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <span className="greenBtn" onClick={handleContinue}>
        Continue
      </span>
    </div>
  );
};
export default GolferInfo;
