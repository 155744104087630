import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Message, Room } from "../../lib/models/chat";
import ScratchApiService from "../../lib/services/scratch-api-service";
import styles from "./Chat.module.css";
import MessageWindow from "./MessageWindow";
import SidePanel from "./SidePanel";
import { useEffect, useState } from "react";

type ChatParams = {
  userId: string;
};

const Chat = () => {
  const [room, setRoom] = useState<Room>();
  const { userId } = useParams<ChatParams>();
  const [user] = useAuth();
  const [rooms, setRooms] = useState<Room[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    if (user) {
      if (userId) {
        (async () => {
          const newRoom = await ScratchApiService.createChatRoom(
            user.Id,
            userId
          );

          ScratchApiService.getChatRooms(user.Id).then((foundRooms) => {
            setRooms(foundRooms);
          });
        })();
      } else {
        ScratchApiService.getChatRooms(user.Id).then((foundRooms) => {
          setRooms(foundRooms);
        });
      }
    }
  }, [user, userId]);

  const addMessageToRoom = (message: Message) => {
    if (message.MessageRoomId === room?.Id) {
      setMessages((prevMessages) => {
        if (
          prevMessages.length > 0 &&
          prevMessages[0].MessageRoomId === message.MessageRoomId
        ) {
          return [...prevMessages, message];
        } else if (prevMessages.length === 0) {
          return [message];
        } else {
          return prevMessages;
        }
      });
    }
    setRooms((prevRooms) => {
      // Find the room that matches the updated message
      const updatedRooms = prevRooms.map((prevRoom) => {
        if (prevRoom.Id === message.MessageRoomId) {
          // Append the new message to the room's Messages array
          return {
            ...prevRoom,
            Messages: [...prevRoom.Messages, message],
          };
        }
        return prevRoom;
      });

      return updatedRooms;
    });
  };

  const handleBack = () => {
    setRoom(undefined);
  };

  const handleRoomClick = (r: Room) => {
    setRoom(r);
    setMessages(r.Messages);
  };

  return (
    <>
      <div className={styles.content18}>
        <div
          className={`${styles.wrapSidePanel} ${room ? styles.hideMobile : ""}`}
        >
          <SidePanel room={room} rooms={rooms} handleSelect={handleRoomClick} />
        </div>

        {rooms && room && messages && (
          <div className={styles.wrapMessageWindow}>
            <MessageWindow
              room={room}
              messages={messages}
              addMessageToRoom={addMessageToRoom}
              handleBack={handleBack}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default Chat;
