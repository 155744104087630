import React, { useEffect } from "react";
import arrow from "../../Assets/Images/buttonArrow.png";
import downarrow from "../../Assets/Images/downHeaderArrow.svg";

const Header = () => {
  const carousel = () => {
    setInterval(function () {
      var w = window.innerWidth;
      let targetId = "#purpose-list";
      if (w < 800) targetId = "#purpose-list-mobile";
      const first_height = $(targetId).find("div:first").height() ?? 0;
      var paddings =
        parseInt($(targetId).find("div:first").css("padding-top")) +
        parseInt($(targetId).find("div:first").css("padding-bottom"));
      var margins =
        parseInt($(targetId).find("div:first").css("margin-top")) +
        parseInt($(targetId).find("div:first").css("margin-bottom"));
      $(targetId)
        .stop()
        .animate(
          {
            scrollTop: first_height + paddings + margins,
          },
          990,
          "linear",
          function () {
            $(this).scrollTop(0).find("div:last").after($("div:first", this));
          }
        );
    }, 3000);
  };

  useEffect(() => {
    carousel();
  }, []);
  return (
    <section className="header">
      <div className="container hero">
        <h1>Scratchmaker</h1>

        {/* <div className="header-section">
          <div className="wrap-top-header">
            <h2>
              Hire a <i>Scratchmaker</i> for your next{" "}
            </h2>
          </div>

          <div className="wrap-hire-statements">
            <div className="hire-statement one">
              <h2>outing</h2>
            </div>
            <div className="hire-statement">
              <h2>lesson</h2>
            </div>
          </div>
        </div> */}
        <div className="carousel">
          <div className="pre">
            {" "}
            Hire a <i>Scratchmaker</i> for your next{" "}
          </div>
          <div className="change_outer">
            <div className="change_inner" id="purpose-list">
              <div className="element">
                <span>competition</span>
              </div>
              <div className="element">
                <span>lesson</span>
              </div>
              <div className="element">
                <span>charity event</span>
              </div>
              <div className="element">
                <span>outing</span>
              </div>
            </div>
          </div>
        </div>
        <div className="headerBtns">
          <a href="#golfers" className="headerBtn1">
            HIRE NOW
            <img alt="hirenow" src={arrow} />
          </a>
          <a href="#howitworks" className="headerBtn2">
            LEARN MORE
          </a>
        </div>
        <a href="#howitworks">
          <div className="downBtn">
            <img alt="down" src={downarrow} />
          </div>
        </a>
      </div>
      <div className="container mobile-hero">
        <div className="carousel">
          <div className="pre">
            {" "}
            Hire a <i>Scratchmaker</i> for your next{" "}
          </div>
          <div className="change_outer">
            <div className="change_inner" id="purpose-list-mobile">
              <div className="element">
                <span>competition</span>
              </div>
              <div className="element">
                <span>lesson</span>
              </div>
              <div className="element">
                <span>charity event</span>
              </div>
              <div className="element">
                <span>outing</span>
              </div>
            </div>
          </div>
        </div>
        <div className="headerBtns">
          <a href="#golfers" className="headerBtn1">
            HIRE NOW
            <img alt="hirenow" src={arrow} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Header;
