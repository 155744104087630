import Modal from "../../Modal";
import ProcessUser from "./ProcessUser";

interface ProcessUserModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const ProcessUserModal = (props: ProcessUserModalProps) => {
  const handleToggle = () => {
    props.toggle();
  };

  const fillModal = () => {
    return <ProcessUser onClose={() => props.toggle()} />;
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={handleToggle}
        children={fillModal()}
      />
    </>
  );
};

export default ProcessUserModal;
