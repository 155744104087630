import { useEffect, useState } from "react";
import Modal from "../../Modal";
import CreateListing from "./CreateListing";

interface CreateListingModalProps {
  isOpen: boolean;
  toggle: () => void;
}
export enum ModalType {
  login,
  register,
  registeruserpass,
  registersocial,
  forgotpassword,
}

const CreateListingModal = (props: CreateListingModalProps) => {
  const handleToggle = () => {
    props.toggle();
  };

  const fillModal = () => {
    return <CreateListing onClose={() => props.toggle()} />;
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={handleToggle}
        children={fillModal()}
      />
    </>
  );
};

export default CreateListingModal;
