import { FunctionComponent, useState } from "react";
import styles from "./EventsTable.module.css";
import EventRow from "./EventRow";
import { Listing } from "../../../../lib/models/listing";

interface EventsTableProps {
  listings: Listing[];
  onClick: (listing: Listing) => void;
}

const EventsTable: React.FC<EventsTableProps> = ({ listings, onClick }) => {
  const [selectedListing, setSelectedListing] = useState<Listing>();
  const handleEditClick = (listing: Listing) => {
    onClick(listing);
    setSelectedListing(listing);
  };
  return (
    <div className={styles.content}>
      <table className={styles.eventsTable}>
        <thead>
          <tr className={styles.eventRow}>
            <td className={styles.column1}>
              <div className={styles.tableHeaderCell1}>
                <div className={styles.tableHeader}>
                  <div className={styles.text}>Title</div>
                </div>
              </div>
            </td>
            <td className={styles.toggle}>
              <div className={styles.tableHeaderCell1}>
                <div className={styles.tableHeader}>
                  <div className={styles.text}>Type</div>
                </div>
              </div>
            </td>
            <td className={styles.toggle}>
              <div className={styles.tableHeaderCell1}>
                <div className={styles.tableHeader}>
                  <div className={styles.text}>{`Date & Time`}</div>
                </div>
              </div>
            </td>
            <td className={styles.column4}>
              <div className={styles.tableHeaderCell1}>
                <div className={styles.tableHeader}>
                  <div className={styles.text}>Spots</div>
                </div>
              </div>
            </td>
            <td className={styles.column5}>
              <div className={styles.tableHeaderCell1}>
                <div className={styles.tableHeader}>
                  <div className={styles.text}></div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {listings.map((l) => {
            return (
              <EventRow
                key={l.Id}
                listing={l}
                onClick={handleEditClick}
                selectedListing={selectedListing}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EventsTable;
