import { FunctionComponent } from "react";
import styles from "./Showcase3.module.css";
import { NavLink } from "react-router-dom";

const Showcase3: FunctionComponent = () => {
  return (
    <div className={styles.chooseAndCreate}>
      <div className="content-div">
        <div className={styles.chooseAndCreateInner}>
          <div className={styles.puttingImageWrapper}>
            <img
              className={styles.richardStottC7c8mpe3mfyUnspIcon}
              alt=""
              src="/richardstottc7c8mpe3mfyunsplash-1@2x.png"
            />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.text}>
            Test your Skills in Outings and Competitions.
          </div>
          <div className={styles.supportingText}>
            Attend and host more outings than ever before, while trying your
            hand at local competitions with similar-skilled players.
          </div>
          <NavLink to="/listings/Competitive" className={styles.button}>
            <div className={styles.text1}>View Upcoming Events</div>
            <img
              className={styles.arrowNarrowRightIcon}
              alt=""
              src="/arrowrightwhite.svg"
            />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Showcase3;
