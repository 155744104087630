import { ChangeEvent, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import styles from "./CreateOuting.module.css";
import ScratchApiService from "../../../../lib/services/scratch-api-service";
import { Course } from "../../../../lib/models/course";
import {
  CreateListingRequest,
  Listing,
  ListingTypeEnum,
} from "../../../../lib/models/listing";
import useAuth from "../../../../hooks/useAuth";
import AzureBlobService from "../../../../lib/services/blob-service";
import {
  charLeft,
  convertStateToAbbreviation,
} from "../../../../lib/helpers/texthelper";
import { ImageType } from "react-images-uploading";
import { ImageCropper, ImageUploadingButton } from "../../ImageUpload";

interface CreateOutingProps {
  handleCompleteClick: (listing: Listing) => void;
  onClose: () => void;
}

interface OutingData {
  outingTitle: string;
  golfCourse: Course | null;
  outingDate: string;
  startingTime: string;
  outingFormat: string;
  description: string;
  buyInPrice: number;
  prizeValue: number;
  slotsAvailable: number;
  handicapLimit: number;
  outingGraphic: string | null;
}

const CreateOuting: React.FC<CreateOutingProps> = ({
  onClose,
  handleCompleteClick,
}) => {
  const [outingData, setOutingData] = useState<OutingData>({
    outingTitle: "",
    golfCourse: null,
    outingDate: "",
    startingTime: "",
    outingFormat: "",
    description: "",
    buyInPrice: 0,
    prizeValue: 0,
    slotsAvailable: 0,
    handicapLimit: 0,
    outingGraphic: null,
  });

  const [user] = useAuth();
  const [outingGraphic, setOutingGraphic] = useState<File | null>(null);
  const [searchResults, setSearchResults] = useState<Course[]>([]);
  const [courseSelected, setCourseSelected] = useState<boolean>();
  const [searchValue, setSearchValue] = useState("");
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [handicapLimit, setHandicapLimit] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(true);
  const [image, setImage] = useState<ImageType[]>([]);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleInputChange = (fieldName: keyof OutingData, value: any) => {
    setOutingData((prevData) => {
      const newData = {
        ...prevData,
        [fieldName]: value,
      };
      return newData;
    });
  };

  const handleCroppedPhoto = (image: string) => {
    setCroppedImage(image);
    setDialogOpen(false);
    setOutingData((prevData) => ({
      ...prevData,
      outingGraphic: image,
    }));
  };

  const handlePhotoUpload = (newImage: ImageType[]) => {
    setDialogOpen(true);
    setImage(newImage);
  };

  const checkInputs = (data: OutingData) => {
    const errors: Record<string, boolean> = {};

    if (data.outingTitle === null || data.outingTitle === "") {
      errors.outingTitle = true;
    }
    if (data.golfCourse === null) {
      errors.golfCourse = true;
    }
    if (data.outingDate === null || data.outingDate === "") {
      errors.outingDate = true;
    }
    if (data.startingTime === null || data.startingTime === "") {
      errors.startingTime = true;
    }
    if (data.description === null || data.description === "") {
      errors.description = true;
    }
    if (data.buyInPrice === null || data.buyInPrice === 0) {
      errors.buyInPrice = true;
    }
    if (data.prizeValue === null || data.prizeValue === 0) {
      errors.prizeValue = true;
    }
    if (data.slotsAvailable === null || data.slotsAvailable === 0) {
      errors.slotsAvailable = true;
    }
    setErrors(errors);
    return Object.keys(errors).length !== 0;
  };

  const handleCreate = async () => {
    if (!user) return;

    const hasErrors = checkInputs(outingData);
    if (hasErrors) {
      return;
    }
    if (!outingData.golfCourse) return;

    // create blob of image
    let blobUrl = null;
    if (croppedImage) {
      const photoBlob = await fetch(croppedImage).then((response) =>
        response.blob()
      );
      const file = new File([photoBlob], "cropped-image.jpg");
      blobUrl = outingData.outingGraphic
        ? await AzureBlobService.addListing(file)
        : null;
    }

    // call api service
    const request: CreateListingRequest = {
      listingType: ListingTypeEnum.Outing,
      userId: user.Id,
      title: outingData.outingTitle,
      format: outingData.outingFormat,
      courseId: outingData.golfCourse.Id,
      description: outingData.description,
      picture: blobUrl ?? null,
      eventDate: outingData.outingDate,
      eventTime: outingData.startingTime,
      price: outingData.buyInPrice,
      prize: outingData.prizeValue,
      greenFeesIncluded: true,
      featuredFlag: false,
      wagerFormat: null,
      wagerAmount: null,
      teamSize: null,
      handicapLimit: handicapLimit,
      handicapLimitValue: outingData.handicapLimit,
      totalSlots: outingData.slotsAvailable,
    };

    const listing = await ScratchApiService.createListing(request);

    // next
    handleCompleteClick(listing);
  };

  const handleHandicapLimitToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setHandicapLimit(event.target.checked);
  };

  const handleCourseSearch = (searchText: string) => {
    setSearchValue(searchText);
    setCourseSelected(false);

    if (searchText !== "") {
      ScratchApiService.getCourses(searchText).then(setSearchResults);
    }
  };

  const handleCourseSearchClick = (result: Course) => {
    setCourseSelected(true);
    handleInputChange("golfCourse", result);
  };

  return (
    <>
      <div className={styles.outingModal}>
        <div className={styles.modalHeader}>
          <div className={styles.content}>
            <div className={styles.event}>
              <img
                className={styles.trophyIcon}
                alt=""
                src="/listing-types/outing_icon.svg"
              />
            </div>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>Lets start building your Outing</div>
              <div className={styles.supportingText}>
                Please fill in all the details below.
              </div>
            </div>
          </div>
          <div className={styles.buttonCloseX} onClick={onClose}>
            <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
          </div>
          <img className={styles.dividerIcon} alt="" src="/divider.svg" />
        </div>
        <div className={styles.content1}>
          <div className={styles.form}>
            <div className={styles.row}>
              <div className={styles.text1}>Outing Title</div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input}>
                    <div className={styles.content2}>
                      <input
                        type="text"
                        className={styles.inputText}
                        placeholder="Enter Outing Title"
                        onChange={(e) =>
                          handleInputChange("outingTitle", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.outingTitle && (
                    <div className={styles.errorText}>
                      Please enter an outing title.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Golf Course</div>
              <div className={styles.inputWithLabel}>
                <div className={styles.courseSearch}>
                  <div className={styles.content3}>
                    <img
                      className={styles.searchLgIcon}
                      alt=""
                      src="/searchlg.svg"
                    />
                    <input
                      type="text"
                      className={styles.inputText}
                      placeholder="Search Course"
                      value={
                        courseSelected
                          ? outingData.golfCourse
                            ? outingData.golfCourse.ClubName
                            : searchValue
                          : searchValue
                      }
                      onChange={(e) => handleCourseSearch(e.target.value)}
                    />
                  </div>
                  {!courseSelected && searchResults.length > 0 && (
                    <div className={styles.searchResults}>
                      {searchResults.map((result) => (
                        <div
                          className={styles.searchResultItem}
                          key={result.Id}
                          onClick={() => handleCourseSearchClick(result)}
                        >
                          <img
                            className={styles.markerPin02Icon}
                            alt=""
                            src="/markerpin02.svg"
                          />
                          {result.ClubName} (
                          {`${result.City}, ${convertStateToAbbreviation(
                            result.State
                          )}`}
                          )
                        </div>
                      ))}
                      {/* TODO: Add the manual entry */}
                      {/* <div className={styles.manualAlert} key="manualListing">
                        Don't see the course your looking for?{" "}
                        <span> Enter details manually</span>
                      </div> */}
                    </div>
                  )}
                </div>
                {errors.golfCourse && (
                  <div className={styles.errorText}>
                    Please search for a golf course.
                  </div>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Outing Date</div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/calendar.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="date"
                        className={styles.inputText}
                        placeholder="Select Date"
                        min={new Date().toISOString().split("T")[0]}
                        onChange={(e) =>
                          handleInputChange("outingDate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.outingDate && (
                    <div className={styles.errorText}>
                      Please choose a date.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Starting Time</div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/alarmclock.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="time"
                        className={styles.inputText}
                        placeholder="Start Time"
                        onChange={(e) =>
                          handleInputChange("startingTime", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.startingTime && (
                    <div className={styles.errorText}>
                      Please choose a time.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.text1}>Outing Graphic</div>
              <div className={styles.avatarAndFileUpload}>
                <div className={styles.fileUpload}>
                  <div className={styles.fileUploadBase}>
                    {croppedImage ? (
                      <>
                        <div
                          className={styles.deleteIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCroppedImage(null);
                          }}
                        >
                          <AiOutlineCloseCircle />
                        </div>
                        <img
                          className={styles.uploadedImage}
                          src={croppedImage}
                          alt="Outing Graphic"
                        />
                      </>
                    ) : (
                      <label className={styles.fileUploadContent}>
                        <img
                          className={styles.featuredIcon}
                          alt=""
                          src="/featured-icon3.svg"
                        />

                        <div className={styles.textAndSupportingText1}>
                          <div className={styles.action}>
                            <div className={styles.uploadButton}>
                              <div className={styles.uploadText}>
                                Click to upload
                              </div>
                            </div>
                            {/* <div className={styles.uploadSubtext}>
                              or drag and drop
                            </div> */}
                          </div>
                          <div className={styles.supportingText1}>
                            SVG, PNG, or JPG
                          </div>
                        </div>
                        <ImageUploadingButton
                          value={image}
                          onChange={handlePhotoUpload}
                        />
                      </label>
                    )}
                  </div>
                </div>
                {errors.outingGraphic && (
                  <div className={styles.errorText}>
                    Please upload an image.
                  </div>
                )}
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.text1}>Format</div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input}>
                    <div className={styles.content2}>
                      <select
                        className={`${styles.inputText} ${styles.selectFormat}`}
                        onChange={(e) =>
                          handleInputChange("outingFormat", e.target.value)
                        }
                      >
                        <option className={styles.selectFormat} value="">
                          Select Format
                        </option>
                        <option value="4-man scramble">4-man scramble</option>
                        <option value="4-man best ball">4-man best ball</option>
                        <option value="2-man scramble">2-man scramble</option>
                        <option value="2-man best-ball">2-man best-ball</option>
                      </select>
                    </div>
                  </div>
                  {errors.outingTitle && (
                    <div className={styles.errorText}>
                      Please select a format.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.text1}>Description</div>
              <div className={styles.textareaInputField}>
                <div className={styles.inputWithLabel3}>
                  <div className={styles.label}>Description*</div>
                  <div className={styles.input4}>
                    <textarea
                      className={styles.textarea}
                      placeholder="Write a few sentences about the outing..."
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
                <div className={styles.hintText}>
                  {charLeft(outingData.description, 1000)} characters left
                </div>
                {errors.description && (
                  <div className={styles.errorText}>
                    Please enter a description.
                  </div>
                )}
              </div>
            </div>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.text1}>Handicap Limit</div>
              <div className={styles.toggle}>
                <div className={styles.toggleBase}>
                  {" "}
                  <input
                    type="checkbox"
                    id="handicapLimitToggle"
                    onChange={handleHandicapLimitToggle} // Replace with your toggle change handler function
                  />
                  <label htmlFor="handicapLimitToggle"></label>
                </div>
                <div className={styles.textAndSupportingText}>
                  <div className={styles.text1}>Apply a handicap limit</div>
                </div>
              </div>
            </div>
            {handicapLimit && (
              <div className={styles.row}>
                <div className={styles.text1}></div>
                <div className={styles.inputField}>
                  <div className={styles.inputWithLabel}>
                    <div className={styles.input2}>
                      <div className={styles.addOn}>
                        <img
                          className={styles.searchLgIcon}
                          alt=""
                          src="/golf-stroke.svg"
                        />
                      </div>
                      <div className={styles.textInput}>
                        <input
                          type="number"
                          className={styles.inputText}
                          placeholder="Handicap Limit"
                          onChange={(e) =>
                            handleInputChange("handicapLimit", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    {errors.startingTime && (
                      <div className={styles.errorText}>
                        Please choose a time.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text11}>Buy-In Price</div>
                <div className={styles.supportingText1}>
                  Including green fees
                </div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/currencydollar.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        placeholder="Enter Price"
                        onChange={(e) =>
                          handleInputChange("buyInPrice", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.buyInPrice && (
                    <div className={styles.errorText}>
                      Please set a buy-in price.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text11}>Prize Value</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/piggybank01.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        placeholder="Enter Prize Amount"
                        onChange={(e) =>
                          handleInputChange("prizeValue", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.prizeValue && (
                    <div className={styles.errorText}>
                      Please set a prize amount.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.textParent}>
                <div className={styles.text11}>How Many Players?</div>
                <div className={styles.subtitle}>Including yourself</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.input2}>
                    <div className={styles.addOn}>
                      <img
                        className={styles.searchLgIcon}
                        alt=""
                        src="/users01.svg"
                      />
                    </div>
                    <div className={styles.textInput}>
                      <input
                        type="number"
                        className={styles.inputText}
                        placeholder="Enter Total Number of Players"
                        onChange={(e) =>
                          handleInputChange("slotsAvailable", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {errors.slotsAvailable && (
                    <div className={styles.errorText}>
                      Please set the number of slots available.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.modalActions}>
          <div className={`${styles.dividerWrap} ${styles.dividerActions}`}>
            <img className={styles.dividerIcon1} alt="" src="/divider.svg" />
          </div>
          <div className={styles.content4}>
            <div className={styles.cancelButton} onClick={onClose}>
              <div className={styles.text14}>Cancel</div>
            </div>
            <div className={`${styles.nextButton} `} onClick={handleCreate}>
              <div className={styles.text14}>Create</div>
            </div>
          </div>
        </div>
      </div>
      <ImageCropper
        open={dialogOpen}
        image={image.length > 0 ? image[0].dataURL ?? null : null}
        onComplete={handleCroppedPhoto}
        header={"Crop Outing Photo"}
        circle={false}
        containerStyle={{
          position: "relative",
          width: "100%",
          height: 300,
          background: "#333",
        }}
        aspectRatio={1.6}
      />
    </>
  );
};

export default CreateOuting;
