import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../lib/services/auth-service";
import { ModalType } from "./LoginModal";

interface ResetviaEmailProps {
  changeModalType: (type: ModalType) => void;
  toggle: () => void;
}

export const ResetviaEmail: React.FC<ResetviaEmailProps> = ({
  changeModalType,
  toggle,
}) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleReset = async () => {
    await AuthService.resetRequest(email);
    toggle();
    navigate("/");
  };
  return (
    <div className="email-reset wrap">
      <section className="body">
        <button className="close-button"></button>
        <form className="">
          <div className="reset-password-request-form">
            <section className="form-header">
              <h4>Reset Password</h4>
              <p>
                Please enter your email address and we'll send you a link to
                reset your password.
              </p>
            </section>
            <div className="field">
              <div className="field-input-wrapper">
                <div>
                  <input
                    className="field-input"
                    type="text"
                    data-lpignore="true"
                    placeholder="Enter your email"
                    id="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <span className="submit-email" onClick={handleReset}>
              <p>Submit</p>
            </span>
          </div>
        </form>
      </section>
      <footer className="modal-content-footer">
        <div className="signing-footer text-body-2">
          <button
            type="button"
            className="link-button text-body-2 green"
            onClick={() => changeModalType(ModalType.login)}
          >
            Back to Sign In
          </button>
        </div>
      </footer>
    </div>
  );
};
