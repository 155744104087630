import { useState } from "react";

export default function useViewListingModal() {
  const [isViewListingOpen, setIsViewListingOpen] = useState(false);

  const toggleViewListing = () => {
    setIsViewListingOpen(!isViewListingOpen);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = body.style.overflow === "" ? "hidden" : "";
    }
  };

  return {
    isViewListingOpen,
    toggleViewListing,
  };
}
