import { User } from "../../lib/models";
import Modal from "../common/Modal";
import Members from "./Members";

interface ChatMembersModalProps {
  isOpen: boolean;
  toggle: () => void;
  members: User[];
}

const ChatMembersModal = (props: ChatMembersModalProps) => {
  const handleToggle = () => {
    props.toggle();
  };

  const fillModal = () => {
    return <Members onClose={() => props.toggle()} members={props.members} />;
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={handleToggle}
        children={fillModal()}
      />
    </>
  );
};

export default ChatMembersModal;
