import { useEffect, useState } from "react";
import { Register } from "./Register";
import { Login } from "./Login";
import { RegisterFromSocial } from "./RegisterFromSocial";
import { RegisterUserPass } from "./RegisterUserPass";
import Modal from "../Modal";
import { ResetviaEmail } from "./ResetviaEmail";

interface LoginModalProps {
  isOpen: boolean;
  toggle: () => void;
}
export enum ModalType {
  login,
  register,
  registeruserpass,
  registersocial,
  forgotpassword,
}

const LoginModal = (props: LoginModalProps) => {
  const [modalType, setModalType] = useState<ModalType>(ModalType.register);
  const [email, setEmail] = useState<String>("");

  const handleToggle = () => {
    setModalType(ModalType.register);
    props.toggle();
  };

  const fillModal = () => {
    switch (modalType) {
      case ModalType.login:
        return <Login toggle={handleToggle} changeModalType={setModalType} />;
      case ModalType.registeruserpass:
        return (
          <RegisterUserPass
            toggle={handleToggle}
            changeModalType={setModalType}
            email={email}
          />
        );
      case ModalType.registersocial:
        return (
          <RegisterFromSocial
            toggle={handleToggle}
            changeModalType={setModalType}
          />
        );
      case ModalType.forgotpassword:
        return (
          <ResetviaEmail toggle={handleToggle} changeModalType={setModalType} />
        );
      default:
        return (
          <Register
            setRegisteredEmail={setEmail}
            changeModalType={setModalType}
          />
        );
    }
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={handleToggle}
        children={fillModal()}
      />
    </>
  );
};

export default LoginModal;
