import Axios, { AxiosResponse } from "axios";
import { User } from "../models";

export const enum LoginType {
  Local,
  Google,
  Facebook,
}

export type AuthResponse = {
  success: Boolean;
  message: String;
  user: User;
};

export default class AuthService {
  static async emailRegister(email: String): Promise<AuthResponse> {
    const result: AuthResponse = await Axios({
      method: "POST",
      data: {
        email: email,
      },
      withCredentials: true,
      url: `/auth/emailRegister`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return null;
        }
        return res.data;
      })
      .catch((ex) => {
        return null;
      });

    return result;
  }

  static async userRegister(
    email: String,
    username: String,
    pw: String
  ): Promise<AuthResponse> {
    const result: AuthResponse = await Axios({
      method: "POST",
      data: {
        username: username,
        email: email,
        password: pw,
      },
      withCredentials: true,
      url: `/auth/userRegister`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return null;
        }
        return res.data;
      })
      .catch((ex) => {
        return null;
      });

    return result;
  }

  static async localLogin(username: String, pw: String): Promise<AuthResponse> {
    const result: AuthResponse = await Axios({
      method: "POST",
      data: {
        username: username,
        password: pw,
      },
      withCredentials: true,
      url: `/auth/login`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return null;
        }
        return res.data;
      })
      .catch((ex) => {
        return null;
      });

    return result;
  }

  static async getLoggedInUser(): Promise<User> {
    const result: User = await Axios({
      method: "GET",
      withCredentials: true,
      url: `/auth/user`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return null;
        }
        return res.data.user;
        // const formattedUser: User = { Id: responseUser.Id };
      })
      .catch((ex) => {
        return null;
      });

    return result;
  }

  static async signOut(): Promise<AxiosResponse> {
    const result: AxiosResponse = await Axios({
      method: "POST",
      withCredentials: true,
      url: `/auth/logout`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return null;
        }
        return res.data;
      })
      .catch((ex) => {
        return null;
      });
    return result;
  }

  static async validateToken(userId: String, token: String): Promise<boolean> {
    const result: boolean = await Axios({
      method: "POST",
      data: {
        userId: userId,
        token: token,
      },
      withCredentials: true,
      url: `/auth/validatetoken`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return false;
        }
        return true;
      })
      .catch((ex) => {
        return false;
      });

    return result;
  }

  static async resetRequest(email: String): Promise<boolean> {
    const result: boolean = await Axios({
      method: "POST",
      data: {
        email: email,
      },
      withCredentials: true,
      url: `/auth/resetrequest`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return false;
        }
        return true;
      })
      .catch((ex) => {
        return false;
      });

    return result;
  }

  static async resetPassword(
    userId: String,
    password: String,
    token: String
  ): Promise<AuthResponse> {
    const result: AuthResponse = await Axios({
      method: "POST",
      data: {
        userId: userId,
        password: password,
        token: token,
      },
      withCredentials: true,
      url: `/auth/reset`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return null;
        }
        return res.data;
      })
      .catch((ex) => {
        return null;
      });

    return result;
  }

  static googleLoginHandler = () => {
    const env = process.env;
    const base_url =
      window.location.origin.indexOf("localhost") > 0
        ? "http://localhost:5000"
        : window.location.origin;
    window.open(
      `${base_url}/auth/login/google`,
      "_self",
      "top=500,right=500,width=600,height=600"
    );
  };

  static facebookLoginHandler = () => {
    const base_url =
      window.location.origin.indexOf("localhost") > 0
        ? "http://localhost:5000"
        : window.location.origin;
    window.open(
      `${base_url}/auth/login/facebook`,
      "_self",
      "top=500,right=500,width=600,height=600"
    );
  };
}
