import { FunctionComponent } from "react";
import styles from "./NewsletterContainer.module.css";

const NewsletterContainer: FunctionComponent = () => {
  return (
    <div className={styles.section}>
      <div className="content-div">
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.textAndSupportingText}>
              <div className={styles.text}>Play More Golf</div>
              <div className={styles.supportingText}>
                Sign up today and start playing more often in your area
              </div>
            </div>
            <div className={styles.emailCapture}>
              <div className={styles.button}>
                <div className={styles.text2}>Register</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterContainer;
