import React, { useState, useRef } from "react";
import closeModal from "../../Assets/Images/Close.png";
import PositionService from "../../lib/services/position-service";
import {
  LocationSearchResult,
  getPositionByPlaceId,
  getPositionDescription,
  searchByPostalCode,
  Position,
} from "../../lib/services/google-maps-service";
import usePosition from "../../hooks/usePosition";
import LocationSearchbar from "./LocationSearchBar";

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
}

const LocationModal: React.FC<Props> = ({ isOpen, onDismiss }) => {
  const searchbarRef = useRef<HTMLInputElement>(null);
  const [position, setPosition] = usePosition();

  const handleSelected = (position: Position) => {
    // erase search bar text and callback to golfers component
    if (searchbarRef.current) {
      searchbarRef.current.value = "";
    }
    setPosition(position);
    onDismiss();
  };

  return (
    <>
      {isOpen && (
        <div className="locationupdatepopup show" id="showlocationpopup">
          <div className="popup">
            <div className="popupHeading">
              <h3>Select a Location</h3>
              <img
                alt="close"
                src={closeModal}
                id="closelocationpopupwithcancelbtn"
                onClick={onDismiss}
              />
            </div>
            <div className="popupBody">
              <LocationSearchbar onSelected={handleSelected} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LocationModal;
