import styles from "./ExclusiveCourse.module.css";
import {
  Listing,
  ListingTypeEnum,
  ListingViewType,
} from "../../lib/models/listing";

interface ExclusiveCourseProps {
  listing: Listing;
  onListingClick: (listing: Listing, type: ListingTypeEnum) => void;
  listingViewType: ListingViewType;
}

const ExclusiveCourse: React.FC<ExclusiveCourseProps> = ({
  listing,
  onListingClick,
  listingViewType,
}) => {
  return (
    <div
      className={`${styles.eventCard} ${
        listingViewType === ListingViewType.Write ? styles.clickable : ""
      }`}
      onClick={() => onListingClick(listing, ListingTypeEnum.Exclusive)}
    >
      <div className={styles.content}>
        <div className={styles.details}>
          <div className={styles.detailsWrap}>
            <div className={styles.textAndIcon}>
              <img
                className={styles.markerPin02Icon}
                alt=""
                src="/markerpin02.svg"
              />
              <div className={styles.text}>{listing.Course.ClubName}</div>
            </div>
          </div>
          {/* TODO: ADD FAVORITING */}
          {/* {listingViewType === ListingViewType.Write && (
            <div className={styles.favoriteButton}></div>
          )} */}
        </div>
        <div className={styles.textAndButton}>
          <div className={styles.text1}>{listing.Title}</div>
        </div>
        <div className={styles.textAndCategory}>
          <div className={styles.wrapAvatar}>
            <div className={styles.avatar}>
              <img alt="" src={listing.User.ImageUrl} />
              {/* <div className={styles.avatarInner}>
                <div className={styles.rectangleParent}>
                  <img
                    className={styles.layer2Copy}
                    alt=""
                    src="/Scratchmaker_Strikethrough.png"
                  />
                </div>
              </div> */}
            </div>
            <div className={styles.content1}>
              <div className={styles.textAndSupportingText}>
                <div className={styles.textAndSubtext}>
                  <div className={`${styles.text2} ${styles.userName}`}>
                    {listing.User.Username}
                  </div>
                </div>
                <div className={styles.rating}>
                  <div className={styles.starsWrap}>
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                    <img className={styles.star} alt="" src="/star-icon.svg" />
                  </div>
                  <div className={styles.ratingWrap}>
                    <div className={styles.text30}>4.9</div>
                  </div>
                  <div className={styles.supportingText4}>(202)</div>
                </div>
              </div>
            </div>
            <img className={styles.dotIcon} alt="" src="/-dot.svg" />
          </div>
          {listing.Price && listingViewType !== ListingViewType.Checkout && (
            <div className={styles.details1}>
              <div className={styles.textAndSupportingText2}>
                <div className={styles.text4}>${listing.Price}</div>
              </div>
            </div>
          )}
        </div>
        {listing.Reservations != null &&
          listing.TotalSlots != null &&
          listingViewType !== ListingViewType.Checkout && (
            <div className={styles.container}>
              <div className={styles.chart}>
                <div className={styles.spotsTargetChart} />
                <div
                  className={styles.slotsIndicator}
                  style={{
                    width: `${
                      listing.Reservations.length > 0
                        ? (listing.Reservations.length / listing.TotalSlots) *
                          260
                        : 20
                    }px`,
                  }}
                />
              </div>
              <div className={styles.detail}>
                <div className={styles.spots}>
                  <div className={styles.spotsFilled}>
                    {listing.Reservations.length}
                  </div>
                  <div className={styles.spotsTarget}>
                    {`/${listing.TotalSlots} Spots`}
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ExclusiveCourse;
