import { useState } from "react";

export default function useProcessUserModal() {
  const [isProcessUserOpen, setIsProcessUserOpen] = useState(false);

  const toggleProcessUser = () => {
    setIsProcessUserOpen(!isProcessUserOpen);
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = body.style.overflow === "" ? "hidden" : "";
    }
  };

  return {
    isProcessUserOpen,
    toggleProcessUser,
  };
}
