import React, { useState, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import { CreateGolferRequest, Golfer } from "../../lib/models/golfer";
import useAuth from "../../hooks/useAuth";
import ScratchApiService from "../../lib/services/scratch-api-service";
import { useNavigate } from "react-router-dom";
import AzureBlobService from "../../lib/services/blob-service";
import useGolfer from "../../hooks/useGolfer";
import { getPositionByPlaceId } from "../../lib/services/google-maps-service";
import AuthService from "../../lib/services/auth-service";
import PersonalInfo, { PersonalInfoState } from "./PersonalInfo";
import GolferInfo, { GolferInfoState } from "./GolferInfo";
import Identification, { IdentityInfoState } from "./Identification";

interface SignUpFormProps {
  currentStep: number;
  clickCB: (step: number) => void;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ currentStep, clickCB }) => {
  const [user, setUser] = useAuth();
  const [, setGolfer] = useGolfer();
  const navigate = useNavigate();

  const [personalInfoState, setPersonalInfoState] =
    useState<PersonalInfoState>();
  const [golferInfoState, setGolferInfoState] = useState<GolferInfoState>();
  const [identityInfoState, setIdentityInfoState] =
    useState<IdentityInfoState>();

  const validateForm = () => {
    if (!identityInfoState || identityInfoState.imageUrl.length < 1) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Input",
        text: "Must upload a picture of your license",
      });
      return false;
    }
    return true;
  };
  const handleSignup = async () => {
    if (!validateForm()) return;
    if (!user) {
      return;
    }
    let awards = "";
    if (!personalInfoState || !golferInfoState || !identityInfoState) {
      // TODO: Return to where we are missing data
      return;
    }

    if (golferInfoState.accreditations) {
      const awardNames = golferInfoState.accreditations.map((a) => {
        return a.name;
      });
      awards = awardNames.join(";");
    }

    // get position of golfer
    if (!personalInfoState.address) return;
    const position = await getPositionByPlaceId(
      personalInfoState.address.place_id
    );

    const golferRequest: CreateGolferRequest = {
      User: user,
      Position: position ?? { latitude: 40, longitude: -80, name: "default" },
      Ghin: golferInfoState.ghin,
      Description: golferInfoState.description,
      ImageUrl: personalInfoState.imageUrl,
      Awards: awards,
    };

    const g = await ScratchApiService.createGolfer(golferRequest);
    if (g.User?.Username && golferInfoState.accreditations) {
      AzureBlobService.addAccreditations(
        g.UserName,
        golferInfoState.accreditations
      )
        .then((a) => {
          console.log("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const loggedInUser = await AuthService.getLoggedInUser();
    if (!loggedInUser) return;
    setUser(loggedInUser);
    wrapUpGolferCreation(g);
  };

  const wrapUpGolferCreation = (golfer: Golfer) => {
    setGolfer(golfer);
    Swal.fire({
      title: "Another Scratchmaker in the Mix!",
      text: "Welcome to the Scratchmaker Family. Please check your email for details around getting your first gig.",
      imageUrl: `https://scratchmaker.blob.core.windows.net/profiles/${golfer.User?.Username}_${golfer.User?.ImageUrl}`,
      imageWidth: 200,
      imageHeight: 200,
      imageAlt: golfer.UserName,
      showCloseButton: true,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
      confirmButtonAriaLabel: "Thumbs up, great!",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
      }
    });
  };

  return (
    <section className="formSection">
      <div className="container wrap-signup-golfer">
        <div>
          <form>
            {currentStep === 0 && (
              <PersonalInfo
                currentStep={currentStep}
                clickCB={clickCB}
                stateCB={setPersonalInfoState}
                sessionState={personalInfoState}
              />
            )}
            {currentStep === 1 && (
              <GolferInfo
                currentStep={currentStep}
                clickCB={clickCB}
                stateCB={setGolferInfoState}
                sessionState={golferInfoState}
              />
            )}
            {currentStep === 2 && (
              <Identification
                stateCB={setIdentityInfoState}
                sessionState={identityInfoState}
              />
            )}
            {/* 
            <div className="inputRowGroup">
              <div className="inputFieldComponent d-block">
                <h3>Pricing</h3>
                <PricingSlider price={price} setPrice={setPrice} />
              </div>
              <div className="inputFieldComponent">
                <h3>Travel Radius (miles)</h3>
                <div className="wrap-slider">
                  <ReactSlider
                    className="travelSlider"
                    thumbClassName="travelSlider-thumb"
                    trackClassName="travelSlider-track"
                    markClassName="travelSlider-mark"
                    marks={25}
                    min={0}
                    max={100}
                    value={radius}
                    onChange={(value) => setRadius(value)}
                    renderMark={(props) => {
                      let val = props?.key;
                      if (val !== null && val !== undefined) {
                        if (val < radius) {
                          props.className =
                            "travelSlider-mark travelSlider-mark-before";
                        } else if (props.key === radius) {
                          props.className =
                            "travelSlider-mark travelSlider-mark-active";
                        }
                      }
                      return <span {...props} />;
                    }}
                  />
                  <span className="travelslider-value">{radius}</span>
                </div>
              </div>
            </div>
            <div className="inputRowGroup accreditations">
              <div className="inputFieldComponent ">
                <h3>Awards and Accreditations</h3>
                <div className="searchField">
                  <input
                    type="file"
                    id="awards-input"
                    multiple={true}
                    onChange={handleFilesUploaded}
                    key={inputKey || ""}
                    className="awards-input"
                    accept="image/png, image/jpeg, application/pdf"
                  />
                  <label htmlFor="awards-input">
                    <div className="awards-btn">Choose Files</div>
                  </label>
                </div>
              </div>
            </div>
            {accreditations && (
              <div className="inputRowGroup">
                <div className=" files">
                  <h2>Awards Selected</h2>
                  <ul>
                    {accreditations.map((a) => {
                      return (
                        <li key={a.name}>
                          {a.name}{" "}
                          <i
                            className="material-icons md-48"
                            onClick={() => removeFile(a)}
                          >
                            delete
                          </i>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )} */}
            {currentStep === 2 && (
              <span className="greenBtn" onClick={handleSignup}>
                Register
              </span>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUpForm;
