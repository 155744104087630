import { FunctionComponent } from "react";
import styles from "./EventsManagerHeader.module.css";

interface EventsManagerHeaderProps {
  handleHostClick: () => void;
}

const EventsManagerHeader: React.FC<EventsManagerHeaderProps> = ({
  handleHostClick,
}) => {
  return (
    <>
      <div className={styles.headerSection}>
        <div className={styles.container}>
          <div className={styles.pageHeader}>
            <div className={styles.content}>
              <div className={styles.textAndSupportingText}>
                <div className={styles.text}>My Listings</div>
                <div className={styles.supportingText}>
                  Manage your listings with our Events Manager
                </div>
              </div>
              <div className={styles.cta} onClick={handleHostClick}>
                <img
                  className={styles.featuredIcon}
                  alt=""
                  src="/create-listing-icon.svg"
                />
                <div className={styles.textAndSupportingText1}>
                  <div className={styles.text1}>Create New Listing</div>
                  <div className={styles.supportingText1}>
                    Want to host an outing, tournament, or casual play? Create a
                    listing today.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsManagerHeader;
