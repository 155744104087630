import { FunctionComponent } from "react";
import styles from "./BecomeAHostCallback.module.css";
import useCreateListingModal from "../../hooks/useCreateListingModal";
import CreateListingModal from "../common/listings/create-listing-modal/CreateListingModal";
import useAuth from "../../hooks/useAuth";
import useLoginModal from "../../hooks/useLoginModal";
import LoginModal from "../common/auth/LoginModal";
import { NavLink } from "react-router-dom";
import useProcessUserModal from "../../hooks/useProcessUserModal";
import ProcessUserModal from "../common/auth/process-user/ProcessUserModal";

const BecomeAHostCallback: FunctionComponent = () => {
  const { isCreateListingOpen, toggleCreateListing } = useCreateListingModal();
  const { isLoginOpen, toggleLogin } = useLoginModal();
  const { isProcessUserOpen, toggleProcessUser } = useProcessUserModal();
  const [user] = useAuth();
  return (
    <div className={styles.cta}>
      <div className="content-div">
        <div className={styles.contentContainer}>
          <div className={styles.headingContent}>
            <div className={styles.heading}>
              <div className={styles.text}>
                <p className={styles.interestedIn}>
                  Interested in Hosting a Round?
                </p>
              </div>
            </div>
            <div className={styles.p1}>
              <p>Connect with golfers or plan your next outing</p>
              <p>Create Your listing today with one click!</p>
            </div>
          </div>
          <div className={styles.buttonGroups}>
            <NavLink
              to="/listings"
              className={styles.button2xlprimarydotLeading}
            >
              <img className={styles.dotIcon} alt="" src="/-dot1.svg" />
              <div className={styles.text1}>Browse Listings</div>
            </NavLink>
            <button
              className={styles.button2xlprimarydotLeading1}
              onClick={
                user
                  ? user.Location
                    ? toggleCreateListing
                    : toggleProcessUser
                  : toggleLogin
              }
            >
              <div className={styles.text2}>Create a Listing</div>
            </button>
          </div>
        </div>
      </div>
      <LoginModal isOpen={isLoginOpen} toggle={toggleLogin} />
      <CreateListingModal
        isOpen={isCreateListingOpen}
        toggle={toggleCreateListing}
      />
      <ProcessUserModal isOpen={isProcessUserOpen} toggle={toggleProcessUser} />
    </div>
  );
};

export default BecomeAHostCallback;
